import { Coordinates } from "@giga-user-fern/api/types/api";
import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import { Shape } from "@gigauser/common/src/components/formats/RichText/components/EditScreenshot/ScreenshotEditorContext";
import { SizeControllerPos } from "../components/formats/RichText/components/EditScreenshot/components/SizeController/SizeControllerThumb";
import { ShapeDisplayProperties } from "../components/formats/RichText/components/EditScreenshot/ScreenshotEditorContext";

export type AspectRatio = "16:9" | "4:3" | "1:1" | "3:4" | "9:16" | "Any";

export const pixelsToFractionalCoords = (
	pos: Coordinates,
	mediaRef: React.RefObject<any>,
): Coordinates => {
	if (!mediaRef.current) return pos;

	const { x, y } = pos;
	const { width, height } = mediaRef.current.getBoundingClientRect();

	return { x: x / width, y: y / height };
};

export const fractionalCoordsToPixels = (
	pos: Coordinates,
	mediaRef: React.RefObject<any>,
): Coordinates => {
	if (!mediaRef.current) return pos;

	const { x, y } = pos;
	const { width, height } = mediaRef.current.getBoundingClientRect();

	return { x: x * width, y: y * height };
};

export const getPixelCrop = (
	stateCrop: Crop,
	mediaRef: React.RefObject<any>,
): { position: Coordinates; size: Coordinates } => {
	const result = {
		position: fractionalCoordsToPixels(
			{ x: stateCrop.position[0], y: stateCrop.position[1] },
			mediaRef,
		),
		size: fractionalCoordsToPixels(
			{ x: stateCrop.size[0], y: stateCrop.size[1] },
			mediaRef,
		),
	};

	return result;
};

export const getShape = (stateCrop: Crop): Shape => ({
	id: "ignore",
	geo: "rectangle",
	position: stateCrop.position,
	size: stateCrop.size,
});

export const getSizeControls = (
	aspectRatio?: AspectRatio,
): SizeControllerPos[] => {
	const cornerControls: SizeControllerPos[] = ["tl", "tr", "br", "bl"];
	const edgeControls: SizeControllerPos[] = ["t", "l", "b", "r"];

	return [
		...cornerControls,
		...(aspectRatio && aspectRatio !== "Any" ? [] : edgeControls),
	];
};

export const updateCrop = (
	stateCrop: Crop,
	setStateCrop: (crop: Crop) => void,
	updatedProperties: {
		position?: [number, number];
		size?: [number, number];
	} & ShapeDisplayProperties,
) => {
	const { position: newPos, size: newSize } = updatedProperties;
	const { position: oldPos, size: oldSize } = stateCrop;

	const clampedPosition = newPos
		? [
				Math.min(1, Math.max(0, newPos[0])),
				Math.min(1, Math.max(0, newPos[1])),
			]
		: oldPos;

	const clampedSize = newSize
		? [
				Math.min(1 - clampedPosition[0], Math.max(0, newSize[0])),
				Math.min(1 - clampedPosition[1], Math.max(0, newSize[1])),
			]
		: oldSize;

	setStateCrop({ position: clampedPosition, size: clampedSize });
};

export const resetCrop = (
	setStateCrop: (crop: Crop) => void,
	stateCrop: Crop,
	setAspectRatio?: (aspectRatio: AspectRatio) => void,
) => {
	setStateCrop(stateCrop);
	if (setAspectRatio) setAspectRatio("Any");
};
