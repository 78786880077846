import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GuideData, GuidePreview, PlainDoc } from "../../core/types/guide";
import { Guide } from "../../core/types/guide";
import { RootState } from "@gigauser/common/src/redux/store";
import { ExportDetails } from "@giga-user-fern/api/types/api/resources/guides";

interface ProcessingState {
	value: {
		saveInProgress: boolean;
		showVideoExportPopup: {
			showPopup: boolean;
			startExport?: Date | false;
		};
		showYoutubePublishPopup: {
			showPopup: boolean;
			startExport?: boolean;
		};
		showGifExportPopup: boolean;
		exportingGif: boolean;
		gifDownloadLink: string | null;
	};
}

const initialState: ProcessingState = {
	value: {
		saveInProgress: false,
		showVideoExportPopup: {
			showPopup: false,
			startExport: false,
		},
		showYoutubePublishPopup: {
			showPopup: false,
			startExport: false,
		},
		showGifExportPopup: false,
		gifDownloadLink: null,
		exportingGif: false,
	},
};

export const processingSlice = createSlice({
	name: "processing",
	initialState,
	reducers: {
		setSaveInProgress: (state, action: PayloadAction<boolean>) => {
			state.value.saveInProgress = action.payload;
		},

		setExportingGif: (state, action: PayloadAction<boolean>) => {
			state.value.exportingGif = action.payload;
		},

		setGifDownloadLink: (state, action: PayloadAction<string | null>) => {
			state.value.gifDownloadLink = action.payload;
		},
		setYoutubePublishPopup: (
			state,
			action: PayloadAction<{
				showPopup: boolean;
				startExport?: boolean;
			}>,
		) => {
			const newState = { ...action.payload };
			newState.startExport = newState.showPopup
				? action.payload.startExport || false
				: false;
			state.value.showYoutubePublishPopup = newState;
		},

		setShowVideoExportPopup: (
			state,
			action: PayloadAction<{
				showPopup: boolean;
				startExport?: Date | false;
				exportDetails?: ExportDetails;
			}>,
		) => {
			const newState = { ...action.payload };
			newState.startExport = newState.showPopup
				? action.payload.startExport || false
				: false;
			state.value.showVideoExportPopup = newState;
		},

		setShowGifExportPopup: (state, action: PayloadAction<boolean>) => {
			state.value.showGifExportPopup = action.payload;
		},
	},
});

export const {
	setShowVideoExportPopup,
	setYoutubePublishPopup,
	setShowGifExportPopup,
	setGifDownloadLink,
	setSaveInProgress,
	setExportingGif,
} = processingSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export default processingSlice.reducer;
