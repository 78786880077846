import { GigaUserApi } from "@giga-user-fern/api";
import { CoverType } from "@giga-user-fern/api/types/api";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { getFontSyncWhenDownloaded } from "../../../../../utils/fontsUtils";
import { Organization } from "../../../../types/guide";
import { FrontendVideoSource } from "../../../CanvasProvider";
import { dummyVideoEdits } from "../../../videoEditTypes/core";
import {
	dummyFrontendVideoSource,
	dummyOrganization,
} from "../../canvasDefaults";
import { getModifiedWidthAndHeight } from "../../resolutionUtils";
import {
	CanvasAssets,
	CanvasCoverAsset,
	CanvasImageAsset,
	CanvasScreenclip,
	CanvasVideoAsset,
} from "./CanvasAssets";

type FrontendCoverInput = {
	backgroundImg?: HTMLImageElement;
	backgroundVideo?: HTMLVideoElement;
	logo?: HTMLImageElement;
	imageElementsMap?: Map<GigaUserApi.Id, CanvasImageAsset>;
};

export const dummyCoverInput: FrontendCoverInput = {
	// backgroundImg: document.createElement("img"),
	// backgroundVideo: document.createElement("video"),
	// logo: document.createElement("img"),
};

export class FeCanvasAssets extends CanvasAssets {
	/**
	 * This class is used to manage canvas assets on the frontend.
	 * This class is to be called only after all the loading logic is handled (to be handled somewhere else.)
	 */

	imageElementsMap: Map<GigaUserApi.Id, CanvasImageAsset>;
	intro: CanvasCoverAsset;
	outro: CanvasCoverAsset;
	background: CanvasImageAsset;
	screenclip: CanvasScreenclip;
	sources: CanvasVideoAsset[];

	constructor(
		videoEdits: VideoEdits,
		frontendSources: FrontendVideoSource[],
		intro: FrontendCoverInput,
		outro: FrontendCoverInput,
		mainVideo: HTMLVideoElement,
		imageElementsMap: Map<GigaUserApi.Id, CanvasImageAsset>,
		organization: Organization,
	) {
		super(videoEdits);
		this.imageElementsMap = imageElementsMap;

		this.background = this.initBackground();
		this.intro = this.initCover("intro", intro);
		this.outro = this.initCover("outro", outro);
		this.screenclip = this.initScreenclip(frontendSources, mainVideo);
		this.sources = this.initSources(frontendSources);
	}

	createCanvas = (width: number, height: number): HTMLCanvasElement => {
		const canvas = document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;

		return canvas;
	};

	initBackground: () => CanvasImageAsset = () => {
		//initialization of background is happening here.
		//should initialization of other assets also happen here? Maybe not.

		const mainVideoBackground = new Image();

		if (this.videoEdits.background?.src) {
			mainVideoBackground.src = this.videoEdits.background.src;
		} else {
			const defaultSrc = "";
			mainVideoBackground.src = defaultSrc;
		}

		const background: CanvasImageAsset = {
			naturalHeight: mainVideoBackground.naturalHeight,
			naturalWidth: mainVideoBackground.naturalWidth,
			src: mainVideoBackground,
		};

		return background;
	};

	//#region INTRO & OUTRO

	initCover: (
		coverType: CoverType,
		a: FrontendCoverInput,
	) => CanvasCoverAsset = (coverType, a) => {
		const { backgroundImg, logo, backgroundVideo } = a;

		const videoEditCover =
			coverType === "intro"
				? this.videoEdits.intro
				: this.videoEdits.outro;

		if (!videoEditCover) {
			return {};
		}

		const { naturalHeight, naturalWidth } = videoEditCover;

		//construct cover
		const cover: CanvasCoverAsset = {
			logo: logo
				? {
						naturalHeight: logo.naturalHeight,
						naturalWidth: logo.naturalWidth,
						src: logo,
					}
				: undefined,
			bgImage: backgroundImg
				? {
						naturalHeight:
							naturalHeight ?? backgroundImg.naturalHeight,
						naturalWidth:
							naturalWidth ?? backgroundImg.naturalWidth,
						src: backgroundImg,
					}
				: undefined,
			bgVideo: backgroundVideo
				? {
						id: GigaUserApi.Id(`${coverType}_clip`),
						naturalHeight: naturalHeight ?? 1080,
						naturalWidth: naturalWidth ?? 1920,
						frame: (t) => backgroundVideo,
						duration: backgroundVideo.duration,
					}
				: undefined,
			imageElementsMap: a.imageElementsMap,
		};

		return cover;
	};

	//#endregion

	initScreenclip(
		sources: FrontendVideoSource[],
		video: HTMLVideoElement,
	): CanvasScreenclip {
		/**
		 * @param video : The HTML video element (React Ref)
		 */

		const clipId = sources.filter(
			(source) => !source.id.includes("clip_"),
		)[0].id;

		const { width, height } = getModifiedWidthAndHeight(
			video.videoWidth,
			video.videoHeight,
			1920,
			1080,
		);

		const screenclip: CanvasScreenclip = {
			id: clipId,
			naturalHeight: video.videoHeight,
			naturalWidth: video.videoWidth,
			frame: (t) => video,
			duration: video.duration,
			renderHeight: height,
			renderWidth: width,
			imageElementsMap: this.imageElementsMap,
		};

		return screenclip;
	}

	initSources(frontendSources: FrontendVideoSource[]): CanvasVideoAsset[] {
		//all source refs should be available while calling this.

		const canvasSources: CanvasVideoAsset[] = frontendSources.map(
			(source) => {
				const asset: CanvasVideoAsset = {
					id: source.id,
					naturalHeight: source.ref.current?.videoHeight ?? 1080,
					naturalWidth: source.ref.current?.videoWidth ?? 1920,
					frame: (t) => source.ref.current as HTMLVideoElement,
					duration: source.ref.current?.duration ?? 0,
				};

				return asset;
			},
		);

		return canvasSources;
	}

	getFont = getFontSyncWhenDownloaded;
}
let feCanvasAssets = null as unknown as FeCanvasAssets;
if (typeof window !== "undefined") {
	feCanvasAssets = new FeCanvasAssets(
		dummyVideoEdits,
		[dummyFrontendVideoSource],
		dummyCoverInput,
		dummyCoverInput,
		document.createElement("video"),
		new Map(),
		dummyOrganization,
	);
}

export default feCanvasAssets;
