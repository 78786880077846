/**
 * This file needs to be kept in sync with backend.
 * Any changes here need changes in the backend too!
 */

type Coordinate = { x: number; y: number };

/***
 * References: https://easings.net/
 * each core func should be a function that goes from (0,0) --> (1,1)
 */

const interpolations: { [key: string]: (x: number) => number } = {
	linear: (x: number) => x,
	easeInOutSin: (x: number) => -(Math.cos(Math.PI * x) - 1) / 2,
	easeOutSin: (x: number) => Math.sin((x * Math.PI) / 2),
	easeOutCirc: (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2)),
	easeInOutCirc: (x: number) =>
		x < 0.5
			? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
			: (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2,
	easeOutCubic: (x: number) => 1 - Math.pow(1 - x, 3),
	easeOutQuart: (x: number) => 1 - Math.pow(1 - x, 4),
	easeOutQuint: (x: number) => 1 - Math.pow(1 - x, 5),
	easeInQuart: (x: number) => x * x * x * x,
	easeInQuad: (x: number) => x * x,
	easeInOutQuart: (x: number): number => {
		return x < 0.5 ? 8 * x * x * x * x : 1 - (-2 * x + 2) ** 4 / 2;
	},
	easeInExpo: (x: number) => (x === 0 ? 0 : 2 ** (10 * x - 10)),
	easeInOutExpo: (x: number) => {
		if (x === 0) return 0;
		if (x === 1) return 1;
		if (x < 0.5) return 2 ** (20 * x - 10) / 2;
		return (2 - 2 ** (-20 * x + 10)) / 2;
	},
	easeOutExpo: (x: number) => (x === 1 ? 1 : 1 - 2 ** (-10 * x)),
	easeOutElastic: (x: number) => {
		const c4 = (2 * Math.PI) / 3;

		return x === 0
			? 0
			: x === 1
				? 1
				: 2 ** -10 * x * Math.sin((x * 10 - 0.75) * c4) + 1;
	},
	easeInOutCubic: (x: number) =>
		x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2,
	easeInOutQuint(x: number): number {
		return x < 0.5
			? 16 * x * x * x * x * x
			: 1 - Math.pow(-2 * x + 2, 5) / 2;
	},
	easeOutQuad(x: number): number {
		return 1 - (1 - x) * (1 - x);
	},
	easeInOutQuad(x: number): number {
		return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
	},
};

const fitFunction = (
	start: Coordinate,
	end: Coordinate,
	currentX: number,
	interpolateFunction: (x: number) => number,
) => {
	const x_gap = end.x - start.x;
	const y_gap = end.y - start.y;

	const x_eff = (currentX - start.x) / x_gap;
	const y_eff = y_gap;

	return y_eff * interpolateFunction(x_eff) + start.y;
};

export const interpolate = (
	start: Coordinate,
	end: Coordinate,
	currentX: number,
	interpolation?: string,
) => {
	var func = interpolations[interpolation || "linear"];
	// console.log(
	// 	"interpolate: ",
	// 	start,
	// 	end,
	// 	currentX,
	// 	interpolation,
	// 	fitFunction(start, end, currentX, func),
	// );

	return fitFunction(start, end, currentX, func);
};
