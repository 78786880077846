import { configureStore } from "@reduxjs/toolkit";
import fullScreenOverlayReducer from "../layouts/FullScreenOverlay/FullScreenOverlaySlice";

import modalReducer from "../layouts/CModal/modalSlice";
import messagePopupReducer from "../layouts/MessagePopup/messagePopupSlice";
import overlayReducer from "../layouts/Overlay/overlaySlice";
import backendReducer from "./slices/backendSlice";
import collectionDataReducer from "./slices/collectionDataSlice";
import collectionPathReducer from "./slices/collectionPathSlice";
import guideReducer from "./slices/guideSlice";
import platformReducer from "./slices/platformDetailsSlice";
import processingReducer from "./slices/processingSlice";
import lvsReducer from "./slices/LvsSlice";
import platformUiSlice from "./slices/platformUiSlice";

const store = configureStore({
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: false,
		});
	},
	reducer: {
		//Clueso Home
		backend: backendReducer,
		collectionData: collectionDataReducer,
		collectionPath: collectionPathReducer,
		platformUi: platformUiSlice,

		//GENERAL
		fullscreenOverlay: fullScreenOverlayReducer,
		overlay: overlayReducer,
		modal: modalReducer,
		messagePopup: messagePopupReducer,
		lvs: lvsReducer,

		// EDITOR
		guide: guideReducer,
		processing: processingReducer,
		platformDetails: platformReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
