import React, { useEffect, useRef, useState } from "react";
import "./RichTextToolbar.css";
import downArrowheadIcon from "../../../../../assets/svgs/downArrowheadIcon";

import magicwandIcon from "../../../../../assets/svgs/magicwandIcon";
import aiRewriteIcon from "../../../../../assets/svgs/aiRewriteIcon";
import generateSpeechIcon from "../../../../../assets/svgs/generateSpeechIcon";
import Icon from "../../../../../ui/Icon/Icon";
import undoIcon from "../../../../../assets/svgs/undoIcon";
import redoIcon from "../../../../../assets/svgs/redoIcon";
import {
	useActive,
	useChainedCommands,
	useCommands,
	useHelpers,
	useRemirrorContext,
} from "@remirror/react";
import { PlainDoc } from "@giga-user-fern/api/types/api/resources/guides";
import { cleanJSON, refillImages } from "../../utils/cleanImageSrc";
import LoadingRing from "../../../../../assets/gifs/LoadingRing/LoadingRing";
import { RemirrorJSON } from "remirror";
import logger from "../../../../../utils/logger";
import { TextEditorType } from "../../TextEditor";
import voiceIcon from "../../../../../assets/svgs/voiceIcon";
import { eventEmitter } from "../../../../../utils/events";
import { Voice } from "../../../../../core/types/guide";
import BlockTypeDropdown from "./BlockTypeDropdown/BlockTypeDropdown";
import ListOptions from "./ListOptions/ListOptions";
import BlockquoteButton from "./BlockquoteButton/BlockquoteButton";
import HRButton from "./HRButton/HRButton";
import TextStyleOptions from "./TextStyleOptions/TextStyleOptions";
import CodeTypeDropdown from "./CodeTypeDropdown/CodeTypeDropdown";
import { LinkExtension } from "remirror/dist-types/extensions";
import { useEffectOnce } from "../../../../../hooks/useEffectOnce";
import Button from "../../../../../ui/buttons/Button/Button";
import refreshIcon from "../../../../../assets/svgs/refreshIcon";
import { Tooltip } from "@chakra-ui/react";
import useAddScreenshot from "../AddScreenshot/useAddScreenshot";

import clockIcon from "../../../../../assets/svgs/clockIcon";
import imagePlus from "../../../../../assets/svgs/imagePlus";
import airplaneIcon from "../../../../../assets/svgs/airplaneIcon";

import ScreenshotDropdown from "./ScreenshotDropdown/ScreenshotDropdown";
import { getFlag } from "../../../../../utils/voices/voiceUtils";
import HoverTip from "../../../../../ui/HoverTip/HoverTip";

import { useToast } from "@chakra-ui/react";
import WhiteHoverTip from "../../../../../ui/WhiteHoverTip/WhiteHoverTip";
import generateSpeechLoadingIcon from "../../../../../assets/svgs/generateSpeechLoadingIcon";
import aiRewriteLoadingIcon from "../../../../../assets/svgs/aiRewriteIconLoading";

type RichTextToolbarProps = {
	onEnhance?: () => void;
	onChangePrompt?: () => void;
	onGenerate?: () => void;
	save?: (x: RemirrorJSON) => void;
	version: string;
	scrollContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
	fixToolbar?: boolean;
	textEditorType: TextEditorType;
	enhanceLoading?: boolean;
	generateLoading?: boolean;
	disabled?: boolean;
	process?: "enhancing" | "generating";
};

export type VoiceProps = {
	voice: Voice;
	onClickVoice: () => void;
};

const RichTextToolbar: React.FC<RichTextToolbarProps & (VoiceProps | {})> = (
	props,
) => {
	const mediumGrey = "#4b4b4b";
	const { undo } = useCommands();
	const { redo } = useCommands();
	const { setContent, manager } = useRemirrorContext();
	const { getMarkdown, getHTML } = useHelpers();
	// const dispatch = useAppDispatch()
	const addScreenshot = useAddScreenshot("videoTranscript");
	// This is being used only for transcript. Refer to ScreenshoitDropdown for the article one
	const iconColor =
		props.textEditorType === "videoTranscript" ||
		props.textEditorType === "platform"
			? "#fff"
			: mediumGrey;

	const toolbarElementRef = useRef<HTMLDivElement | null>(null);
	const [maintenanceWidth, setMaintenanceWidth] = useState<number>(350);

	const [isTop, setIsTop] = useState(false);

	var displayVoice;
	var displayFlag;
	if ("voice" in props) {
		displayVoice = props.voice;
		displayFlag = getFlag(props.voice.languageCountryId || "en-US");
	} else {
		displayFlag = getFlag("en-US");
	}
	const toast = useToast();
	const commands = useCommands();

	const enhanceDocument = async () => {
		if (!props.disabled) {
			await props.onEnhance?.();
		}
	};

	const changePrompt = () => {
		props.onChangePrompt?.();
	};

	const undoRedoKeyHandler = (e: KeyboardEvent) => {
		if (e.metaKey && e.key === "z") {
			e.preventDefault();
			undo();
			commands.removeAllInlineAiMarks();
		}
		if (e.metaKey && e.shiftKey && e.key === "z") {
			e.preventDefault();
			redo();
			commands.removeAllInlineAiMarks();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", undoRedoKeyHandler);
		return () => {
			document.removeEventListener("keydown", undoRedoKeyHandler);
		};
	}, [undoRedoKeyHandler]);

	useEffect(() => {
		const useHTML = (callback: (x: string, y: string) => void) => {
			// Simulate fetching or computing some data
			if (props.textEditorType === "platform") {
				const html = getHTML(manager.view.state);
				const markdown = getMarkdown(manager.view.state);
				callback(html, markdown);
			}
		};

		eventEmitter.on("getHTML", useHTML);

		return () => {
			eventEmitter.off("getHTML", useHTML);
		};
	}, [getHTML, manager.view.state, props.textEditorType]);
	if (props.textEditorType === "videoTranscript")
		return (
			<>
				<div
					id="gigauser-RichTextToolbar"
					ref={toolbarElementRef}
					className={`gigauser-RichTextToolbar gigauser-fix-toolbar ${
						isTop ? "gigauser-isTop" : "gigauser-notIsTop"
					}
            transcriptToolbar
            `}
				>
					<div
						className="ToolbarRow1"
						onContextMenu={(e: any) => {
							e.preventDefault();
							changePrompt();
						}}
					>
						<div className="ToolbarRow1-left">
							<HoverTip
								className="genspeech-container"
								hoverTipTitle={"Generate Speech"}
								hoverTipPara="Click here to generate the new voiceover for your video from the script."
							>
								<div
									className={`GenerateSpeechButton ${props.disabled ? "disabled" : "enabled"}`}
									onClick={
										props.disabled
											? undefined
											: props.onGenerate
									}
								>
									<Icon className="generateSpeechIcon d-small-none">
										{props.generateLoading
											? generateSpeechLoadingIcon(
													"#e179af",
												)
											: generateSpeechIcon("#e179af")}
									</Icon>
									<div>
										{" "}
										{props.generateLoading ? (
											"Generating ..."
										) : (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
													justifyContent:
														"space-between",
													gap: "0.2rem",
												}}
											>
												<span>Generate</span>
												<span className="d-none">
													Speech
												</span>
											</div>
										)}{" "}
									</div>
								</div>
							</HoverTip>

							<HoverTip
								className="aiRewrite-container"
								hoverTipTitle={"AI Rewrite"}
								hoverTipPara="Rephrases your script while keeping content intact. Removes filler words and creates a concise, engaging script for your video."
							>
								<div
									className={`AI-rewrite-button ${props.disabled ? "disabled" : "enabled"}`}
									onClick={enhanceDocument}
								>
									<div className="ai-rewrite-button-inner">
										{props.enhanceLoading ? (
											<div className="ai-rewrite-icon d-small-none">
												{aiRewriteLoadingIcon("white")}
											</div>
										) : (
											<Icon className="ai-rewrite-icon d-small-none">
												{aiRewriteIcon("white")}
											</Icon>
										)}

										<div className="ai-rewrite-label">
											{props.enhanceLoading ? (
												"Writing..."
											) : (
												<div
													style={{
														display: "flex",
														alignItems: "center",
														width: "100%",
														justifyContent:
															"space-between",
														gap: "0.2rem",
													}}
												>
													<span className="d-none">
														AI
													</span>
													<span>Rewrite</span>
												</div>
											)}
										</div>

										<div></div>
									</div>

									<div
										className="down-icon-container d-none"
										onClick={(e) => {
											changePrompt();
											e.stopPropagation();
										}}
									>
										<Icon className="down-icon">
											{downArrowheadIcon()}
										</Icon>
									</div>
								</div>
							</HoverTip>
						</div>

						<HoverTip
							hoverTipTitle={"Add Sync Marker"}
							hoverTipPara="Enforce sync between the voiceover and video by adding frames to your script."
						>
							<TranscriptToolbarButton
								className="TranscriptToolbarButton addSyncMarker-container"
								icon={imagePlus("#ffffff")}
								onClick={addScreenshot}
								disabled={props.disabled}
								// rank="minor"
								// hoverTip="Add Sync Marker"
							/>
						</HoverTip>
					</div>
				</div>

				<div className="ToolbarRow2">
					<Icon
						hoverTip="Undo"
						hoverPosition="top"
						onClick={undo}
						className="TranscriptToolbarButton minor undo-icon"
					>
						{undoIcon("white")}
					</Icon>

					<Icon
						hoverTip="Redo"
						hoverPosition="top"
						onClick={redo}
						className="TranscriptToolbarButton minor redo-icon"
					>
						{redoIcon("white")}
					</Icon>
				</div>
			</>
		);

	return (
		<div
			id="gigauser-RichTextToolbar"
			ref={toolbarElementRef}
			className={`gigauser-RichTextToolbar gigauser-fix-toolbar ${
				isTop ? "gigauser-isTop" : "gigauser-notIsTop"
			}
		gigauser-dark
		${props.textEditorType === "platform" ? "gigauser-dark fullWidthToolbar" : ""}
		`}
		>
			<div className="gigauser-toolbar-special-buttons">
				{"voice" in props ? (
					<div
						className="boxIcon-container voiceIcon"
						onClick={props.onClickVoice}
					>
						<Icon className="gigauser-RichTextToolbar-voice-icon">
							{voiceIcon("#FE78BD")}
						</Icon>

						<span className="gigauser-voice-name">
							{displayVoice?.name}
						</span>
					</div>
				) : null}

				{props.textEditorType === "platform" && (
					<>
						<BlockTypeDropdown
							disabled={props.disabled}
						></BlockTypeDropdown>
						<div className="toolbar-special-buttons-divider"></div>
						<TextStyleOptions
							disabled={props.disabled}
						></TextStyleOptions>
						<CodeTypeDropdown
							disabled={props.disabled}
						></CodeTypeDropdown>
					</>
				)}
			</div>
			<div className="toolbar-special-buttons-divider"></div>
			<div className="gigauser-toolbar-regular-buttons">
				<div
					className="gigauser-RichTextToolbar-reg-icon-container"
					onClick={() => {
						undo();
						commands.removeAllInlineAiMarks();
					}}
				>
					<Icon
						disabled={props.disabled}
						className={`gigauser-RichTextToolbar-reg-icon ${props.disabled ? "icon-disabled" : "icon-nondisabled"}`}
					>
						{undoIcon(iconColor)}
					</Icon>
					<div className="reg-icon-container-hovertip">
						<WhiteHoverTip text="Undo" shortcutKey="Command+Z" />
					</div>
				</div>

				<div
					className="gigauser-RichTextToolbar-reg-icon-container"
					onClick={() => {
						redo();
						commands.removeAllInlineAiMarks();
					}}
				>
					<Icon
						disabled={props.disabled}
						className={`gigauser-RichTextToolbar-reg-icon ${props.disabled ? "icon-disabled" : "icon-nondisabled"}`}
					>
						{redoIcon(iconColor)}
					</Icon>
					<div className="reg-icon-container-hovertip">
						<WhiteHoverTip
							text="Redo"
							shortcutKey="Command+Shift+ZorCtrl+Y"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export type TranscriptButtonProps = {
	icon?: JSX.Element;
	text?: string;
	className?: string;
	dropdown?: boolean;
	onClick?: () => void;

	loading?: boolean;
	rank?: "major" | "minor";
	hoverTip?: string;
	disabled?: boolean;
};

export const TranscriptToolbarButton: React.FC<TranscriptButtonProps> = (
	props,
) => {
	return (
		<div
			onClick={
				props.loading || props.disabled ? undefined : props.onClick
			}
			className={` ${props.className} ${props.rank}  ${props.disabled ? "disabled" : "enabled"}`}
		>
			{props.icon && props.loading ? (
				<div className="transbuttonloading">
					<LoadingRing color="hsl(224, 13%, 57%)" />
				</div>
			) : (
				<Icon
					hoverTip={props.hoverTip}
					hoverPosition={props.rank === "minor" ? "top" : "bottom"}
					className={`transcriptToolbarIcon ${props.text ? "yestext" : "notext"}`}
				>
					{props.icon}
				</Icon>
			)}
			{props.text ? (
				<div className="TranscriptToolbarButtonText">{props.text}</div>
			) : null}
			{props.dropdown ? (
				<Icon className="trans-down-icon">
					{downArrowheadIcon("#bfc7d4")}
				</Icon>
			) : null}
		</div>
	);
};

export default RichTextToolbar;
