import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";

export const formatTime = (time: number, showHours = true) => {
	/**
	 * time: seconds
	 */
	const hours = showHours ? Math.floor(time / 3600) : 0;
	const minutes = showHours
		? Math.floor((time % 3600) / 60)
		: Math.floor(time / 60);
	const seconds = Math.floor(time % 60);
	const milliseconds = Math.floor((time % 1) * 1000);

	const formattedHours = hours.toString().padStart(2, "0") || "00";
	const formattedMinutes = minutes.toString().padStart(2, "0") || "00";
	const formattedSeconds = seconds.toString().padStart(2, "0") || "00";
	const formattedMilliseconds =
		milliseconds.toString().padStart(3, "0").charAt(0) || "0";

	return {
		fullString: `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`,
		hours: formattedHours,
		minutes: formattedMinutes,
		seconds: formattedSeconds,
		milliseconds: formattedMilliseconds,
	};
};

export const round10 = (num: number) => {
	return Math.round(num * 10) / 10;
};
export const simpleFormatTime = (inputSeconds: number) => {
	const roundedSeconds = round10(inputSeconds);

	// Calculate the number of minutes and remaining seconds
	const minutes = Math.floor(roundedSeconds / 60);
	const remainingSeconds = round10(roundedSeconds % 60);

	if (minutes === 0) {
		return `${remainingSeconds} s`;
	}

	return `${minutes}m ${remainingSeconds}s`;
};

/**
 *  calculateMediaDuration()
 *  Force media element duration calculation.
 *  Returns a promise, that resolves when duration is calculated
 **/
export function calculateMediaDuration(
	media: HTMLMediaElement,
	callback?: (x: number) => void,
) {
	return new Promise((resolve, reject) => {
		media.onloadedmetadata = () => {
			// set the mediaElement.currentTime  to a high value beyond its real duration
			media.currentTime = Number.MAX_SAFE_INTEGER;
			// listen to time position change
			media.ontimeupdate = () => {
				media.ontimeupdate = () => {};
				// setting player currentTime back to 0 can be buggy too, set it first to .1 sec
				media.currentTime = 0.1;
				media.currentTime = 0;
				// media.duration should now have its correct value, return it...

				callback?.(media.duration as number);
				resolve(media.duration as number);
			};
		};
	});
}

export function calculateMediaDurationWithTimeout(
	media: HTMLMediaElement,
	callback?: (x: number) => void,
	timeout?: number,
) {
	return new Promise((resolve, reject) => {
		const timeoutId = setTimeout(() => {
			clearTimeout(timeoutId);
			reject(new Error("Timeout exceeded"));
		}, timeout || 2000);

		media.currentTime = Number.MAX_SAFE_INTEGER;

		media.onloadedmetadata = () => {
			media.currentTime = Number.MAX_SAFE_INTEGER;
			media.ontimeupdate = () => {
				media.ontimeupdate = () => {};
				media.currentTime = 0.1;
				media.currentTime = 0;
				resolve(media.duration as number);
				callback?.(media.duration as number);
				clearTimeout(timeoutId);
			};
		};
	});
}

export function stripIntroAndOutro(videoEdits?: VideoEdits) {
	if (!videoEdits) return null;
	const videoEditsCopy = JSON.parse(JSON.stringify(videoEdits));
	videoEditsCopy.intro = null;
	videoEditsCopy.outro = null;
	return videoEditsCopy;
}
