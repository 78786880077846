const gs = (_color?: string) => {
	var color = _color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M4.375 7.5V12.5C4.375 12.6658 4.30915 12.8247 4.19194 12.9419C4.07473 13.0592 3.91576 13.125 3.75 13.125C3.58424 13.125 3.42527 13.0592 3.30806 12.9419C3.19085 12.8247 3.125 12.6658 3.125 12.5V7.5C3.125 7.33424 3.19085 7.17527 3.30806 7.05806C3.42527 6.94085 3.58424 6.875 3.75 6.875C3.91576 6.875 4.07473 6.94085 4.19194 7.05806C4.30915 7.17527 4.375 7.33424 4.375 7.5ZM6.875 1.875C6.70924 1.875 6.55027 1.94085 6.43306 2.05806C6.31585 2.17527 6.25 2.33424 6.25 2.5V17.5C6.25 17.6658 6.31585 17.8247 6.43306 17.9419C6.55027 18.0592 6.70924 18.125 6.875 18.125C7.04076 18.125 7.19973 18.0592 7.31694 17.9419C7.43415 17.8247 7.5 17.6658 7.5 17.5V2.5C7.5 2.33424 7.43415 2.17527 7.31694 2.05806C7.19973 1.94085 7.04076 1.875 6.875 1.875ZM10 4.375C9.83424 4.375 9.67527 4.44085 9.55806 4.55806C9.44085 4.67527 9.375 4.83424 9.375 5V15C9.375 15.1658 9.44085 15.3247 9.55806 15.4419C9.67527 15.5592 9.83424 15.625 10 15.625C10.1658 15.625 10.3247 15.5592 10.4419 15.4419C10.5592 15.3247 10.625 15.1658 10.625 15V5C10.625 4.83424 10.5592 4.67527 10.4419 4.55806C10.3247 4.44085 10.1658 4.375 10 4.375ZM13.125 6.875C12.9592 6.875 12.8003 6.94085 12.6831 7.05806C12.5658 7.17527 12.5 7.33424 12.5 7.5V12.5C12.5 12.6658 12.5658 12.8247 12.6831 12.9419C12.8003 13.0592 12.9592 13.125 13.125 13.125C13.2908 13.125 13.4497 13.0592 13.5669 12.9419C13.6842 12.8247 13.75 12.6658 13.75 12.5V7.5C13.75 7.33424 13.6842 7.17527 13.5669 7.05806C13.4497 6.94085 13.2908 6.875 13.125 6.875ZM16.25 5.625C16.0842 5.625 15.9253 5.69085 15.8081 5.80806C15.6908 5.92527 15.625 6.08424 15.625 6.25V13.75C15.625 13.9158 15.6908 14.0747 15.8081 14.1919C15.9253 14.3092 16.0842 14.375 16.25 14.375C16.4158 14.375 16.5747 14.3092 16.6919 14.1919C16.8092 14.0747 16.875 13.9158 16.875 13.75V6.25C16.875 6.08424 16.8092 5.92527 16.6919 5.80806C16.5747 5.69085 16.4158 5.625 16.25 5.625Z"
				fill={color}
			/>
		</svg>
	);
};

export default gs;
