import {
	GuideData,
	GuidePreview,
} from "@giga-user-fern/api/types/api/resources/guides";
import React, { useEffect, useRef, useState } from "react";
import { Device } from "../../../types/devices";
import {
	MediaCommunitySkin,
	MediaOutlet,
	useMediaStore,
	MediaPlayer,
	MediaPoster,
	MediaPlayButton,
	useMediaRemote,
} from "@vidstack/react";
import type {
	MediaPlayEvent,
	MediaPlayFailEvent,
	MediaPlayRequestEvent,
	MediaPauseEvent,
	MediaPlayerElement,
} from "vidstack";
import "./SimpleVideo.css";
import useWindowSize from "../../../hooks/useWindowSize";
import { calculateMediaDuration } from "../../../core/canvas/canvasUtils/videoUtils";
import { HelpCenterProps } from "../../../help-center/HelpCenter/HelpCenter";
import { captureEventInPanchayat } from "../../../utils/panchayat";

type EmbedVideoProps = {
	video: GuideData["video"];
	videoSrc?: string;
	guidePreview?: GuidePreview;
	overrideDevice?: Device;
	helpCenterProps?: HelpCenterProps;
};

const EmbedVideo: React.FC<EmbedVideoProps> = (props) => {
	let device: Device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;
	const vidRef = useRef<HTMLVideoElement>(null);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const playbackPointRef = useRef(0);
	const player = useRef<MediaPlayerElement>(null);
	const { currentTime, duration } = useMediaStore(player);
	const [htmlVideoDuration, setHtmlVideoDuration] = useState(0);

	const startEventLoggingInPanchayat = () => {
		stopEventLoggingInPanchayat();

		intervalRef.current = setInterval(() => {
			const playbackTime = playbackPointRef.current;
			const videoDuration = Math.floor(duration || htmlVideoDuration);

			if (props.helpCenterProps && videoDuration) {
				captureEventInPanchayat({
					eventName: "VideoPlayedInHelpCenter",
					value: {
						guide_id: props.guidePreview?.id.toString() as string,
						organization_id: props.helpCenterProps
							.getOrganization()
							?.id.toString() as string,
						playback_point_in_seconds: Math.floor(playbackTime),
						session_id: props.helpCenterProps
							.helpCenterSessionId as string,
						video_duration_in_seconds: videoDuration,
						percentage_played:
							Math.floor(
								(Math.floor(playbackTime) / videoDuration) *
									100,
							) > 100
								? 100
								: Math.floor(
										(Math.floor(playbackTime) /
											videoDuration) *
											100,
									),
					},
				});
			}
		}, 1000);
	};

	const stopEventLoggingInPanchayat = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	useEffect(() => {
		playbackPointRef.current = currentTime; // Keep the ref up-to-date
	}, [currentTime]);

	const onMediaPlay = (event: MediaPlayEvent) => {
		startEventLoggingInPanchayat();
	};

	const onMediaPause = (event: MediaPauseEvent) => {
		stopEventLoggingInPanchayat();
	};

	// Handlers for the simple video player
	const onVideoPlay = () => {
		startEventLoggingInPanchayat();
	};

	const onVideoPause = () => {
		stopEventLoggingInPanchayat();
	};

	// Track playback progress for the simple video player
	useEffect(() => {
		if (vidRef.current) {
			const video = vidRef.current;

			const updatePlaybackPoint = () => {
				playbackPointRef.current = video.currentTime;
			};

			video.addEventListener("timeupdate", updatePlaybackPoint);
			return () => {
				video.removeEventListener("timeupdate", updatePlaybackPoint);
			};
		}
	}, []);

	useEffect(() => {
		// logger.debug("src: ", props.video.metadata?.generatedVideo?.src)

		if (device === "mobile" && vidRef.current) {
			calculateMediaDuration(vidRef.current);
		}

		return () => {
			stopEventLoggingInPanchayat();
		};
	}, []);

	if (device === "laptop")
		return (
			<MediaPlayer
				title={props.guidePreview?.header.name}
				src={props.videoSrc || props.video.originalSrc}
				poster="https://media-files.vidstack.io/poster.png"
				onPlay={onMediaPlay}
				onPause={onMediaPause}
				ref={player}

				// poster="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980"
				// thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
				// aspectRatio={16 / 9}
				// crossorigin=""
			>
				<MediaOutlet>
					{props.video.generated?.subtitles && (
						<track
							label="English"
							kind="subtitles"
							srcLang="en"
							src={props.video.generated?.subtitles}
							// default
						/>
					)}
				</MediaOutlet>

				<MediaCommunitySkin />
			</MediaPlayer>
		);
	else {
		return (
			<video
				ref={vidRef}
				preload="metadata"
				controls
				crossOrigin="anonymous"
				className="gigauser-editor-video"
				src={props.videoSrc || props.video.originalSrc}
				onPlay={onVideoPlay}
				onPause={onVideoPause}
				onLoadedMetadata={(e) => {
					const video = e.target as HTMLVideoElement;
					setHtmlVideoDuration(video.duration || 0); // Store duration
				}}
			>
				{props.video.generated?.subtitles && (
					<track
						label="English"
						kind="subtitles"
						srcLang="en"
						src={props.video.generated?.subtitles}
					/>
				)}
			</video>
		);
	}
};
export default EmbedVideo;
