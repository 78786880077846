import { GigaUserApi } from "@giga-user-fern/api";
import { Coordinates, ElementEdit } from "@giga-user-fern/api/types/api";
import { Shape } from "../../../../../components/formats/RichText/components/EditScreenshot/ScreenshotEditorContext";

export class MutableElement {
	element: Shape;

	constructor(element: Shape) {
		this.element = element;
	}

	getPosition() {
		if (this.element.geo === "text") {
			if (this.element.textdata?.alignment === "center") {
				return {
					x: this.element.position[0] - this.element.size[0] / 2,
					y: this.element.position[1] - this.element.size[1] / 2,
				};
			} else if (this.element.textdata?.alignment === "right") {
				return {
					x: this.element.position[0] - this.element.size[0],
					y: this.element.position[1],
				};
			}
		}
		return { x: this.element.position[0], y: this.element.position[1] };
	}

	setPositionFromTopLeft(topLeft: Coordinates) {
		if (this.element.geo === "text") {
			if (this.element.textdata?.alignment === "center") {
				return {
					x: topLeft.x + this.element.size[0] / 2,
					y: topLeft.y + this.element.size[1] / 2,
				};
			} else if (this.element.textdata?.alignment === "right") {
				return {
					x: topLeft.x - this.element.size[0],
					y: topLeft.y,
				};
			}
		}
		return { x: topLeft.x, y: topLeft.y };
	}

	distanceFromCenter() {
		const pos = this.getPosition();

		if (this.element.geo === "text") {
			if (this.element.textdata?.alignment === "center") {
				return {
					x: 0.5 - pos.x,
					y: 0.5 - pos.y,
				};
			} else if (this.element.textdata?.alignment === "right") {
				return {
					x: 0.5 + this.element.size[0] / 2 - pos.x,
					y: 0.5 - this.element.size[1] / 2 - pos.y,
				};
			}
		}
		return {
			x: 0.5 - this.element.size[0] / 2 - pos.x,
			y: 0.5 - this.element.size[1] / 2 - pos.y,
		};
	}
}

const dummyElementEdit: ElementEdit = {
	id: GigaUserApi.Id("dummy"),
	geo: "text",
	startTime: 0,
	endTime: 0,
	position: [0, 0],
	size: [0, 0],
};

export default new MutableElement(dummyElementEdit);
