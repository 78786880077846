import "./Skeleton.css";

export type SkeletonVariant = "default" | "circle" | "rounded";

export interface SkeletonProps {
	width?: string | number;
	height?: string | number;
	shimmerGradient?: string;
	sheenWidth?: string;
	baseColor?: string;
	className?: string;
	style?: React.CSSProperties;
	variant?: SkeletonVariant;
	animationDuration?: number;
}

const DEFAULT_GRADIENT =
	"linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)";

export const Skeleton: React.FC<SkeletonProps> = ({
	width = "100%",
	height = "100%",
	shimmerGradient = DEFAULT_GRADIENT,
	sheenWidth = 50,
	baseColor = "#e2e5e7",
	className = "",
	style = {},
	variant = "default",
	animationDuration = 2,
}) => {
	const variantClass = variant !== "default" ? `skeleton--${variant}` : "";

	const customStyles: React.CSSProperties = {
		width,
		height,
		backgroundColor: baseColor,
		"--sheen-width": `${sheenWidth}`,
		"--shimmer-gradient": shimmerGradient,
		"--animation-duration": `${animationDuration}s`,
		...style,
	} as React.CSSProperties;

	return (
		<div
			className={`skeleton ${variantClass} ${className}`}
			style={customStyles}
		/>
	);
};
