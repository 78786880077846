import { Cover } from "@giga-user-fern/api/types/api/resources/templates";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import axios from "axios";

export type Font = {
	family?: string;
	variants?: string[];
	subsets?: string[];
	version?: string;
	lastModified?: string;
	files?: {
		[key: string]: string;
	};
	category?: string;
	kind?: string;
	menu?: string;
};

type FontURLProps = {
	family?: string;
	subset?: string;
	capability?: "VF" | "WOFF2";
	sort?: "alpha" | "date" | "popularity" | "style" | "trending";
};

export const getAPI = async (url: string) => {
	try {
		const response = await axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const getFontURL = ({
	family,
	subset,
	capability,
	sort,
}: FontURLProps) => {
	let BASE_URL = process.env.REACT_APP_GOOGLE_FONTS_API_URL;

	if (!BASE_URL) throw new Error("Fonts API is not defined");

	if (family) BASE_URL += `&family=${family}`;
	if (subset) BASE_URL += `&subset=${subset}`;
	if (capability) BASE_URL += `&capability=${capability}`;
	if (sort) BASE_URL += `&sort=${sort}`;

	return BASE_URL;
};

export const getFontURLWithWeight = async (family: string) => {
	const url = getFontURL({ family });
	const response = await getAPI(url);

	const font = response.items.find((item: Font) => item.family === family);
	if (!font) return false;
	let fontWeight = "600";

	if (!font?.files[fontWeight]) {
		fontWeight = "regular";
	}

	return {
		url: font?.files[fontWeight],
		fontName: family,
		fontWeight: fontWeight,
	};
};

export const getFonts = async () => {
	const url = getFontURL({});
	const response = await getAPI(url);

	return response.items.map((font: Font) => font.family);
};

export const getFont = async (isTemplate: boolean, family?: string) => {
	if (family === undefined) {
		if (isTemplate) {
			family = "League Spartan";
		} else {
			family = "Inter";
		}
	}

	try {
		try {
			const fontValue = isFontInDocument(family);

			if (fontValue) {
				return fontValue;
			}
		} catch (e) {
			console.error("cannot load from document");
		}

		try {
			const key = await getFontFromLocal(family);
			if (key) {
				return key;
			}
		} catch (e) {
			console.error("cannot load local");
		}

		//If the font has not been loaded yet, go here:
		console.log("going to get font by URL");
		const fontResp = await getFontURLWithWeight(family);

		if (!fontResp) throw new Error("Font not found");
		const { fontName, fontWeight, url: fontUrl } = fontResp;

		try {
			localStorage.setItem(`${fontName}-${fontWeight}`, fontUrl);

			if (fontName && fontUrl) {
				const fontFace = new FontFace(
					`${fontName}-${fontWeight}`,
					`url(${fontUrl})`,
				);
				await fontFace.load();
				// @ts-ignore
				document.fonts.add(fontFace);
			}
		} catch (e) {}

		return `${fontName}-${fontWeight}`;
	} catch (error) {
		console.log("there was an error: ", error);
		return false;
	}
};

export const isFontInDocument = (family: string) => {
	// biome-ignore lint/style/noVar: <explanation>
	var fontFamily: string | boolean = false;

	// biome-ignore lint/complexity/noForEach: <explanation>
	document.fonts.forEach((font) => {
		const splitFont = font.family.split("-");

		if (splitFont[0]?.toLowerCase() === family?.toLowerCase()) {
			if (
				!splitFont[1] ||
				!(
					splitFont[1]?.toLowerCase() === "regular" ||
					splitFont[1]?.toLowerCase() === "600"
				)
			) {
				//no scene
			} else {
				fontFamily = font.family;
			}
		}
	});

	return fontFamily;
};

export const getFontFromLocal = async (family: string) => {
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i) || "";
		// biome-ignore lint/suspicious/noDoubleEquals: <explanation>
		if (key.split("-")[0] == family && localStorage.getItem(key) != null) {
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			const url = localStorage.getItem(key)!;
			if (key && url) {
				const fontFace = new FontFace(key, `url(${url})`);
				await fontFace.load();
				// @ts-ignore
				document.fonts.add(fontFace);
			}
			return key;
		}
	}
};

export const getFontSyncWhenDownloaded = (family: string | undefined) => {
	// biome-ignore lint/style/noVar: <explanation>
	var fontFamily: string | boolean = false;

	if (family === undefined) {
		family = "Inter";
	}

	// biome-ignore lint/complexity/noForEach: <explanation>
	document.fonts.forEach((font) => {
		const splitFont = font.family.split("-");

		if (splitFont[0]?.toLowerCase() === family?.toLowerCase()) {
			if (
				!splitFont[1] ||
				!(
					splitFont[1]?.toLowerCase() === "regular" ||
					splitFont[1]?.toLowerCase() === "600"
				)
			) {
				//no scene
			} else {
				fontFamily = font.family;
			}
		}
	});

	if (fontFamily) {
		return fontFamily;
	} else {
		// console.error("Cannot find font in document: ", family);
		return "Inter";
	}
};

export const extractFontsFromCover = (cover?: Cover): string[] => {
	const fonts: string[] = [];
	if (cover?.font) {
		fonts.push(cover.font);
	}
	for (const ele of cover?.coverEdits ?? []) {
		if (ele.geo === "text") {
			fonts.push(ele.textdata?.font || "");
		}
	}
	return fonts;
};

export const extractFontsRequired = (videoEdits: VideoEdits): string[] => {
	const fonts =
		videoEdits.elements?.map((element) => {
			return element.textdata?.font || "";
		}) ?? [];

	// Use the helper function to add intro and outro fonts
	fonts.push(...extractFontsFromCover(videoEdits?.intro));
	fonts.push(...extractFontsFromCover(videoEdits?.outro));

	return fonts.filter((font) => font !== "");
};

export const loadAllFonts = async (fonts: string[]) => {
	return new Promise(async (resolve, reject) => {
		//Load the default fonts.
		await getFont(false, "Inter");
		await getFont(false, "League Spartan");

		//Load the custom fonts.
		for (const font of fonts) {
			await getFont(false, font);
		}

		const allFonts = document.fonts;

		// This will store the promises for each font load
		const loadPromises: Promise<FontFace | null>[] = [];
		// Loop through each FontFace object
		// @ts-ignore
		for (const font of allFonts as any as Iterable<FontFace>) {
			// Each font can be loaded by calling its load method, which returns a promise
			const loadPromise = font.load().catch((error: any) => {
				console.error("Failed to load font:", font.family, error);
				return null; // Return null in case of an error to not break the Promise.all
			});
			loadPromises.push(loadPromise);
		}

		// Wait for all fonts to be loaded
		Promise.all(loadPromises).then((loadedResults) => {
			// Filter out any null results due to failed loads
			const successfulResults = loadedResults.filter(
				(result) => result !== null,
			);

			if (successfulResults.length > 0) {
				console.log("All fonts loaded successfully.");
			} else {
				console.log("No fonts were loaded.");
			}

			resolve(true);
		});
	});
};
