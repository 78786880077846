import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GigaUserApi } from "@giga-user-fern/api";
import { getBrandingColor } from "../../core/utils/styleUtils";

// export type PlatformHomePage =
// 	| "home"
//     | "projects"
// 	| "bin"
// 	| "organization"
// 	| "anal"
// 	| "admin"
// 	| "hosting"
// 	| "help-center-config"
// 	| "settings"
// 	| "usage";

export type VideoCustomizerPage =
	| "Intro"
	| "Background"
	| "Outro"
	| "Zooms"
	| "Elements"
	| "Music"
	| "Voiceover"
	| "Outro_Transitions"
	| "Intro_Transitions"
	| "Transitions"
	| "Subtitles";
export type ActiveFormat = "Video" | "Article";
export type TemplateBuilderFlowStack = "intro" | "outro" | "elements";

type PlatformUiState = {
	value: {
		// homePage: PlatformHomePage;
		activeFormat: ActiveFormat;
		videoUploadProgress: number | null;
		customizerPage: VideoCustomizerPage | null;
		activeZoom: GigaUserApi.Id | null;
		activeElement: GigaUserApi.Id | null;
		hardRefreshTranscript: boolean;
		exportSrtFile: boolean;

		activeIntroTemplate: GigaUserApi.Id | null;
		activeOutroTemplate: GigaUserApi.Id | null;
		activeBackgroundTemplate: GigaUserApi.Id | null;
		activeCoverElement: GigaUserApi.Id | null;
		activeArea: "video" | "element" | "background" | null;
		activeElementDefaultTab: "design" | "animation";
		showImageElementUploadPopup: boolean;
		elementsCustomizerDefaultTab: "add-elements" | "my-elements";
		activeTransitionCustomizerTab: "intro" | "outro" | null;
		activeIntroTab: "my-templates" | "library";
		activeOutroTab: "my-templates" | "library";
		templateBuilderFlowStack: TemplateBuilderFlowStack[];
		introTemplateBuilderOpen: boolean;
		outroTemplateBuilderOpen: boolean;

		backgroundPatternColor: string;

		introTransitionColor: string;
		outroTransitionColor: string;
	};
};

const initialState: PlatformUiState = {
	value: {
		// homePage: "home",
		activeFormat: "Video",
		videoUploadProgress: null,
		customizerPage: "Voiceover",
		activeZoom: null,
		activeElement: null,
		hardRefreshTranscript: false,
		exportSrtFile: false,
		activeIntroTemplate: GigaUserApi.Id(""),
		activeOutroTemplate: GigaUserApi.Id(""),
		activeBackgroundTemplate: GigaUserApi.Id(""),
		activeCoverElement: null,
		activeArea: null,
		activeElementDefaultTab: "design",
		showImageElementUploadPopup: false,
		elementsCustomizerDefaultTab: "add-elements",
		activeTransitionCustomizerTab: "intro",
		templateBuilderFlowStack: [],
		introTemplateBuilderOpen: false,
		outroTemplateBuilderOpen: false,
		activeIntroTab:
			(localStorage.getItem("activeIntroTemplateBuilderTab") as
				| "my-templates"
				| "library") || "library",
		activeOutroTab:
			(localStorage.getItem("activeOutroTemplateBuilderTab") as
				| "my-templates"
				| "library") || "library",

		backgroundPatternColor:
			localStorage.getItem("BackgroundPatternColor") || "#d43f8c",
		introTransitionColor:
			localStorage.getItem("IntroTransitionColor") || "#d43f8c",
		outroTransitionColor:
			localStorage.getItem("OutroTransitionColor") || "#d43f8c",
	},
};

export const platformUiSlice = createSlice({
	name: "platformUi",
	initialState: initialState,
	reducers: {
		// setHomePage: (state, action: PayloadAction<PlatformHomePage>) => {
		// 	state.value.homePage = action.payload;
		// },

		setActiveFormat: (state, action: PayloadAction<ActiveFormat>) => {
			state.value.activeFormat = action.payload;
		},

		setCustomizerPage: (
			state,
			action: PayloadAction<VideoCustomizerPage | null>,
		) => {
			state.value.customizerPage = action.payload;
		},

		setActiveZoom: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeZoom = action.payload;
		},

		setActiveElement: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeCoverElement = null;
			state.value.activeElement = action.payload;
		},

		setActiveArea: (
			state,
			action: PayloadAction<"video" | "element" | "background" | null>,
		) => {
			state.value.activeArea = action.payload;
		},

		setIntroTemplateBuilderOpen: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.value.introTemplateBuilderOpen = action.payload;
		},

		setOutroTemplateBuilderOpen: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.value.outroTemplateBuilderOpen = action.payload;
		},

		addToTemplateBuilderFlowStack: (
			state,
			action: PayloadAction<TemplateBuilderFlowStack>,
		) => {
			state.value.templateBuilderFlowStack.push(action.payload);
		},

		popFromTemplateBuilderFlowStack: (state) => {
			state.value.templateBuilderFlowStack.pop();
		},

		clearTemplateBuilderFlowStack: (state) => {
			state.value.templateBuilderFlowStack = [];
		},

		setBackgroundPatternColor: (state, action: PayloadAction<string>) => {
			state.value.backgroundPatternColor = action.payload;
		},

		setIntroTransitionMenuColor: (state, action: PayloadAction<string>) => {
			state.value.introTransitionColor = action.payload;
		},

		setOutroTransitionMenuColor: (state, action: PayloadAction<string>) => {
			state.value.outroTransitionColor = action.payload;
		},

		setShowImageElementUploadPopup: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.value.showImageElementUploadPopup = action.payload;
		},

		setActiveElementDefaultTab: (
			state,
			action: PayloadAction<"design" | "animation">,
		) => {
			state.value.activeElementDefaultTab = action.payload;
		},

		setActiveTransitionCustomizerTab: (
			state,
			action: PayloadAction<"intro" | "outro" | null>,
		) => {
			state.value.activeTransitionCustomizerTab = action.payload;
		},

		setElementsCustomizerDefaultTab: (
			state,
			action: PayloadAction<"add-elements" | "my-elements">,
		) => {
			state.value.elementsCustomizerDefaultTab = action.payload;
		},

		setActiveIntroTab: (
			state,
			action: PayloadAction<"my-templates" | "library">,
		) => {
			state.value.activeIntroTab = action.payload;
		},

		setActiveOutroTab: (
			state,
			action: PayloadAction<"my-templates" | "library">,
		) => {
			state.value.activeOutroTab = action.payload;
		},

		setActiveCoverElement: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeElement = null;
			state.value.activeCoverElement = action.payload;
		},

		setHardRefreshTranscript: (state, action: PayloadAction<boolean>) => {
			state.value.hardRefreshTranscript = action.payload;
		},

		setExportSrtFile: (state, action: PayloadAction<boolean>) => {
			state.value.exportSrtFile = action.payload;
		},

		setVideoUploadProgress: (state, action: PayloadAction<number>) => {
			state.value.videoUploadProgress = action.payload;
		},

		setActiveIntroTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeIntroTemplate =
				action.payload || GigaUserApi.Id("");
		},

		setActiveOutroTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeOutroTemplate =
				action.payload || GigaUserApi.Id("");
		},

		setActiveBackgroundTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeBackgroundTemplate =
				action.payload || GigaUserApi.Id("");
		},
	},
});

export const {
	setActiveBackgroundTemplate,
	setActiveIntroTemplate,
	setActiveOutroTemplate,
	setVideoUploadProgress,
	setExportSrtFile,
	setActiveZoom,
	setActiveElement,
	setActiveArea,
	addToTemplateBuilderFlowStack,
	popFromTemplateBuilderFlowStack,
	clearTemplateBuilderFlowStack,
	setHardRefreshTranscript,
	setIntroTemplateBuilderOpen,
	setOutroTemplateBuilderOpen,
	// setHomePage,
	setCustomizerPage,
	setActiveCoverElement,
	setActiveFormat,
	setActiveElementDefaultTab,
	setElementsCustomizerDefaultTab,
	setActiveTransitionCustomizerTab,
	setShowImageElementUploadPopup,
	setBackgroundPatternColor,
	setIntroTransitionMenuColor,
	setOutroTransitionMenuColor,
	setActiveIntroTab,
	setActiveOutroTab,
} = platformUiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default platformUiSlice.reducer;
