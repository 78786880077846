import { GigaUserApi } from "@giga-user-fern/api";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";

type CanvasMediaAsset = {
	naturalHeight: number;
	naturalWidth: number;
};

export type CanvasVideoAsset = {
	id: GigaUserApi.Id;
	frame: (t: number) => HTMLImageElement | HTMLVideoElement;
	duration: number;
} & CanvasMediaAsset;

export type CanvasImageAsset = {
	src: HTMLImageElement;
} & CanvasMediaAsset;

export type CanvasCoverAsset = {
	bgVideo?: CanvasVideoAsset;
	bgImage?: CanvasImageAsset;
	logo?: CanvasImageAsset;
	imageElementsMap?: Map<GigaUserApi.Id, CanvasImageAsset>;
};

export type CanvasScreenclip = CanvasVideoAsset & {
	renderWidth: number;
	renderHeight: number;
	imageElementsMap: Map<GigaUserApi.Id, CanvasImageAsset>;
};

export abstract class CanvasAssets {
	/**
	 * This class is used to manage
	 */

	videoEdits: VideoEdits;

	abstract intro: CanvasCoverAsset;
	abstract outro: CanvasCoverAsset;
	abstract background: CanvasImageAsset;
	abstract screenclip: CanvasScreenclip;
	abstract sources: CanvasVideoAsset[];

	abstract createCanvas(width: number, height: number): HTMLCanvasElement;

	constructor(videoEdits: VideoEdits) {
		this.videoEdits = videoEdits;
	}

	// abstract initBackground(): CanvasImageAsset;

	// abstract initCover(coverType: CoverType, ...args: any[]): CanvasCoverAsset;

	// abstract initScreenclip(...args: any[]): CanvasVideoAsset;

	// abstract initSources(...args: any[]): CanvasVideoAsset[];

	abstract getFont(family?: string): string;
}
