import { GigaUserApi } from "@giga-user-fern/api";
import { Cover } from "@giga-user-fern/api/types/api";
import { printText } from "./canvasText";
import {
	fractionalCoordsToCanvasCoordsUtil,
	getScaleFactor,
} from "./canvasUtils";
import { drawCoverElements } from "./elements/canvasElement";
import { CanvasImageAsset } from "./immutables/assets/CanvasAssets";

type OutroOptions = {
	width: number;
	height: number;
	background: HTMLImageElement | null;
	imageDataMap: Map<GigaUserApi.Id, CanvasImageAsset> | null;
	getFont: (name: string | undefined) => string;
	logo?: HTMLImageElement | null;
};

export const drawOutro = async (
	canvas: HTMLCanvasElement,
	outro: Cover,
	options: OutroOptions,
): Promise<void> => {
	const { width, height, background, imageDataMap, getFont } = options;

	const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

	ctx.clearRect(0, 0, width, height);

	if (outro.background && background) {
		ctx.drawImage(background, 0, 0, width, height);
	}

	let fillStyle = "#4f46e5";

	if (outro.fontColor) {
		fillStyle = outro.fontColor || fillStyle;
	}

	const { f, wf, hf } = getScaleFactor(width, height);

	if (outro.type === "video") {
		//draw a non-migrated outo.

		const printTextAsync = async (
			text: string | undefined,
			textY: number,
			fontSize: number,
		) => {
			// this is used only in the old kind of cover where there is no version.

			if (!text) return;
			const font = getFont(outro.font);
			if (!font || typeof font === "boolean")
				throw new Error("Font not found");

			let weight = 0;
			if (font.split("-").slice(-1)[0] === "regular") {
				weight = 500;
			} else {
				weight = Number.parseInt(font.split("-").slice(-1)[0]);
			}
			if (Number.isNaN(weight)) {
				weight = 600;
			}

			printText(
				ctx,
				text,
				{ width, height },
				{
					color: fillStyle,
					x: "center",
					y: textY,
					maxWidth: 1600 * wf,
					fontSize: fontSize * f,
					fontWeight: weight,
					font: font,
					maxChars: { portrait: 20, landscape: 30 },
				},
			);
		};

		const text1Y = outro.text2 ? 0.5 * height - 74 * hf : 0.5 * height;
		const text2Y = outro.text1 ? 0.5 * height + 74 * hf : 0.5 * height;

		printTextAsync(outro.text1, text1Y, 36);
		printTextAsync(outro.text2, text2Y, 52);
	}

	if (outro.coverEdits && canvas) {
		//draw a migrated outro.

		drawCoverElements({
			allEleEdits: outro.coverEdits,
			canvas: canvas,
			fractionalCoordsToCanvasCoords: fractionalCoordsToCanvasCoordsUtil,
			imageDataMap: imageDataMap ?? new Map(),
			showLogo: outro.showLogo ?? false,
			getFont,
		});
	}
};
