import { cp } from "fs";

const generateSpeechLoadingIcon = (_color?: string) => {
	var color = _color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill={color}
			style={{
				animation: "spin 1s linear infinite",
			}}
		>
			<style>
				{`
					@keyframes spin {
						from { transform: rotate(0deg); }
						to { transform: rotate(360deg); }
					}
				`}
			</style>
			<path
				d="M10 1.875V3.95833M10 15V18.3333M4.79167 10H1.875M17.7083 10H16.4583M15.3809 15.3809L14.7917 14.7917M15.5535 4.51315L14.375 5.69167M4.10131 15.8987L6.45833 13.5417M4.2739 4.34057L6.04167 6.10833"
				stroke={color}
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default generateSpeechLoadingIcon;
