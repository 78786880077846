import { boolean } from "@giga-user-fern/api/types/core/schemas";
import React, { MutableRefObject, useRef } from "react";
import { dummyVideoEdits } from "./videoEditTypes/core";
import { Organization, VideoClip } from "../types/guide";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { VideoSource } from "@giga-user-fern/api/types/api/resources/guides";
import { FrontendVideoSource } from "./CanvasProvider";
import { GigaUserApi } from "@giga-user-fern/api";
import cr, { CanvasRenderer } from "./canvasUtils/immutables/CanvasRenderer";
import { ZoomRender } from "./canvasUtils/immutables/framer/CanvasFramer";
import { AspectRatio, Background, Cover } from "@giga-user-fern/api/types/api";

export type CustomizableFunction = "play" | "pause";

export type HardsetCurrentTimeOptions = {
	waitTillSeek?: boolean;
	skipSettingActiveClipIndex?: boolean;
};

type CanvasPlayerContextValue = {
	//controller
	getRenderer: () => CanvasRenderer;
	initRenderer: (reset?: boolean) => CanvasRenderer;

	//edits
	videoEdits: VideoEdits;
	videoSrc: string;

	vidRef: React.MutableRefObject<HTMLVideoElement | null> | null;
	canvasRef: React.MutableRefObject<HTMLCanvasElement | null> | null;
	organization: Organization | null;

	clips?: VideoClip[];
	sources?: FrontendVideoSource[];

	//state variables
	loading: boolean;
	currentTime: number; //Does not include video time.
	currentTimeRef: React.MutableRefObject<number> | null;

	systemTimeAtPlayRef: React.MutableRefObject<number> | null; // System timestamp (Date.now()) when play was pressed
	videoTimeAtPlayRef: React.MutableRefObject<number> | null; // Video timestamp position when play was pressed

	activeClipIndex: number;
	activeClipIndexRef: React.MutableRefObject<number> | null;
	videoDuration: number;
	paused: boolean;
	pausedRef: MutableRefObject<boolean> | null;
	fullscreen: boolean;
	_hardUpdateTimelinePending: boolean;
	_timelineInitPending: boolean;

	//video controller functions
	setCurrentTime: (time: number) => void;
	hardsetCurrentTime: (
		time: number,
		hard?: boolean,
		options?: HardsetCurrentTimeOptions,
	) => void;
	play: () => void;
	pause: () => void;
	toggleFullscreen: () => void;
	_setHardUpdateTimelinePending: (b: boolean) => void;
	_setTimelineInitPending: (b: boolean) => void;
	setPlaybackRate: (rate: number) => void;
	setClips: (clips: VideoClip[]) => void;
	setSources: (sources: VideoSource[]) => void;
	addNewSource: (id: string, presignedGet: string) => FrontendVideoSource;
	setActiveClipIndex: (index: number) => void;

	//customizers
	// appendCallback: (func: CustomizableFunction, callbackFunction: () => void) => void
	getCurrentZoom: () => ZoomRender | null;

	goToIntro: () => void;
	goToOutro: () => void;

	getCurrentAspectRatio: () => AspectRatio | null;
	getCurrentTime: () => number;
};

const CanvasPlayerContext = React.createContext<CanvasPlayerContextValue>({
	//renderer
	getRenderer: () => cr,
	initRenderer: (reset = true) => cr,

	//edits
	videoEdits: dummyVideoEdits,
	videoSrc: "",
	vidRef: null,
	canvasRef: null,
	organization: null,

	// introVidRef: null,
	// outroVidRef: null,
	// introImgRef: null,
	// outroImgRef: null,
	// introLogoRef: null,
	// outroLogoRef: null,

	//state variables
	loading: true,
	currentTime: 0, //Does not include video time.
	currentTimeRef: null,
	systemTimeAtPlayRef: null, // System timestamp (Date.now()) when play was pressed
	videoTimeAtPlayRef: null, // Video timestamp position when play was pressed

	activeClipIndexRef: null,
	activeClipIndex: 0,
	videoDuration: 0,
	paused: true,
	pausedRef: null,
	fullscreen: false,
	_hardUpdateTimelinePending: false,
	_timelineInitPending: false,

	//video controller functions
	setCurrentTime: (time: number) => {},
	hardsetCurrentTime: (
		time: number,
		hard?: boolean,
		options?: HardsetCurrentTimeOptions,
	) => {},
	play: () => {},
	pause: () => {},
	toggleFullscreen: () => {},
	_setHardUpdateTimelinePending: (b: boolean) => {},
	_setTimelineInitPending: (b: boolean) => {},
	setPlaybackRate: (r: number) => {},
	setClips: (clips: VideoClip[]) => {},
	setSources: (sources: VideoSource[]) => {},
	addNewSource: (id: string, presignedGet: string) => {
		return {
			id: GigaUserApi.Id("d"),
			loaded: false,
			ref: React.createRef<HTMLVideoElement | null>(),
			src: "",
		};
	},
	setActiveClipIndex: (index: number) => {},

	// appendCallback: (func, callbackFunction) => {}
	getCurrentZoom: () => null,

	goToIntro: () => {},
	goToOutro: () => {},

	getCurrentAspectRatio: () => null,
	getCurrentTime: () => 0,
});

export default CanvasPlayerContext;
