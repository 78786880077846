import React, { useEffect, useRef } from "react";
import "./SizeController.css";
import { Shape, UpdateShapeFunction } from "../../ScreenshotEditorContext";
import { Coordinates } from "@giga-user-fern/api/types/api";
import { MutableElement } from "../../../../../../../core/canvas/canvasUtils/mutables/elements/MutableElement";

export type SizeControllerPos =
	| "tl"
	| "t"
	| "tr"
	| "r"
	| "br"
	| "b"
	| "bl"
	| "l"
	| "a";

export type SizeControllerThumbProps = {
	shape: Shape;
	position: SizeControllerPos;

	resizeControls?: boolean;
	zoomFactor?: number;
	getRelativeCoords: (pos: Coordinates) => Coordinates; //pixelsToFracCoords
	getAbsoluteCoords: (pos: Coordinates) => Coordinates; //fracToPixelsCoords
	updateShape: UpdateShapeFunction;

	boundLimits?: boolean;
	lockRatio?: boolean;
};

const SizeControllerThumb: React.FC<SizeControllerThumbProps> = ({
	zoomFactor = 1,
	...props
}) => {
	const isDragging = useRef<boolean>(false);

	const startX = useRef(0);
	const startY = useRef(0);

	const onMouseDown = (e: React.MouseEvent) => {
		isDragging.current = true;
		startX.current = e.clientX;
		startY.current = e.clientY;

		initListeners();
	};

	const onMouseMove = (e: MouseEvent) => {
		if (!isDragging.current) return;

		const shapePos = new MutableElement(props.shape).getPosition();
		const x_0 = shapePos.x;
		const y_0 = shapePos.y;

		const w_0 = props.shape.size[0];
		let h_0 = props.shape.size[1];

		if (props.shape.geo === "image" && props.shape.imagedata) {
			const { naturalHeight, naturalWidth } = props.shape.imagedata;
			const w_p = props.getAbsoluteCoords({ x: w_0, y: h_0 }).x;
			const h_p = (w_p * naturalHeight) / naturalWidth;
			h_0 = props.getRelativeCoords({ x: w_p, y: h_p }).y;
		}

		const dx = (e.clientX - startX.current) / zoomFactor;
		const dy = (e.clientY - startY.current) / zoomFactor;

		const { x, y } = props.getRelativeCoords({ x: dx, y: dy });

		const MIN_DIM_H = props.shape.geo === "arrow" ? -100000 : 0.01;
		const MIN_DIM_W = props.shape.geo === "arrow" ? -100000 : 0.01;

		let new_x = x_0;
		let new_y = y_0;
		let new_w = w_0;
		let new_h = h_0;

		switch (props.position) {
			case "t":
				new_y = y_0 + y;
				new_h = h_0 - y;
				new_w = w_0;

				if (props.boundLimits) {
					if (new_y < 0) {
						new_y = 0;
						new_h = props.shape.size[1] + y_0;
					}
				}

				break;

			case "tl":
				new_x = x_0 + x;
				new_y = y_0 + y;
				new_w = w_0 - x;
				new_h = h_0 - y;

				if (props.lockRatio) {
					new_h = (new_w * h_0) / w_0;
					new_y = y_0 + h_0 - new_h;
				}

				if (props.boundLimits) {
					if (new_x < 0) {
						new_x = 0;
						new_w = w_0 + x_0;
						if (props.lockRatio) break;
					}

					if (new_y < 0) {
						new_y = 0;
						new_h = h_0 + y_0;
						if (props.lockRatio) break;
					}
				}

				break;

			case "tr":
				new_y = y_0 + y;
				new_w = w_0 + x;
				new_h = h_0 - y;

				if (props.lockRatio) {
					new_h = (new_w * h_0) / w_0;
					new_y = y_0 + h_0 - new_h;
				}

				if (props.boundLimits) {
					if (new_y < 0) {
						new_y = 0;
						new_h = h_0 + y_0;
						if (props.lockRatio) break;
					}
					if (x_0 + new_w > 1) {
						new_w = 1 - x_0;
						if (props.lockRatio) break;
					}
				}

				break;

			case "l":
				new_x = x_0 + x;
				new_w = w_0 - x;

				if (props.boundLimits) {
					if (new_x < 0) {
						new_x = 0;
						new_w = props.shape.size[0] + x_0;
					}
				}

				break;

			case "bl":
				new_x = x_0 + x;
				new_w = w_0 - x;
				new_h = h_0 + y;

				if (props.lockRatio) new_h = (new_w * h_0) / w_0;

				if (new_x < 0) {
					new_x = 0;
					new_w = props.shape.size[0] + x_0;
					if (props.lockRatio) break;
				}

				if (new_h + y_0 > 1) {
					new_h = 1 - y_0;
					if (props.lockRatio) break;
				}

				break;

			case "r":
				new_w = w_0 + x;

				if (new_w + shapePos.x > 1) {
					new_w = 1 - shapePos.x;
				}

				break;

			case "br":
				new_w = w_0 + x;
				new_h = h_0 + y;

				if (props.lockRatio) {
					new_h = (new_w * h_0) / w_0;
				}

				if (new_w + shapePos.x > 1) {
					new_w = 1 - shapePos.x;
					if (props.lockRatio) break;
				}

				if (new_h + shapePos.y > 1) {
					new_h = 1 - shapePos.y;
					if (props.lockRatio) break;
				}

				break;

			case "b":
				new_h = h_0 + y;

				if (new_h + shapePos.y > 1) {
					new_h = 1 - shapePos.y;
				}

				break;
		}

		const finalPos = new MutableElement(props.shape).setPositionFromTopLeft(
			{
				x: new_x,
				y: new_y,
			},
		);
		props.updateShape(props.shape.id, {
			position: [finalPos.x, finalPos.y],
			size: [Math.max(new_w, MIN_DIM_W), Math.max(new_h, MIN_DIM_H)],
		});
	};

	const onMouseUp = () => {
		isDragging.current = false;
		cleanUpListeners();
	};

	const initListeners = () => {
		window.addEventListener("mousemove", onMouseMove);
		window.addEventListener("mouseup", onMouseUp);
	};

	const cleanUpListeners = () => {
		window.removeEventListener("mousemove", onMouseMove);
		window.removeEventListener("mouseup", onMouseUp);
	};

	useEffect(() => {}, []);

	useEffect(() => {
		return cleanUpListeners;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const effectiveZoom = props.resizeControls ? zoomFactor : 1;
	return (
		<>
			<div
				className={`SizeController position-${props.position}`}
				style={{
					border: `${2 / Math.sqrt(effectiveZoom)}px solid white`,
					width: 12 / Math.sqrt(effectiveZoom),
					aspectRatio: 1,
				}}
				onMouseDown={onMouseDown}
			></div>
		</>
	);
};

export default SizeControllerThumb;
