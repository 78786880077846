import { Node } from "@remirror/pm/model";
import {
	useActive,
	useChainedCommands,
	useCommands,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import { globalSaver } from "../../../../../../network/saver";
import { useAppSelector } from "../../../../../../redux";
import { selectGuidePreview } from "../../../../../../redux/slices/guideSlice";
import { GuidePreview } from "../../../../../../core/types/guide";
import useAddScreenshot from "../../AddScreenshot/useAddScreenshot";
import "./ScreenshotDropdown.css";
import Icon from "../../../../../../ui/Icon/Icon";
import imagePlus from "../../../../../../assets/svgs/imagePlus";
import ToolbarButton from "../ToolbarButton/ToolbarButton";
import WhiteHoverTip from "../../../../../../ui/WhiteHoverTip/WhiteHoverTip";

type ScreenshotDropdownProps = {
	disabled?: boolean;
};

const ScreenshotDropdown: React.FC<ScreenshotDropdownProps> = (props) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { commands, manager } = useRemirrorContext();
	const guidePreview = useAppSelector(selectGuidePreview) as GuidePreview;
	const originalVideo = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.originalSrc,
	) as string;
	const addScreenshot = useAddScreenshot("textEditor");
	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (!file) return;

		// Validate file type
		if (!file.type.startsWith("image/")) {
			alert("Please select an image file");
			return;
		}

		// Validate file size (e.g., 5MB limit)
		const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
		if (file.size > MAX_FILE_SIZE) {
			alert("File size must be less than 10MB");
			return;
		}

		// Get format of file (with additional safety)
		const format = file.name.split(".").pop()?.toLowerCase() || "unknown";
		const allowedFormats = ["jpg", "jpeg", "png", "gif", "webp"];
		if (!allowedFormats.includes(format)) {
			alert(
				"Please upload a valid image format (JPG, PNG, GIF, or WebP)",
			);
			return;
		}

		const reader = new FileReader();
		reader.onload = async () => {
			try {
				const uri = reader.result as string;
				const resp =
					await globalSaver.selectedRegionalSaver.uploadImage(
						guidePreview.id,
						uri,
					);
				const node: Node = manager.schema.nodes.image.create({
					src: uri,
					metadata: {
						stepId: Date.now().toString(),
						s3ObjectId: resp,
						format: format,
					},
				});
				commands.insertNode(node);
			} catch (error) {
				console.error("Error uploading image:", error);
				alert("Failed to upload image. Please try again.");
			}
		};

		reader.onerror = () => {
			alert("Error reading file. Please try again.");
		};

		reader.readAsDataURL(file);
	};

	const items = [];

	if (originalVideo) {
		items.push({
			active: () => false,
			handler: () => {
				addScreenshot();
			},
			source: (
				<span className="ToolbarButton-dropdown-text-item">
					Choose from video
				</span>
			),
		});
	}

	items.push({
		active: () => false,
		handler: () => {
			fileInputRef.current?.click();
		},
		source: (
			<>
				<span className="ToolbarButton-dropdown-text-item">
					Upload from computer
				</span>
				<input
					type="file"
					accept=".jpg,.jpeg,.png,.webp"
					ref={fileInputRef}
					style={{ display: "none" }}
					onChange={handleFileUpload}
				/>
			</>
		),
	});

	return (
		<div className="ScreenshotDropdown">
			<ToolbarButton
				active={() => false}
				disabled={props.disabled}
				handler={() => {}}
				source={imagePlus("white")}
				newHoverTip={<WhiteHoverTip text="Insert Image" />}
				dropdownType="verticle"
				dropdownMenu={items.map((x) => {
					return {
						active: () => false,
						handler: x.handler,
						source: x.source,
					};
				})}
			/>
		</div>
	);
};

export default ScreenshotDropdown;
