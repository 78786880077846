import React, { useContext, useRef } from "react";
import { Shape, UpdateShapeFunction } from "../../ScreenshotEditorContext";
import { Coordinates, ElementEdit } from "@giga-user-fern/api/types/api";
import CanvasPlayerContext from "../../../../../../../core/canvas/CanvasPlayerContext";
import { MutableElement } from "../../../../../../../core/canvas/canvasUtils/mutables/elements/MutableElement";
import {
	fractionalCoordsToPixels,
	pixelsToFractionalCoords,
} from "../../../../../../../utils/cropUtils";
import { DEFAULT_CROP } from "../../../../../../../core/canvas/videoEditTypes/core";

type DragControllerProps = {
	shape: Shape;
	getRelativeCoords: (pos: Coordinates) => Coordinates; //pixelsToFracCoords
	getAbsoluteCoords: (pos: Coordinates) => Coordinates; //fracToPixelsCoords

	zoomFactor?: number;
	updateShape: UpdateShapeFunction;

	boundLimits?: boolean;
	className?: string;

	toggleCenterGuideLines?: (newValue: {
		vertical: boolean;
		horizontal: boolean;
	}) => void;
	toggleVerticalElementGuideLines?: (newValue: {
		baseCoord: number;
		startCoord: number;
		endCoord: number;
	}) => void;
	toggleHorizontalElementGuideLines?: (newValue: {
		baseCoord: number;
		startCoord: number;
		endCoord: number;
	}) => void;
	elements?: Shape[];
};

// Drag Controller always uses the top left corner of the element as the origin

const DragController: React.FC<DragControllerProps> = ({
	zoomFactor = 1,
	elements = [],
	toggleCenterGuideLines: toggleGuideLines = null,
	...props
}) => {
	const startX = useRef(0);
	const startY = useRef(0);
	const canvasRef = useContext(CanvasPlayerContext).canvasRef;

	const isDraggable = React.useRef(false);

	const onMouseDown = (e: React.MouseEvent) => {
		startX.current = e.clientX;
		startY.current = e.clientY;
		isDraggable.current = false;
		initDragListeners();
	};

	const initDragListeners = () => {
		window.addEventListener("mousemove", onMouseMove);
		window.addEventListener("mouseup", onMouseUp);
	};

	const getHeight = (shape: Shape) => {
		let h = shape.size[1];
		const w = shape.size[0];
		if (props.shape.geo === "image" && props.shape.imagedata && canvasRef) {
			const { naturalHeight, naturalWidth, crop } = props.shape.imagedata;
			const castCrop = crop || DEFAULT_CROP;
			const cropWidthFrac = castCrop.size[0];
			const cropHeightFrac = castCrop.size[1];
			const effectiveWidth = cropWidthFrac * naturalWidth;
			const effectiveHeight = cropHeightFrac * naturalHeight;
			const w_p = fractionalCoordsToPixels({ x: w, y: h }, canvasRef).x;
			const h_p = (w_p * effectiveHeight) / effectiveWidth;
			h = pixelsToFractionalCoords({ x: w_p, y: h_p }, canvasRef).y;
		}

		return h;
	};

	const onMouseMove = (e: MouseEvent) => {
		if (!isDraggable) return;

		const mutableElement = new MutableElement(props.shape);
		const pos = mutableElement.getPosition();

		const x_0 = pos.x;
		const y_0 = pos.y;

		const dx = (e.clientX - startX.current) / zoomFactor;
		const dy = (e.clientY - startY.current) / zoomFactor;

		const { x, y } = props.getRelativeCoords({ x: dx, y: dy });
		let new_x = x_0 + x;
		let new_y = y_0 + y;

		const w = props.shape.size[0];
		const h = getHeight(props.shape);

		if (props.boundLimits) {
			new_x = Math.max(new_x, 0);
			new_x = Math.min(new_x, 1 - w);

			new_y = Math.max(new_y, 0);
			new_y = Math.min(new_y, 1 - h);
		}

		let pos_x = new_x;
		let pos_y = new_y;

		const result = setElementGuideLines(elements, props.shape, [
			pos_x,
			pos_y,
		]);

		pos_x = result.pos[0];
		pos_y = result.pos[1];

		if (props.toggleHorizontalElementGuideLines) {
			props.toggleHorizontalElementGuideLines(result.horizontalLines);
		}

		if (props.toggleVerticalElementGuideLines) {
			props.toggleVerticalElementGuideLines(result.verticalLines);
		}

		const final_pos = mutableElement.setPositionFromTopLeft({
			x: pos_x,
			y: pos_y,
		});

		props.updateShape(props.shape.id, {
			position: [final_pos.x, final_pos.y],
		});
	};

	const onMouseUp = (e: MouseEvent) => {
		isDraggable.current = false;
		if (toggleGuideLines) {
			toggleGuideLines({ vertical: false, horizontal: false });
		}
		if (props.toggleVerticalElementGuideLines) {
			props.toggleVerticalElementGuideLines({
				baseCoord: 0,
				startCoord: 0,
				endCoord: 1,
			});
		}
		if (props.toggleHorizontalElementGuideLines) {
			props.toggleHorizontalElementGuideLines({
				baseCoord: 0,
				startCoord: 0,
				endCoord: 1,
			});
		}
		cleanupDragListeners();
	};

	const cleanupDragListeners = () => {
		window.removeEventListener("mousemove", onMouseMove);
		window.removeEventListener("mouseup", onMouseUp);
	};

	const setElementGuideLines = (
		elements: Shape[],
		shape: Shape,
		pos: number[],
	) => {
		let pos_x = pos[0];
		let pos_y = pos[1];
		const size_x = shape.size[0];
		const size_y = getHeight(shape);

		let snapVerticalDirection: string | null = null;
		let snapVerticalDistance: number | null = null;
		let snapHorizontalDirection: string | null = null;
		let snapHorizontalDistance: number | null = null;
		let verticalLineCoords = {
			baseCoord: 0,
			startCoord: 0,
			endCoord: 0,
		};
		let horizontalLineCoords = {
			baseCoord: 0,
			startCoord: 0,
			endCoord: 0,
		};

		for (const ele of elements ?? []) {
			if (ele.id === shape.id) continue;
			const element = { ...ele, size: [ele.size[0], getHeight(ele)] };

			const pos = new MutableElement(element).getPosition();
			const elementTopLeft = [pos.x, pos.y];
			const elementBottomRight = [
				pos.x + element.size[0],
				pos.y + element.size[1],
			];
			const elementTopRight = [elementBottomRight[0], elementTopLeft[1]];
			const elementBottomLeft = [
				elementTopLeft[0],
				elementBottomRight[1],
			];
			const elementCenter = [
				element.position[0] + element.size[0] / 2,
				element.position[1] + element.size[1] / 2,
			];

			const activeTopLeft = [pos_x, pos_y];
			const activeBottomRight = [pos_x + size_x, pos_y + size_y];
			const activeTopRight = [activeBottomRight[0], activeTopLeft[1]];
			const activeBottomLeft = [activeTopLeft[0], activeBottomRight[1]];
			const activeCenter = [pos_x + size_x / 2, pos_y + size_y / 2];

			const activePoints = [
				activeTopLeft,
				activeBottomRight,
				activeTopRight,
				activeBottomLeft,
				activeCenter,
			];
			const elementPoints = [
				elementTopLeft,
				elementBottomRight,
				elementTopRight,
				elementBottomLeft,
				elementCenter,
			];

			// compare each point of active element to every point of element in vertical direction only and find the closest one
			for (let i = 0; i < activePoints.length; i++) {
				for (let j = 0; j < elementPoints.length; j++) {
					const distance = activePoints[i][1] - elementPoints[j][1];
					if (
						snapVerticalDistance === null ||
						Math.abs(distance) < snapVerticalDistance
					) {
						snapVerticalDistance = Math.abs(distance);
						horizontalLineCoords = {
							baseCoord: elementPoints[j][1],
							startCoord: Math.min(element.position[0], pos_x),
							endCoord: 1 - Math.max(element.position[0], pos_x),
						};
						if (distance < 0) {
							snapVerticalDirection = "down";
						} else {
							snapVerticalDirection = "up";
						}
					}
				}
			}

			for (let i = 0; i < activePoints.length; i++) {
				for (let j = 0; j < elementPoints.length; j++) {
					const distance = activePoints[i][0] - elementPoints[j][0];
					const absDistance = Math.abs(distance);
					if (
						snapHorizontalDistance === null ||
						absDistance < snapHorizontalDistance
					) {
						snapHorizontalDistance = absDistance;
						verticalLineCoords = {
							baseCoord: elementPoints[j][0],
							startCoord: Math.min(element.position[1], pos_y),
							endCoord: 1 - Math.max(element.position[1], pos_y),
						};
						if (distance < 0) {
							snapHorizontalDirection = "right";
						} else {
							snapHorizontalDirection = "left";
						}
					}
				}
			}
		}

		const distanceToCenter = {
			x: 0.5 - size_x / 2 - pos_x,
			y: 0.5 - size_y / 2 - pos_y,
		};

		if (Math.abs(distanceToCenter.x) < 0.015) {
			pos_x = 0.5 - size_x / 2;
			verticalLineCoords = {
				baseCoord: 0,
				startCoord: 0,
				endCoord: 1,
			};
		} else {
			if (snapHorizontalDistance && snapHorizontalDistance < 0.015) {
				if (snapHorizontalDirection === "left") {
					pos_x = pos_x - snapHorizontalDistance;
				} else {
					pos_x = pos_x + snapHorizontalDistance;
				}
			} else {
				verticalLineCoords = {
					baseCoord: 0,
					startCoord: 0,
					endCoord: 1,
				};
			}
		}

		if (Math.abs(distanceToCenter.y) < 0.015) {
			pos_y = 0.5 - size_y / 2;
			horizontalLineCoords = {
				baseCoord: 0,
				startCoord: 0,
				endCoord: 1,
			};
		} else {
			if (snapVerticalDistance && snapVerticalDistance < 0.015) {
				if (snapVerticalDirection === "up") {
					pos_y = pos_y - snapVerticalDistance;
				} else {
					pos_y = pos_y + snapVerticalDistance;
				}
			} else {
				horizontalLineCoords = {
					baseCoord: 0,
					startCoord: 0,
					endCoord: 1,
				};
			}
		}

		return {
			pos: [pos_x, pos_y],
			size: [size_x, size_y],
			verticalLines: verticalLineCoords,
			horizontalLines: horizontalLineCoords,
		};
	};

	return (
		<div
			className={`DragController ${props.className} ${props.shape.geo}`}
			onMouseDown={onMouseDown}
		></div>
	);
};

export default DragController;
