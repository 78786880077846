import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	GuideData,
	GuidePreview,
	PlainDoc,
	Voice,
	GuidePlatformStatus,
} from "../../core/types/guide";
import { Guide, VideoClip } from "../../core/types/guide";
import { RootState } from "../store";

import {
	ExportedVideo,
	GuideAiChatMessage,
	OriginalEdits,
	PromptOption,
	VoiceEdits,
} from "@giga-user-fern/api/types/api/resources/guides";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video/resources/videoEdits/types/VideoEdits";
import {
	ZoomEdit,
	SubtitlesSettings,
} from "@giga-user-fern/api/types/api/resources/video/resources/videoEdits/types";
import { AspectRatio, ElementEdit } from "@giga-user-fern/api/types/api";
import {
	defaultBackground,
	defaultIntroCover,
	defaultOutroCover,
} from "../../core/canvas/videoEditTypes/cover";
import { GigaUserApi } from "@giga-user-fern/api";
import { Background, Cover, Id } from "@giga-user-fern/api/types/api";

interface Edits {
	unsavedChanges: number | null; //for all changes, including transcript
	transcriptEdited: boolean; //for only transcript changes
}

interface VideoEditsHistory {
	videoEdits: VideoEdits[];
	currentIndex: number;
}

interface GuideState {
	value: {
		guide?: Guide;
		edits: Edits;
		status?: GuidePlatformStatus;
		history: VideoEditsHistory;
		articleAiChatMessages: GuideAiChatMessage[] | [];
		videoAiChatMessages: GuideAiChatMessage[] | [];
		articleSelectedPromptData: PromptOption | null;
		videoSelectedPromptData: PromptOption | null;
	};
}

const initialState: GuideState = {
	value: {
		guide: undefined,
		edits: {
			unsavedChanges: null,
			transcriptEdited: false,
		},
		status: undefined,
		history: {
			videoEdits: [],
			currentIndex: 1,
		},
		articleAiChatMessages: [],
		videoAiChatMessages: [],
		articleSelectedPromptData: null,
		videoSelectedPromptData: null,
	},
};

export const defaultVoice: Voice = {
	engine: "eleven",
	code: "EXAVITQu4vr4xnSDxMaL",
	language: "en-US",
};

export const defaultVoiceEdits: VoiceEdits = {
	voice: defaultVoice,
	speed: 1,
};

export const guideSlice = createSlice({
	name: "guide",
	initialState,
	reducers: {
		setGuideLoading: (state) => {
			state.value.guide = undefined;
		},

		setGuide: (state, action: PayloadAction<Guide>) => {
			const guide = action.payload;
			state.value.guide = guide;
		},

		setArticleSelectedPromptData: (
			state,
			action: PayloadAction<PromptOption>,
		) => {
			state.value.articleSelectedPromptData = action.payload;
		},

		setVideoSelectedPromptData: (
			state,
			action: PayloadAction<PromptOption>,
		) => {
			state.value.videoSelectedPromptData = action.payload;
		},

		setArticleAiChatMessages: (
			state,
			action: PayloadAction<GuideAiChatMessage[]>,
		) => {
			state.value.articleAiChatMessages = action.payload;
		},

		setVideoAiChatMessages: (
			state,
			action: PayloadAction<GuideAiChatMessage[]>,
		) => {
			state.value.videoAiChatMessages = action.payload;
		},

		setGuidePreview: (state, action: PayloadAction<GuidePreview>) => {
			if (state.value.guide) {
				state.value.guide.guidePreview = action.payload;
			}
		},

		setGuideData: (state, action: PayloadAction<GuideData>) => {
			if (state.value.guide) {
				state.value.guide.guideData = action.payload;
			}
		},

		setGeneratedSrc: (state, action: PayloadAction<string>) => {
			const newSrc = action.payload;
			if (state.value.guide) {
				state.value.guide.guideData.video.generatedSrc = newSrc;
			}
		},

		setOriginalVideoSrc: (state, action: PayloadAction<string>) => {
			if (state.value.guide) {
				state.value.guide.guideData.video.originalSrc = action.payload;
			}
		},

		setVideoTranscript: (state, action: PayloadAction<PlainDoc>) => {
			const newDoc = action.payload;
			if (state.value.guide?.guideData.video.generated) {
				state.value.guide.guideData.video.generated.transcript = newDoc;
			}
		},
		setPlainDoc: (state, action: PayloadAction<PlainDoc>) => {
			const newDoc = action.payload;
			if (state.value.guide?.guideData) {
				state.value.guide.guideData.plainDoc = newDoc;
				state.value.edits.unsavedChanges = Date.now();
			}
		},
		setTitle: (state, action: PayloadAction<string>) => {
			if (state.value.guide) {
				state.value.guide.guidePreview.header.name = action.payload;
			}
		},

		setDescription: (state, action: PayloadAction<string>) => {
			//@ts-expect-error
			state.value.guide.guidePreview.header.description = action.payload;
		},
		setDecoratedAiVideoExists: (state, action: PayloadAction<boolean>) => {
			//@ts-expect-error
			state.value.guide.guidePreview.decoratedAiVideoExists =
				action.payload;
		},

		setEditedVideoExists: (state, action: PayloadAction<boolean>) => {
			//@ts-expect-error
			state.value.guide.guidePreview.editedVideoExists = action.payload;
		},
		setLoadingGeneratedVideo: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.generatingVoiceover = action.payload;
			}
		},
		setPublishingGuide: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.publishingGuide = action.payload;
			}
		},

		setGeneralSettings: (state, action: PayloadAction<GuidePreview>) => {
			// const newGuidePreview = action.payload
			// if(state.value.guide){
			//     state.value.guide.guidePreview.header.published = newGuidePreview.header.published
			//     state.value.guide.guidePreview.hideArticle =  newGuidePreview.hideArticle
			//     state.value.guide.guidePreview.publishedVideo = newGuidePreview.publishedVideo
			// }
		},

		setGuidePlatformStatus: (
			state,
			action: PayloadAction<GuidePlatformStatus>,
		) => {
			state.value.status = action.payload;
		},

		setVideoEdits: (state, action: PayloadAction<VideoEdits>) => {
			if (state.value.guide) {
				state.value.guide.guideData.video.videoEdits = action.payload;
			}
		},

		addVideoEditHistory: (state, action: PayloadAction<VideoEdits>) => {
			if (state.value.history.currentIndex > 1) {
				state.value.history.videoEdits =
					state.value.history.videoEdits.slice(
						0,
						state.value.history.videoEdits.length -
							state.value.history.currentIndex +
							1,
					);
			}
			state.value.history.currentIndex = 1;
			if (state.value.history.videoEdits.length > 30) {
				state.value.history.videoEdits =
					state.value.history.videoEdits.slice(
						0,
						state.value.history.videoEdits.length - 30,
					);
			}
			state.value.history.videoEdits.push(action.payload);
		},

		undoVideoEdits: (state) => {
			if (!state.value.guide) return;
			if (
				state.value.history.currentIndex <
				state.value.history.videoEdits.length
			) {
				state.value.history.currentIndex++;
				state.value.guide.guideData.video.videoEdits =
					state.value.history.videoEdits[
						state.value.history.videoEdits.length -
							state.value.history.currentIndex
					];
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		redoVideoEdits: (state) => {
			if (!state.value.guide) return;
			if (state.value.history.currentIndex > 1) {
				state.value.history.currentIndex--;
				state.value.guide.guideData.video.videoEdits =
					state.value.history.videoEdits[
						state.value.history.videoEdits.length -
							state.value.history.currentIndex
					];
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		setOriginalTimestamps: (state, action: PayloadAction<number[]>) => {
			if (state.value.guide?.guideData.video.generated) {
				state.value.guide.guideData.video.generated.originalTimestamps =
					action.payload;
			}
		},

		setGeneratedTimestamps: (state, action: PayloadAction<number[]>) => {
			if (state.value.guide?.guideData.video.generated) {
				state.value.guide.guideData.video.generated.generatedTimestamps =
					action.payload;
			}
		},

		setSubtitles: (state, action: PayloadAction<string>) => {
			if (state.value.guide?.guideData.video.generated) {
				state.value.guide.guideData.video.generated.subtitles =
					action.payload;
			}
		},

		updateIntroEdits: (state, action: PayloadAction<Cover>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.intro =
					action.payload;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		deleteIntroEdit: (state, action: PayloadAction<GigaUserApi.Id>) => {
			if (state.value.guide?.guideData.video.videoEdits?.intro) {
				const elements =
					state.value.guide.guideData.video.videoEdits.intro
						.coverEdits || [];
				const updatedElements = elements.filter((ele) => {
					return ele.id !== action.payload;
				});
				state.value.guide.guideData.video.videoEdits.intro.coverEdits =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		addIntroElement: (state, action: PayloadAction<ElementEdit>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				if (state.value.guide?.guideData.video.videoEdits.intro) {
					state.value.guide.guideData.video.videoEdits.intro.coverEdits =
						[
							...(state.value.guide.guideData.video.videoEdits
								.intro.coverEdits || []),
							action.payload,
						];
					state.value.edits.unsavedChanges = Date.now();
				}
			}
		},

		updateIntroElement: (state, action: PayloadAction<ElementEdit>) => {
			const updatedElement = action.payload;

			if (state.value.guide?.guideData.video.videoEdits) {
				if (!state.value.guide.guideData.video.videoEdits.intro) {
					return;
				}

				const elements =
					state.value.guide.guideData.video.videoEdits.intro
						.coverEdits || [];
				const updatedElements = elements.map((element) => {
					if (element.id === updatedElement.id) {
						return updatedElement;
					}
					return element;
				});

				state.value.guide.guideData.video.videoEdits.intro.coverEdits =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		updateBackgroundEdits: (state, action: PayloadAction<Background>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.background =
					action.payload;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		updateOutroEdits: (state, action: PayloadAction<Cover>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.outro =
					action.payload;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		addOutroElement: (state, action: PayloadAction<ElementEdit>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				if (state.value.guide?.guideData.video.videoEdits.outro) {
					state.value.guide.guideData.video.videoEdits.outro.coverEdits =
						[
							...(state.value.guide.guideData.video.videoEdits
								.outro.coverEdits || []),
							action.payload,
						];
					state.value.edits.unsavedChanges = Date.now();
				}
			}
		},

		deleteOutroEdit: (state, action: PayloadAction<GigaUserApi.Id>) => {
			if (state.value.guide?.guideData.video.videoEdits?.outro) {
				const elements =
					state.value.guide.guideData.video.videoEdits.outro
						.coverEdits || [];
				const updatedElements = elements.filter((ele) => {
					return ele.id !== action.payload;
				});
				state.value.guide.guideData.video.videoEdits.outro.coverEdits =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		updateOutroElement: (state, action: PayloadAction<ElementEdit>) => {
			const updatedElement = action.payload;

			if (state.value.guide?.guideData.video.videoEdits) {
				if (!state.value.guide.guideData.video.videoEdits.outro) {
					return;
				}

				const elements =
					state.value.guide.guideData.video.videoEdits.outro
						.coverEdits || [];
				const updatedElements = elements.map((element) => {
					if (element.id === updatedElement.id) {
						return updatedElement;
					}
					return element;
				});

				state.value.guide.guideData.video.videoEdits.outro.coverEdits =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		setVoice: (state, action: PayloadAction<VoiceEdits>) => {
			if (state.value.guide?.guideData.video.videoEdits?.voice) {
				state.value.guide.guideData.video.videoEdits.voice =
					action.payload;
			}
		},

		setCurrentVersions: (
			state,
			action: PayloadAction<{
				published?: number;
				saved?: number;
			}>,
		) => {
			const { saved, published } = action.payload;
			if (state.value.guide?.guidePreview) {
				if (saved) state.value.guide.guidePreview.versionSaved = saved;
				if (published)
					state.value.guide.guidePreview.versionPublished = published;
			}
		},

		setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
			if (action.payload === false) {
				// If we want to set unsaved changes to false, set transcript edited also to false.
				state.value.edits.transcriptEdited = false;
				state.value.edits.unsavedChanges = null;
			} else {
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		setTranscriptEdited: (state, action: PayloadAction<boolean>) => {
			state.value.edits.transcriptEdited = action.payload;
			if (action.payload === true) {
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		setExportingVideo: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.exportingVideo = action.payload;
				state.value.status.exportDetails = undefined;
			}
		},

		setPublishingYoutube: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.publishingYoutube = action.payload;
			}
		},

		setAiEnhancingArticle: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.aiEnhancingArticle = action.payload;
			}
		},

		setAiEnhancingVideo: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.aiEnhancingVideo = action.payload;
			}
		},

		setTrimmingVideo: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.trimmingVideo = action.payload;
			}
		},

		setExport: (state, action: PayloadAction<ExportedVideo>) => {
			if (state.value.guide) {
				state.value.guide.guideData.video.export = action.payload;
			}
		},

		setAutoUpdatingGuide: (state, action: PayloadAction<boolean>) => {
			if (state.value.status) {
				state.value.status.autoUpdatingGuide = action.payload;
			}
		},

		/**
		 * CROP
		 */
		setCrop: (
			state,
			action: PayloadAction<{
				size: number[];
				position: number[];
			}>,
		) => {
			const { size, position } = action.payload;

			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.crop = {
					size: size,
					position: position,
				};
			}

			state.value.edits.unsavedChanges = Date.now();
		},

		/**
		 * ASPECT RATIO
		 */
		setAspectRatio: (
			state,
			action: PayloadAction<{
				aspectRatio: AspectRatio;
			}>,
		) => {
			const { aspectRatio } = action.payload;

			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.aspectRatio =
					aspectRatio;
			}

			state.value.edits.unsavedChanges = Date.now();
		},

		/**
		 * ZOOMS
		 */

		addZoom: (state, action: PayloadAction<ZoomEdit>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.zooms = [
					...(state.value.guide.guideData.video.videoEdits.zooms ||
						[]),
					action.payload,
				];
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		deleteZoom: (state, action: PayloadAction<ZoomEdit>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				const zooms =
					state.value.guide.guideData.video.videoEdits.zooms || [];
				const updatedZooms = zooms.filter((zoom) => {
					return zoom.id !== action.payload.id;
				});
				state.value.guide.guideData.video.videoEdits.zooms =
					updatedZooms;
				state.value.edits.unsavedChanges = Date.now();
			}
		},
		deleteAllZooms: (state) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.zooms = [];
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		updateZoom: (state, action: PayloadAction<ZoomEdit>) => {
			const updatedZoom = action.payload;

			if (state.value.guide?.guideData.video.videoEdits) {
				const zooms =
					state.value.guide.guideData.video.videoEdits.zooms || [];
				const updatedZooms = zooms.map((zoom) => {
					if (zoom.id === updatedZoom.id) {
						return updatedZoom;
					}
					return zoom;
				});

				state.value.guide.guideData.video.videoEdits.zooms =
					updatedZooms;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		batchUpdateZoom: (
			state,
			action: PayloadAction<{
				updatedZooms: ZoomEdit[];
				deletedZooms: ZoomEdit[];
			}>,
		) => {
			const updatedZooms = action.payload.updatedZooms || [];
			const deletedZooms = action.payload.deletedZooms || [];

			if (state.value.guide?.guideData.video.videoEdits) {
				if (!state.value.guide?.guideData.video.videoEdits.zooms)
					return;

				// const currentElements : ElementEdit[] = {...state.value.guide?.guideData.video.videoEdits.elements}

				const newZooms: ZoomEdit[] =
					state.value.guide?.guideData.video.videoEdits.zooms
						.map((zoom) => {
							//find a matching updated element
							const updatedZoom = updatedZooms.find(
								(updated) => updated.id === zoom.id,
							);
							return updatedZoom || zoom;
						})
						.filter(
							(element) =>
								!deletedZooms.some(
									(deleted) => deleted.id === element.id,
								),
						);

				state.value.guide.guideData.video.videoEdits.zooms = newZooms;
			}

			state.value.edits.unsavedChanges = Date.now();
		},

		/**
		 * ELEMENTS
		 */

		addElement: (state, action: PayloadAction<ElementEdit>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.elements = [
					...(state.value.guide.guideData.video.videoEdits.elements ||
						[]),
					action.payload,
				];
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		updateElement: (state, action: PayloadAction<ElementEdit>) => {
			const updatedElement = action.payload;

			if (state.value.guide?.guideData.video.videoEdits) {
				const elements =
					state.value.guide.guideData.video.videoEdits.elements || [];
				const updatedElements = elements.map((ele) => {
					if (ele.id === updatedElement.id) {
						return updatedElement;
					}
					return ele;
				});

				state.value.guide.guideData.video.videoEdits.elements =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		updateSubtitles: (state, action: PayloadAction<SubtitlesSettings>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				state.value.guide.guideData.video.videoEdits.subtitlesSettings =
					action.payload;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		batchUpdateElement: (
			state,
			action: PayloadAction<{
				updatedElements: ElementEdit[];
				deletedElements?: ElementEdit[];
			}>,
		) => {
			const updatedElements = action.payload.updatedElements || [];
			const deletedElements = action.payload.deletedElements || [];

			if (state.value.guide?.guideData.video.videoEdits) {
				if (!state.value.guide?.guideData.video.videoEdits.elements)
					return;

				// const currentElements : ElementEdit[] = {...state.value.guide?.guideData.video.videoEdits.elements}

				const newElements: ElementEdit[] =
					state.value.guide?.guideData.video.videoEdits.elements
						.map((element) => {
							//find a matching updated element
							const updatedElement = updatedElements.find(
								(updated) => updated.id === element.id,
							);
							return updatedElement || element;
						})
						.filter(
							(element) =>
								!deletedElements.some(
									(deleted) => deleted.id === element.id,
								),
						);

				state.value.guide.guideData.video.videoEdits.elements =
					newElements;
			}

			state.value.edits.unsavedChanges = Date.now();
		},

		deleteElement: (state, action: PayloadAction<GigaUserApi.Id>) => {
			if (state.value.guide?.guideData.video.videoEdits) {
				const elements =
					state.value.guide.guideData.video.videoEdits.elements || [];
				const updatedElements = elements.filter((ele) => {
					return ele.id !== action.payload;
				});
				state.value.guide.guideData.video.videoEdits.elements =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		deleteIntroCoverElement: (
			state,
			action: PayloadAction<GigaUserApi.Id>,
		) => {
			if (
				state.value.guide?.guideData.video.videoEdits?.intro?.coverEdits
			) {
				const elements =
					state.value.guide?.guideData.video.videoEdits?.intro
						?.coverEdits || [];
				const updatedElements = elements.filter((ele) => {
					return ele.id !== action.payload;
				});
				state.value.guide.guideData.video.videoEdits.intro.coverEdits =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		deleteOutroCoverElement: (
			state,
			action: PayloadAction<GigaUserApi.Id>,
		) => {
			if (
				state.value.guide?.guideData.video.videoEdits?.outro?.coverEdits
			) {
				const elements =
					state.value.guide?.guideData.video.videoEdits?.outro
						?.coverEdits || [];
				const updatedElements = elements.filter((ele) => {
					return ele.id !== action.payload;
				});
				state.value.guide.guideData.video.videoEdits.outro.coverEdits =
					updatedElements;
				state.value.edits.unsavedChanges = Date.now();
			}
		},

		/**
		 * ORIGINAL EDITS
		 */

		setOriginalEdits: (state, action: PayloadAction<OriginalEdits>) => {
			if (state.value.guide?.guideData.video) {
				state.value.guide.guideData.video.originalEdits =
					action.payload;
			}
		},

		setClips: (state, action: PayloadAction<VideoClip[]>) => {
			if (state.value.guide?.guideData.video.originalEdits) {
				state.value.guide.guideData.video.originalEdits.clips =
					action.payload;
			}
		},
	},
});

export const {
	batchUpdateElement,
	setCrop,
	setGuide,
	setArticleSelectedPromptData,
	setVideoSelectedPromptData,
	setArticleAiChatMessages,
	setVideoAiChatMessages,
	updateElement,
	deleteElement,
	deleteIntroCoverElement,
	deleteOutroCoverElement,
	setOriginalEdits,
	addZoom,
	deleteZoom,
	updateZoom,
	setGuideData,
	setExport,
	setExportingVideo,
	setTranscriptEdited,
	setClips,
	setDecoratedAiVideoExists,
	setUnsavedChanges,
	setGeneralSettings,
	setGuidePreview,
	setEditedVideoExists,
	setGuideLoading,
	setGeneratedSrc,
	setVideoTranscript,
	setPlainDoc,
	setTitle,
	setDescription,
	setCurrentVersions,
	setLoadingGeneratedVideo,
	setGuidePlatformStatus,
	setAiEnhancingArticle,
	setAiEnhancingVideo,
	setTrimmingVideo,
	setOriginalVideoSrc,
	setPublishingYoutube,
	setAutoUpdatingGuide,
	deleteAllZooms,
	addIntroElement,
	addOutroElement,
	updateIntroElement,
	updateOutroElement,
	deleteIntroEdit,
	deleteOutroEdit,
	addVideoEditHistory,
	undoVideoEdits,
	redoVideoEdits,
	setAspectRatio,
} = guideSlice.actions;
// export const {setGuide, updateElement, deleteElement, setOriginalEdits, addZoom, deleteZoom, updateZoom, setGuideData, setExport, setExportingVideo, setTranscriptEdited, setClips, setDecoratedAiVideoExists, setUnsavedChanges, setGeneralSettings, setGuidePreview, setEditedVideoExists, setGuideLoading, setGeneratedSrc, setVideoTranscript, setPlainDoc, setTitle, setDescription, setCurrentVersions, setLoadingGeneratedVideo} = guideSlice.actions

export const selectArticleSelectedPromptData = (state: RootState) =>
	state.guide.value.articleSelectedPromptData;

export const selectVideoSelectedPromptData = (state: RootState) =>
	state.guide.value.videoSelectedPromptData;

export const selectArticleAiChatMessages = (state: RootState) =>
	state.guide.value.articleAiChatMessages;

export const selectVideoAiChatMessages = (state: RootState) =>
	state.guide.value.videoAiChatMessages;

export const selectVideo = (state: RootState) =>
	state.guide.value.guide?.guideData.video;

export const selectClips = (state: RootState) => {
	// const clips = state.guide.value.guide?.guidePreview.ogVideoClips
	// return clips
	// // if(clips) {
	// //     return clips
	// // }
	// // else{
	// //     logger.debug("no clips")
	// //     return [{startTime: 0, endTime: 0}]
	// // }
};
export const selectGuide = (state: RootState) => state.guide.value.guide;
export const selectGuidePreview = (state: RootState) =>
	state.guide.value.guide?.guidePreview;
export const selectPlainDoc = (state: RootState) =>
	state.guide.value.guide?.guideData.plainDoc;
export const selectVideoTranscript = (state: RootState) =>
	state.guide.value.guide?.guideData.video.generated?.transcript;
export const selectLoadingGeneratedVideo = (state: RootState) =>
	state.guide.value.status?.generatingVoiceover;
export const selectGuideSettings = (state: RootState) =>
	state.guide.value.guide?.guideData.settings;
export const selectPublishingGuide = (state: RootState) =>
	state.guide.value.status?.publishingGuide;
export const selectExportingVideo = (state: RootState) =>
	state.guide.value.status?.exportingVideo;
export const selectOriginalTimestamps = (state: RootState) =>
	state.guide.value.guide?.guideData.video.generated?.originalTimestamps;
export const selectGeneratedTimestamps = (state: RootState) =>
	state.guide.value.guide?.guideData.video.generated?.generatedTimestamps;
export const selectPublishingYoutube = (state: RootState) =>
	state.guide.value.status?.publishingYoutube;
// Other code such as selectors can use the imported `RootState` type

export const {
	batchUpdateZoom,
	addElement,
	setPublishingGuide,
	setVideoEdits,
	updateIntroEdits,
	updateBackgroundEdits,
	updateOutroEdits,
	setVoice,
	setOriginalTimestamps,
	setGeneratedTimestamps,
	setSubtitles,
	updateSubtitles,
} = guideSlice.actions;
export const selectVideoEdits = (state: RootState) =>
	state.guide.value.guide?.guideData.video.videoEdits;

export const selectIntroEdits = (state: RootState) =>
	state.guide.value.guide?.guideData.video.videoEdits?.intro ||
	defaultIntroCover;
export const selectBackgroundEdits = (state: RootState) => {
	return (
		state.guide.value.guide?.guideData.video.videoEdits?.background ||
		defaultBackground
	);
};
export const selectOutroEdits = (state: RootState) =>
	state.guide.value.guide?.guideData.video.videoEdits?.outro ||
	defaultOutroCover;
export const selectVoice = (state: RootState) =>
	state.guide.value.guide?.guideData.video.videoEdits?.voice ||
	defaultVoiceEdits;

export const selectUnsavedChanges = (state: RootState) =>
	state.guide.value.edits.unsavedChanges;
export const selectVideoExport = (state: RootState) =>
	state.guide.value.guide?.guideData.video.export;
export const selectAutoUpdatingGuide = (state: RootState) =>
	state.guide.value.status?.autoUpdatingGuide;
export const selectAiEnhancingArticle = (state: RootState) =>
	state.guide.value.status?.aiEnhancingArticle;
export const selectAiEnhancingVideo = (state: RootState) =>
	state.guide.value.status?.aiEnhancingVideo;
export const selectTrimmingVideo = (state: RootState) =>
	state.guide.value.status?.trimmingVideo;
export const selectCreatingGuide = (state: RootState) =>
	state.guide.value.status?.creatingGuide;
export default guideSlice.reducer;
