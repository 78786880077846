import React, {
	forwardRef,
	PropsWithChildren,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { LinkExtension, PlaceholderExtension } from "remirror/extensions";
import { TableExtension } from "@remirror/extension-react-tables";
import {
	EditorComponent,
	Remirror,
	ThemeProvider,
	useChainedCommands,
	useEditorView,
	useRemirror,
} from "@remirror/react";

import {
	CreateEditorStateProps,
	Extension,
	InvalidContentHandler,
	RemirrorJSON,
} from "remirror";
import type { ReactFrameworkOutput, RemirrorProps } from "@remirror/react";
import "remirror/styles/all.css";
import "./TextEditor.css";
import { getExtensions } from "./utils/getExtensions";
import { OnChangeJSON } from "@remirror/react";
import {
	NavigateToTimeFunction,
	SpecialImageExtension,
} from "./extensions/SpecialImageExtension";
import RichTextToolbar, {
	TranscriptToolbarButton,
	VoiceProps,
} from "./components/RichTextToolbar/RichTextToolbar";
import logger from "../../../utils/logger";
import { Size } from "../../../types/sizes";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { openOverlay } from "../../../layouts/Overlay";
import LinkPopup from "./components/RichTextToolbar/LinkPopup/LinkPopup";
import { Id } from "@giga-user-fern/api/types/api";
import { ViewingLinkExtension } from "./extensions/ViewingLinkExtension";
import useEditScreenshot, {
	ConvertToGifFunction,
} from "./components/EditScreenshot/useEditScreenshot";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { InlineAiExtension } from "./extensions/InlineAIRewriteMark";
import { InlineAIPositioner } from "./components/InlineAiRewrite/InlineAiRewrite";
import { captureEvent } from "../../../core/analytics/analytics";
import { Skeleton } from "../../../ui/skeleton/Skeleton";
import HoverTip from "../../../ui/HoverTip/HoverTip";
import { getFlag } from "../../../utils/voices/voiceUtils";
import { openModal } from "../../../layouts/CModal/modalSlice";

export type TextEditorType =
	| "extension"
	| "platform"
	| "videoTranscript"
	| "helpCenter";

export interface ReactEditorProps
	extends Pick<CreateEditorStateProps, "stringHandler">,
		Pick<
			RemirrorProps,
			"initialContent" | "editable" | "autoFocus" | "hooks"
		> {
	version: string;
	type: TextEditorType;
	placeholder?: string;
	save?: (x: RemirrorJSON) => void;
	onChangePrompt?: () => void;
	onEnhance?: () => void;
	onGenerate?: () => void;
	scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
	fixToolbar?: boolean;
	size?: Size;
	appTheme: "gigauser-light" | "gigauser-dark";
	onOpenGuideFromId?: (x: Id) => void;
	enhanceLoading?: boolean;
	generateLoading?: boolean;
	convertToGif?: ConvertToGifFunction;
	navigateToTime?: NavigateToTimeFunction;
}

export interface TextEditorProps extends Partial<ReactEditorProps> {}
export type RichTextData = any;

//forwardRef is if you want to control externally. (via parent)

export const TextEditor = forwardRef<
	ReactFrameworkOutput<Extension> | undefined,
	PropsWithChildren<TextEditorProps> & (VoiceProps | {})
>(
	(
		{
			placeholder,
			stringHandler,
			children,
			version,
			type,
			onEnhance,
			onChangePrompt,
			onGenerate,
			save,
			scrollContainerRef,
			appTheme,
			onOpenGuideFromId,
			convertToGif,
			navigateToTime,
			...rest
		},
		ref,
	) => {
		//@ts-ignore
		const editScreenshot = useEditScreenshot(type);
		//@ts-ignore
		const linkExtension =
			type === "helpCenter"
				? new ViewingLinkExtension({
						onOpenGuideFromId: (x) => {
							if (onOpenGuideFromId) {
								onOpenGuideFromId(x);
							} else {
								return false;
							}
							return true;
						},
					})
				: new LinkExtension({
						autoLink: true,
						defaultTarget: "_blank",
						extraAttributes: {
							guideId: {
								default: null,
								parseDOM: (dom) =>
									dom.getAttribute("data-guide-id"),
								toDOM: (attrs) => [
									"data-guide-id",
									attrs.guideId as any,
								],
							},
						},
					});

		const proxyConvertToGif: ConvertToGifFunction = () => {};

		const extensions = useCallback(
			() => [
				new PlaceholderExtension({
					placeholder: "Click here to begin typing",
				}), //want
				new TableExtension({}), //dont want
				new SpecialImageExtension({
					//dont want
					enableResizing: false,
					editable: rest.editable,
					type: type ?? "platform",
					editScreenshot: editScreenshot,
					convertToGif: convertToGif || proxyConvertToGif,
					navigateToTime: navigateToTime,
				}),
				new InlineAiExtension(),
				linkExtension,

				...getExtensions({ syntaxTheme: "atom_dark" }),
				//use the default extensions in getExtensions These are the functionalities of the editor. Maybe make a copy.

				//(appTheme?? "gigauser-light") === "gigauser-light" ? "vs" : "atom_dark"
			],
			[rest.editable],
		);

		const onError: InvalidContentHandler = useCallback(({ json }) => {
			try {
				captureEvent({
					eventName: "ErrorRemirrorInvalidContent",
					value: {
						guide_id:
							window.location.pathname.split("/").at(-1) ?? "",
					},
				});
				const extractContent = (node: any): any[] => {
					if (!node) return [];

					let content: any[] = [];

					if (node.type === "text" && node.text) {
						content.push({
							type: "text",
							text: node.text,
						});
					}

					if (node.type === "image") {
						content.push({
							type: "paragraph",
							content: [node],
						});
					}

					if (Array.isArray(node.content)) {
						for (const child of node.content) {
							content = content.concat(extractContent(child));
						}
					}

					return content;
				};

				const extractedContent = extractContent(json);

				const wrappedContent = extractedContent.map((item) => {
					return {
						type: "paragraph",
						content: [item],
					};
				});

				return {
					type: "doc",
					content:
						wrappedContent.length > 0
							? wrappedContent
							: [
									{
										type: "paragraph",
										content: [],
									},
								],
				};
			} catch (err) {
				logger.error("Error extracting content:", err);
				return {
					type: "doc",
					content: [
						{
							type: "paragraph",
							content: [
								{
									type: "text",
									text: 'There is some error loading your content. Please revert back to the previous version from "File > Version History" in Guide Editor or contact support.',
								},
							],
						},
					],
				};
			}
		}, []);

		const { manager, getContext } = useRemirror({
			extensions,
			stringHandler,
			onError,
		});
		//@ts-ignore
		useImperativeHandle(ref, () => getContext(), [getContext]); //not needed. external.

		const onChange = useCallback((json: RemirrorJSON) => {
			//function run when the user makes some edits.
			// Store the JSON in localStorage
			save?.(json); //probably do some redux dispatch here for text slide. not a well defined type.
			//TODO: Optionally call the flag stuff here.
		}, []);

		let voiceProps: VoiceProps | {} = {};

		if ("voice" in rest) {
			voiceProps = { voice: rest.voice, onClickVoice: rest.onClickVoice };
		}

		//TODO: Remirror manager is important. Anything within that can modify and access and run toolbar.
		return (
			<div className={`TextEditorContainer ${appTheme}`}>
				<ThemeProvider>
					<Remirror manager={manager} {...rest}>
						<div
							className={`gigauser-TextEditor ${rest.size} gigauser-TextEditor-${type} ${rest.fixToolbar ? "gigauser-text-editor-fix" : ""}`}
						>
							{type !== "helpCenter" ? (
								<>
									<RichTextToolbar
										textEditorType={type || "platform"}
										save={save}
										onEnhance={onEnhance}
										onChangePrompt={onChangePrompt}
										enhanceLoading={rest.enhanceLoading}
										generateLoading={rest.generateLoading}
										onGenerate={onGenerate}
										version={version ?? "2023-03-12"}
										scrollContainerRef={scrollContainerRef}
										fixToolbar={rest.fixToolbar}
										{...voiceProps}
										disabled={!rest.editable}
									/>
								</>
							) : null}

							{/* Voice Changer Container */}
							{"voice" in rest && rest.voice && (
								<div className="voice-container">
									<div className="voice-flag-container">
										<div className="voice-flag">
											{
												getFlag(
													rest.voice
														?.languageCountryId ||
														"en-US",
												).flag
											}
										</div>
										<HoverTip
											hoverTipTitle={"Voice actor"}
											hoverTipPara="Choose an AI voice actor for your video from a number of accents."
										>
											<TranscriptToolbarButton
												text={`${rest.voice?.name}:`}
												disabled={!rest.editable}
												className="voiceButton"
												onClick={
													rest.editable
														? rest.onClickVoice
														: undefined
												}
											/>
										</HoverTip>
									</div>

									<div
										className="boxIcon-container voiceIcon"
										onClick={rest.onClickVoice}
									>
										Change Voice
									</div>
								</div>
							)}

							{type !== "helpCenter" && (
								<FakeLinkComponent
									linkExtension={
										linkExtension as LinkExtension
									}
									editable={rest.editable as boolean}
								></FakeLinkComponent>
							)}

							<div className="gigauser-TextEditor-innerContainer">
								<div
									className={`
										${type === "platform" ? "gigauser-TextEditor-innerContainer-platform" : "gigauser-TextEditor-innerContainer-rest"}
									
										`}
								>
									<div
										className={`gigauser-TextEditor-visible-${rest.enhanceLoading ? "false" : "true"}`}
									>
										<EditorComponent />
									</div>
									{type === "platform" ? (
										<div
											className={`gigauser-TextEditor-loading-skeleton-${rest.enhanceLoading ? "true" : "false"}`}
										>
											<Skeleton
												width="50%"
												height="2rem"
												variant="rounded"
												animationDuration={1}
												style={{ marginBottom: "2rem" }}
											/>
											<Skeleton
												width="90%"
												height="15rem"
												variant="rounded"
												sheenWidth={"20rem"}
												animationDuration={1}
												style={{ marginBottom: "2rem" }}
											/>
											{[0, 1].map((i) => {
												return (
													<div key={i}>
														<Skeleton
															width="50%"
															height="1.8rem"
															variant="rounded"
															animationDuration={
																1
															}
															style={{
																marginBottom:
																	"2rem",
															}}
														/>
														<Skeleton
															width="100%"
															height="1rem"
															variant="rounded"
															animationDuration={
																1
															}
															style={{
																marginBottom:
																	"1rem",
															}}
														/>
														<Skeleton
															width="95%"
															height="1rem"
															variant="rounded"
															animationDuration={
																1
															}
															style={{
																marginBottom:
																	"1rem",
															}}
														/>
														<Skeleton
															width="70%"
															height="1rem"
															variant="rounded"
															animationDuration={
																1
															}
															style={{
																marginBottom:
																	"2rem",
															}}
														/>
													</div>
												);
											})}
										</div>
									) : null}
								</div>
								{rest.editable &&
								(type === "platform" ||
									type === "videoTranscript") ? (
									<InlineAIPositioner textEditorType={type} />
								) : null}
								<OnChangeJSONWrapper
									onChange={onChange}
								></OnChangeJSONWrapper>
								{children}
							</div>
						</div>
					</Remirror>
				</ThemeProvider>
			</div>
		);
	},
);

interface OnChangeJSONWrapperProps {
	onChange: (json: RemirrorJSON) => void;
}

const OnChangeJSONWrapper: React.FC<OnChangeJSONWrapperProps> = (props) => {
	// this wrapper exists so that you can use remirror hooks and apply them to the on change function

	const actualOnChange = (json: RemirrorJSON) => {
		props.onChange(json);
	};

	return <OnChangeJSON onChange={actualOnChange}></OnChangeJSON>;
};

const FakeLinkComponent = (props: {
	linkExtension: LinkExtension;
	editable: boolean;
}) => {
	const commands = useChainedCommands();
	const dispatch = useAppDispatch();
	const view = useEditorView();
	useEffectOnce(() => {
		props.linkExtension.addHandler("onClick", (e, data) => {
			// We need to check for if editable
			// e.preventDefault()
			// e.stopPropagation()
			let href = data.href;
			if (data.guideId as any) {
				e.preventDefault();
				e.stopPropagation();
				href = "/guide/" + data.guideId;
			}
			if (!props.editable) {
				// Just take them to that page
				if (data.guideId) {
					window.open(href, "_blank");
				}
				return false;
			} else {
				// Give them a way to edit
				// i'm going to use confirm for now because
				// why not
				commands.selectLink().run();
				const text = view.state.doc.textBetween(
					view.state.selection.from,
					view.state.selection.to,
				);

				dispatch(
					openOverlay({
						heading: {
							icon: null,
							title: "Link Details",
							data: "",
						},
						component: (
							<LinkPopup
								text={text}
								currentLink={(data.guideId as any) ?? data.href}
								save={(newText, newLink) => {
									if (newLink) {
										if (newLink.includes("https"))
											commands
												.replaceText({
													content: newText,
												})
												.updateLink({ href: newLink })
												.run();
										else
											commands
												.replaceText({
													content: newText,
												})
												.updateLink({
													href: "#0",
													guideId: newLink,
												})
												.run();
									}
								}}
								delete={() => commands.removeLink().run()}
							></LinkPopup>
						),
					}),
				);
				// const newLink = prompt("Enter link value or guide ID")
				// if (newLink){

				//     if (newLink.includes("https")) commands.selectLink().updateLink({href: newLink}).run()
				//     else commands.selectLink().updateLink({href: '', guideId: newLink}).run()
				// }
			}
			return false;
		});
	});
	return <></>;
};
