import { useAppDispatch } from "../../../../../redux";
import ScreenshotEditor from "./ScreenshotEditor";
import {
	GifExtensionMetadata,
	ImageExtensionMetadata,
	SpecialImageCoordinates,
} from "../../extensions/SpecialImageExtension";
import { Shape } from "./ScreenshotEditorContext";
import { TextEditorType } from "../../TextEditor";
import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import {
	openModal,
	setDisableClickoutModal,
} from "../../../../../layouts/CModal/modalSlice";

export type ScreenshotRenderEdits = {
	zoomFactor: number;
	zoomCenter: SpecialImageCoordinates;
	shapes?: Shape[];
	crop?: Crop;
};

export type EditScreenshotFunction = (
	src: string,
	metadata: ImageExtensionMetadata,
	onEditScreenshot: (edits: ScreenshotRenderEdits) => void,
) => void;

export type ConvertToGifFunction = (
	metadata: ImageExtensionMetadata,
	insertGifInNotebook: InsertGifInNotebookFunction,
) => void;
export type InsertGifInNotebookFunction = (
	url: string,
	s3ObjectId: string,
	metadata: ImageExtensionMetadata & GifExtensionMetadata,
) => void;

const useEditScreenshot: (type: TextEditorType) => EditScreenshotFunction = (
	type: TextEditorType,
) => {
	const dispatch = useAppDispatch();
	if (type === "helpCenter") return () => {};
	else
		return (
			src: string,
			metadata: ImageExtensionMetadata,
			onEditScreenshot,
		) => {
			dispatch(
				openModal({
					heading: { icon: null, title: "Edit Screenshot", data: "" },
					component: (
						<ScreenshotEditor
							rootSrc={src}
							metadata={metadata}
							onEditScreenshot={onEditScreenshot}
						/>
					),
				}),
			);

			dispatch(setDisableClickoutModal(true));
		};
};

export default useEditScreenshot;
