import { GigaUserApi } from "@giga-user-fern/api";
import { Organization } from "../../types/guide";

export const dummyOrganization: Organization = {
	id: GigaUserApi.Id("google"),
	name: "Google",
	brandColor: "#d43f8c",
	domain: "google.com",
};

export const dummyFrontendVideoSource = {
	id: GigaUserApi.Id("dummy"),
	src: "",
	loaded: true,
	ref: { current: null },
};
