const aiRewriteLoadingIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				animation: "spin 1s linear infinite",
			}}
		>
			<style>
				{`
					@keyframes spin {
						from { transform: rotate(0deg); }
						to { transform: rotate(360deg); }
					}
				`}
			</style>
			<circle
				cx="13"
				cy="13"
				r="12"
				fill="url(#paint0_linear_674_10710)"
			/>
			<circle
				cx="13"
				cy="13"
				r="12.3"
				stroke={color}
				stroke-opacity="0.05"
				stroke-width="0.6"
			/>
			<path
				d="M13 4.875V6.95833M13 18V21.3333M7.79167 13H4.875M20.7083 13H19.4583M18.3809 18.3809L17.7917 17.7917M18.5535 7.51315L17.375 8.69167M7.10131 18.8987L9.45833 16.5417M7.2739 7.34057L9.04167 9.10833"
				stroke="white"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_674_10710"
					x1="16.5745"
					y1="1"
					x2="16.5745"
					y2="25"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#D43F8C" />
					<stop offset="1" stop-color="#4038B6" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default aiRewriteLoadingIcon;
