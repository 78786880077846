export function hexToRGBA(h: string, t?: number): string {
	// Ensure transparency is within the bounds of 0 to 100
	if (h === "transparent") return "rgba(0,0,0,0)";
	let hex = h;
	let transparency = t;
	if (transparency === undefined) transparency = 100;

	if (transparency < 0) {
		transparency = 0;
	} else if (transparency > 100) {
		transparency = 100;
	}

	// Convert transparency to an alpha value between 0 and 1
	let alpha = (transparency / 100).toFixed(2);

	if (transparency === 0) {
		alpha = "0"; // Set alpha to 0 when transparency is 0
	} else {
		alpha = (transparency / 100).toFixed(2); // Ensure alpha is calculated correctly for other values
	}

	// Remove the hash at the beginning of the hex color if it's present
	hex = hex.replace(/^#/, "");
	// Parse the hex color
	let r: number;
	let g: number;
	let b: number;
	if (hex.length === 3) {
		// If the hex code is shorthand (3 characters), double each character
		r = Number.parseInt(hex.charAt(0) + hex.charAt(0), 16);
		g = Number.parseInt(hex.charAt(1) + hex.charAt(1), 16);
		b = Number.parseInt(hex.charAt(2) + hex.charAt(2), 16);
	} else if (hex.length === 6 || hex.length === 8) {
		// If the hex code is standard (6 characters), parse each pair of characters
		r = Number.parseInt(hex.substring(0, 2), 16);
		g = Number.parseInt(hex.substring(2, 4), 16);
		b = Number.parseInt(hex.substring(4, 6), 16);
		alpha =
			hex.length === 8
				? (Number.parseInt(hex.substring(6, 8), 16) / 255).toFixed(2)
				: alpha;
	} else {
		// handle if string is rgba or rgb
		const rgba = hex.split(",");
		if (rgba.length === 3) {
			r = Number.parseInt(rgba[0].split("(")[1]);
			g = Number.parseInt(rgba[1]);
			b = Number.parseInt(rgba[2].split(")")[0]);
			alpha = "1";
		} else if (rgba.length === 4) {
			r = Number.parseInt(rgba[0].split("(")[1]);
			g = Number.parseInt(rgba[1]);
			b = Number.parseInt(rgba[2]);
			alpha = rgba[3].split(")")[0];
		} else {
			throw new Error("Invalid hex color: " + hex);
		}
	}

	// Return the color in RGBA format
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export type ColorAlias =
	| "red"
	| "orange"
	| "yellow"
	| "green"
	| "blue"
	| "violet"
	| "brown"
	| "black"
	| "transparent"
	| "purple"
	| "teal";

export const basicColors: ColorAlias[] = [
	"red",
	"orange",
	"yellow",
	"green",
	"blue",
	"violet",
	"brown",
	"black",
	"purple",
	"teal",
];

type ColorMapType = { [key in ColorAlias]?: string };
type StrictColorMapType = { [key in ColorAlias]: string };

function inverse(obj: any) {
	var retobj: any = {};
	for (var key in obj) {
		retobj[obj[key]] = key;
	}
	return retobj;
}

export const ColorMap: StrictColorMapType = {
	red: "#f08080",
	orange: "#FFA500",
	yellow: "#ffcc00",
	green: "#00ff7f",
	blue: "#87cefa",
	violet: "#EE82EE",
	brown: "#3f000f",
	black: "#1a1b1f",
	transparent: "#ffcc00",
	purple: "#ac94f4",
	teal: "#81cdc6",
};

export const ReverseColorMap = inverse(ColorMap);

export const unravelColor: (hex: string) => {
	color: string;
	opacity: number;
} = (hex: string) => {
	/**
	 * Unravels a color hex code into its color and opacity components.
	 * @param hex - The 9-character color hex code to unravel (including '#').
	 * @returns An object containing the color and opacity components. Opacity is from [0,100].
	 */

	if (hex.length === 9) {
		// Extract the opacity from the last two characters and convert to decimal
		const opacityHex = hex.substring(7, 9);
		const opacity = Number.parseInt(opacityHex, 16) / 2.55; // Scale from 0-255 to 0-100

		// Update hex to be just the color part
		hex = hex.substring(0, 7);

		return { color: hex, opacity: Math.round(opacity) }; // Round to nearest whole number
	} else {
		// Assume full opacity if the hex code does not include opacity information
		return { color: hex, opacity: 100 }; // Full opacity as 100
	}
};

export const ravelColor: (hex: string, opacity?: number) => string = (
	hex,
	opacity = 100,
) => {
	/**
	 * Ravel a color and opacity into a hex code.
	 * @param hex - The color hex code.
	 * @param opacity - The opacity value, between 0 and 100.
	 * @returns The 8-character color hex code.
	 */

	// Ensure the input hex is a 7-character string starting with '#'
	hex = hex.substring(0, 7);

	// Convert opacity from a 0-100 scale to a 0-255 scale
	const scaledOpacity = Math.floor((opacity / 100) * 255);

	// Convert the numerical opacity to a hexadecimal string and pad if necessary
	const opacityHex = scaledOpacity.toString(16).padStart(2, "0");

	// Concatenate the color hex code with the opacity hex code
	return hex + opacityHex;
};
