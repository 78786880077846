import React, { useEffect, useRef, useState } from "react";
import "./VideoSlider.css";
import GridLoader from "../../../../../ui/loaders/GridLoader/GridLoader";
import LoadingRing from "../../../../../assets/gifs/LoadingRing/LoadingRing";
import { useAppSelector } from "../../../../../redux";
import { selectGuide } from "../../../../../redux/slices/guideSlice";
import { selectAnalytics } from "../../../../../redux/slices/backendSlice";
import Cutton from "../../../../../ui/buttons/Cutton/Cutton";
import { isDisabled } from "@testing-library/user-event/dist/utils";

interface VideoSliderProps {
	source: string;
	initialStartTimestamp: number;
	initialEndTimestamp?: number;
	addImage: (dataUrl: string, timestamp: number) => void;
	type: "videoTranscript" | "textEditor";
	reloadSource: () => Promise<string>;
}

const VideoSlider: React.FC<VideoSliderProps> = ({
	source,
	initialStartTimestamp,
	initialEndTimestamp,
	addImage,
	type,
	reloadSource,
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [sliderValue, setSliderValue] = useState(initialStartTimestamp);
	const [videoDuration, setVideoDuration] = useState<number | null>(null);
	const [startTimestamp, setStartTimestamp] = useState(initialStartTimestamp);
	const [endTimestamp, setEndTimestamp] = useState(
		initialEndTimestamp ?? videoDuration ?? 0,
	);
	const [loading, setLoading] = useState(true);
	const [addLoading, setAddLoading] = useState(false);
	const [src, setSrc] = useState(source);
	const guide = useAppSelector(selectGuide);
	const analytics = useAppSelector(selectAnalytics);

	useEffect(() => {
		const video = videoRef.current;
		if (!video) return;

		const handleMetadataLoaded = () => {
			setVideoDuration(video.duration);
		};

		video.addEventListener("loadedmetadata", handleMetadataLoaded);
		return () =>
			video.removeEventListener("loadedmetadata", handleMetadataLoaded);
	}, []);

	useEffect(() => {
		if (videoDuration !== null) {
			setStartTimestamp(
				Math.max(0, Math.min(initialStartTimestamp, videoDuration)),
			);
			setEndTimestamp(
				Math.max(
					0,
					Math.min(
						initialEndTimestamp ?? videoDuration,
						videoDuration,
					),
				),
			);
			setLoading(false);
			if (videoRef.current) {
				videoRef.current.currentTime = Math.max(
					0,
					Math.min(initialStartTimestamp, videoDuration),
				);
			}
		}
	}, [videoDuration, initialStartTimestamp, initialEndTimestamp]);

	const onSubmit = () => {
		const submitValue = getSubmitValue();

		if (submitValue < 0) return;

		setAddLoading(true);
		const video = videoRef.current;
		if (!video) return;

		const canvas = document.createElement("canvas");
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
		const screenshotDataUrl = canvas.toDataURL();
		addImage(screenshotDataUrl, submitValue);

		if (guide?.id) {
			analytics.captureEvent({
				eventName:
					type === "textEditor"
						? "ScreenshotAddedInArticleFromVideo"
						: "ScreenshotAddedInTranscriptFromVideo",
				value: { guide_id: guide.id.toString() },
			});
		}
	};

	const getSubmitValue: () => number = () => {
		if (sliderValue === startTimestamp) {
			if (sliderValue + 0.1 >= endTimestamp) {
				return -1;
			} else {
				return sliderValue + 0.1;
			}
		} else if (sliderValue === endTimestamp) {
			if (sliderValue - 0.1 <= startTimestamp) {
				return -1;
			} else {
				return sliderValue - 0.1;
			}
		} else {
			return sliderValue;
		}
	};

	const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseFloat(event.target.value);
		setSliderValue(value);
		if (videoRef.current) {
			videoRef.current.currentTime = value;
		}
	};

	const percentage =
		((sliderValue - startTimestamp) / (endTimestamp - startTimestamp)) *
		100;

	// Dynamic linear gradient for the slider's background
	const sliderBackground = `linear-gradient(to right, #d43f8c ${percentage}%, darkgray ${percentage}%, darkgray 100%)`;
	let leftWidth = 0;
	let rightWidth = 0;

	if (videoDuration !== null) {
		leftWidth = (startTimestamp / videoDuration) * 100;
		rightWidth = 100 - (endTimestamp / videoDuration) * 100;
	}

	let textjsx;
	if (type === "videoTranscript") {
		textjsx = (
			<>
				Select the screenshot to be added in the transcript.<br></br>{" "}
				This will be used to sync the audio and video
			</>
		);
	} else {
		textjsx = <>Select the screenshot to be added in the editor</>;
	}

	const disabled = getSubmitValue() < 0;

	return (
		<>
			{loading && <GridLoader></GridLoader>}
			<div
				className="video-slider-container"
				style={{ visibility: loading ? "hidden" : "visible" }}
			>
				<div className="video-slider-explanation">{textjsx}</div>
				<video
					ref={videoRef}
					src={src}
					className="video-slider-video"
					crossOrigin="anonymous"
					onError={async () => {
						const newSrc = await reloadSource();
						setSrc(newSrc);
					}}
				></video>
				<div className="video-slider-wrapper">
					<div
						className="video-slider-inactive"
						style={{ width: `${leftWidth}%` }}
					></div>
					<input
						type="range"
						min={startTimestamp}
						max={endTimestamp}
						value={sliderValue}
						step="0.1"
						onChange={handleSliderChange}
						className="video-slider-input"
						style={{
							background: sliderBackground,
							width: `${100 - rightWidth}%`,
						}}
					/>
					<div
						className="video-slider-inactive"
						style={{
							width: `${rightWidth}%`,
						}}
					></div>
				</div>

				<Cutton
					onClick={onSubmit}
					isLoading={addLoading}
					disabled={disabled}
				>
					Add
				</Cutton>
			</div>
		</>
	);
};

export default VideoSlider;
