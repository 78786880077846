type SaverConfig = {
	main_url: string;
	panchayat_url: string;
	un_url: string;
};

const saverconfig: SaverConfig = {
	main_url: process.env.REACT_APP_API_SERVICE_URL || "",
	panchayat_url: process.env.REACT_APP_PANCHAYAT_URL || "",
	un_url: process.env.REACT_APP_UN_URL || "",
};

export default saverconfig;
