import { GigaUserApi } from "@giga-user-fern/api";
import { AnalyticsEvent } from "../core/analytics/analytics";
import logger from "../utils/logger";

export function dataURItoBlob(dataURI: string) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

	try {
		var byteString = atob(dataURI.split(",")[1]);

		// separate out the mime component
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		var ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		var blob = new Blob([ab], { type: mimeString });
		return blob;
	} catch (e) {
		logger.error("Error", e, dataURI);
		return dataURI;
	}
}

export const uploadToPresignedXhr = async (
	body: any,
	presignedURL: GigaUserApi.PresignedUrl,
	progressCallback?: (number: number) => void,
	captureEvent?: (event: AnalyticsEvent) => void,
) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		const data = { ...(presignedURL as any) };

		// Append fields to formData
		for (const key in data.fields) {
			formData.append(key, data.fields[key]);
		}
		formData.append("file", body);

		const xhr = new XMLHttpRequest();

		// Track upload progress
		xhr.upload.addEventListener("progress", (event) => {
			if (event.lengthComputable) {
				if (progressCallback) {
					progressCallback((event.loaded / event.total) * 100);
				}
			}
		});

		// Handle the response
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				// Done
				if (xhr.status === 200 || xhr.status === 204) {
					resolve(true);
				} else {
					console.error(
						"Upload failed:",
						xhr.status,
						xhr.responseText,
					);
					captureEvent?.({
						eventName: "UploadError",
						value: { error: xhr.responseText },
					});
					reject(null);
				}
			}
		};

		// Handle network errors
		xhr.onerror = () => {
			console.error("Network Error");
			captureEvent?.({
				eventName: "UploadError",
				value: { error: "Network Error" },
			});
			reject("Network Error");
		};

		// Set up the request and send the formData
		xhr.open("POST", data.url, true);
		xhr.send(formData);
	});
};

export const uploadToPresigned = async (
	body: any,
	presignedURL: GigaUserApi.PresignedUrl,
	captureEvent: (event: AnalyticsEvent) => void,
) => {
	const formData = new FormData();
	const data = { ...(presignedURL as any) };
	// data.fields.key = presignedURL.fields.key + "hello.txt"
	for (const key in data.fields) {
		formData.append(key, data.fields[key]);
	}
	formData.append("file", body);
	try {
		const resp = await fetch(data.url, { method: "POST", body: formData });
		if (!resp.ok) {
			// No upload
			captureEvent?.({
				eventName: "UploadError",
				value: { error: JSON.stringify(resp) },
			});
			logger.debug("BODY OF FAILED UPLOAD", body);
			return false;
		}
		return resp;
	} catch (e: any) {
		try {
			const resp = await fetch(data.url, {
				method: "POST",
				body: formData,
			});
			logger.debug("UPLOADED", resp);
			if (!resp.ok) {
				// No upload
				captureEvent({
					eventName: "UploadError",
					value: { error: JSON.stringify(resp) },
				});
				logger.debug("BODY OF FAILED UPLOAD", body);
				return false;
			}
			return resp;
		} catch (e: any) {
			logger.debug(e, e.status);
			console.error("Error uploading", e, e.status);
			try {
				captureEvent({
					eventName: "UploadError",
					value: { error: JSON.stringify(e) },
				});
			} catch (e) {}
			return Promise.resolve(null);
		}
	}
};
