// modalSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	OverlayComponent,
	OverlayComponentHeading,
} from "../Overlay/overlaySlice";
import { RootState } from "../../redux/store";

interface ModalState {
	isOpen: boolean;
	content: OverlayComponent;
	disableCloseButton: boolean;
	disableClickoutModal: boolean;
}

const initialState: ModalState = {
	isOpen: false,
	content: null,
	disableCloseButton: false,
	disableClickoutModal: false,
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		openModal: (state, action: PayloadAction<OverlayComponent>) => {
			state.isOpen = true;
			state.content = action.payload;
			state.disableCloseButton = false;
			state.disableClickoutModal = false;
		},
		closeModal: (state) => {
			state.isOpen = false;
			state.content = null;
			state.disableCloseButton = false;
			state.disableClickoutModal = false;
		},
		changeModalHeading: (
			state,
			action: PayloadAction<OverlayComponentHeading>,
		) => {
			if (state.content) {
				state.content.heading = action.payload;
			}
		},
		disableCloseButton: (state) => {
			state.disableCloseButton = true;
		},
		enableCloseButton: (state) => {
			state.disableCloseButton = false;
		},

		setDisableClickoutModal: (state, action: PayloadAction<boolean>) => {
			state.disableClickoutModal = action.payload;
		},
	},
});

export const selectModalState = (state: RootState) => state.modal;
export const {
	openModal,
	closeModal,
	setDisableClickoutModal,
	changeModalHeading,
	disableCloseButton,
	enableCloseButton,
} = modalSlice.actions;
export default modalSlice.reducer;
