import { GigaUserApi } from "@giga-user-fern/api";
import {
	AspectRatio,
	Coordinates,
	Cover,
	ElementEdit,
	ElementGeo,
	Id,
	ImageType,
	OrganizationBackground,
	OrganizationTemplate,
	TextAlignment,
} from "@giga-user-fern/api/types/api";
import { OrganizationLogo } from "@giga-user-fern/api/types/api/resources/organizations";
import { v4 as uuidv4 } from "uuid";

export type TransitionType =
	| "circleWipe"
	| "crossfade"
	| "rainbowWipe"
	| "cornerWipe"
	| "stack"
	| "colorWipe"
	| "fade";

export const drawRoundedRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
) => {
	// Ensure the radius does not exceed 1/2 of the smallest rectangle dimension
	const radiusNew = Math.min(radius, width / 2, height / 2);

	// Begins a new path (to avoid issues with previous paths)
	ctx.beginPath();

	// Moves to the start point of the top horizontal line
	ctx.moveTo(x + radius, y);

	// Draws lines and curves
	ctx.lineTo(x + width - radiusNew, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radiusNew);
	ctx.lineTo(x + width, y + height - radiusNew);
	ctx.quadraticCurveTo(
		x + width,
		y + height,
		x + width - radiusNew,
		y + height,
	);
	ctx.lineTo(x + radiusNew, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radiusNew);
	ctx.lineTo(x, y + radiusNew);
	ctx.quadraticCurveTo(x, y, x + radiusNew, y);

	// Closes the path and optionally you can fill or stroke the path here
	// ctx.closePath();
};

export const drawBoxShadow = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	shadowFactor: number,
	borderRadius?: number,
) => {
	// Draw shadow
	const shadowOffset = 0; // Adjust as needed
	ctx.save();
	ctx.beginPath();

	const borderRadius2 = borderRadius || 0;

	drawRoundedRect(
		ctx,
		x - shadowOffset,
		y - shadowOffset,
		width + 2 * shadowOffset,
		height + 2 * shadowOffset,
		borderRadius2 + shadowOffset,
	);
	ctx.shadowColor = "rgba(0, 0, 0, 0.6)"; // Adjust as needed
	ctx.shadowBlur = shadowFactor; // Adjust as needed
	ctx.shadowOffsetX = 2; // Adjust as needed
	ctx.shadowOffsetY = 2; // Adjust as needed
	ctx.fillStyle = "#c7c7c7";
	ctx.fill();
	ctx.restore();
};

export const getScaleFactor = (width: number, height: number) => {
	const w_f = width / 1680;
	const h_f = height / 920;

	return { f: (w_f + h_f) / 2, wf: w_f, hf: h_f };
};

export type FractionalCoordsToCanvasCoordsFunction = (
	pos: Coordinates,
	canvas?: HTMLCanvasElement,
) => Coordinates;

export const fractionalCoordsToCanvasCoordsUtil: FractionalCoordsToCanvasCoordsFunction =
	(pos, canvas) => {
		if (!canvas) return pos;

		const canvas_x = canvas.width * pos.x;
		const canvas_y = canvas.height * pos.y;

		return { x: canvas_x, y: canvas_y };
	};

export const sourcesAreEqual = (
	source1: GigaUserApi.Id,
	source2?: GigaUserApi.Id,
) => {
	if (source1.includes("clip_") || source2?.includes("clip_")) {
		//either of the sources is a clip (the other could be the original)
		return source1 === source2;
	} else return true;
};

export const loadImage = (src: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		img.src = src;
	});
};

export const loadBackgroundImage = (
	src: string,
	color?: string,
): Promise<HTMLImageElement> => {
	return new Promise(async (resolve, reject) => {
		// for the case of pattern backgrounds
		// (check if the url is svg and the color is provided)
		if (color) {
			try {
				const response = await fetch(src);
				let svgText = await response.text();

				// Replace {patternColor} with actual color
				svgText = svgText.replace(/\{patternColor\}/g, `"${color}"`);

				const img = new Image();
				const svg = new Blob([svgText], { type: "image/svg+xml" });
				const svgUrl = URL.createObjectURL(svg);

				img.src = svgUrl;
				resolve(img);
			} catch (error) {
				reject(error);
			}
		}
		// for the case of normal (image) backgrounds
		else {
			const img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = src;
		}
	});
};

/**
 * Creates an image file from a SVG background pattern.
 * @param imageSrc - The source URL of the SVG image.
 * @param patternColor - The color to apply to the SVG pattern.
 * @param canvasWidth - The width to draw the image on the canvas. Defaults to 1920.
 * @param canvasHeight - The height to draw the image on the canvas. Defaults to 1080.
 * @returns A Promise that resolves to a File object representing the PNG image.
 */
export async function createImageFileFromPattern(
	imageSrc: string,
	patternColor: string,
	canvasWidth = 1920,
	canvasHeight = 1080,
): Promise<File> {
	// Load the SVG background image with the given color
	const coloredSvgImage = await loadBackgroundImage(imageSrc, patternColor);

	// Set crossOrigin to allow canvas access
	coloredSvgImage.crossOrigin = "anonymous";
	if (!coloredSvgImage.complete) {
		await new Promise((resolve) => {
			coloredSvgImage.onload = resolve;
		});
	}

	// Create an offscreen canvas and draw the image
	const offcanvas = document.createElement("canvas");
	const context = offcanvas.getContext("2d");
	offcanvas.width = canvasWidth;
	offcanvas.height = canvasHeight;

	if (context) {
		context.drawImage(coloredSvgImage, 0, 0, canvasWidth, canvasHeight);
	}

	// Convert canvas to a data URL representing a PNG image
	const dataUrl = offcanvas.toDataURL("image/png");
	const response = await fetch(dataUrl);
	const blob = await response.blob();

	// Create a File from the blob
	const file = new File([blob], "coloredSvgImage.png", { type: "image/png" });
	return file;
}

// functions to classify image elements
export const isRegularImage = (e: ElementEdit) =>
	e.geo === "image" &&
	(!e.imagedata ||
		(e.imagedata.type !== "logo" && e.imagedata.type !== "frame"));

export const isFrameImage = (e: ElementEdit) =>
	e.geo === "image" && e?.imagedata?.type === "frame";

export const isLogoImage = (e: ElementEdit) =>
	e.geo === "image" && e?.imagedata?.type === "logo";

export const isPremadeBackground = (bg: OrganizationBackground) =>
	bg.backgroundType === "pattern" ||
	(bg?.backgroundType === "image" && bg?.premadeBackgroundId);

export const isElementWrtVideo = (element: ElementEdit) => {
	/**
	 * Takes in an element, and checks if this elements size and position are wrt video or canvas.
	 */
	const elementsWrtCanvas: ElementGeo[] = ["text", "image"];
	const wrtVideo = !elementsWrtCanvas.includes(element.geo);

	return wrtVideo;
};

export const mapElementsFromCoverToCover = (
	previousCoverOriginal: Cover,
	newCoverTemplate: Cover,
	organizationLogos: OrganizationLogo[],
) => {
	const previousCover: Cover = JSON.parse(
		JSON.stringify(previousCoverOriginal),
	);
	const newCover: Cover = JSON.parse(JSON.stringify(newCoverTemplate));

	// Map text elements: one-to-one mapping;
	// append extra text elements from previousCover if any
	const previousTextElements =
		previousCover.coverEdits?.filter(
			(e) => e.geo === "text" && e.textdata && e.textdata.text,
		) ?? [];

	const newTextElements =
		newCover.coverEdits?.filter((e) => e.geo === "text") ?? [];

	const mappedTextElements: ElementEdit[] = [];

	// find the max length of the two arrays
	const textCount = Math.max(
		previousTextElements.length,
		newTextElements.length,
	);

	for (let i = 0; i < textCount; i++) {
		if (i < previousTextElements.length && i < newTextElements.length) {
			mappedTextElements.push({
				...newTextElements[i],
				id: GigaUserApi.Id(uuidv4()),
				textdata: {
					...newTextElements[i].textdata,
					text: previousTextElements[i].textdata?.text ?? "",
					lines: previousTextElements[i].textdata?.lines ?? [],
					fontSize: newTextElements[i].textdata?.fontSize ?? 0,
					alignment:
						newTextElements[i].textdata?.alignment ??
						("left" as TextAlignment),
					backgroundColor:
						newTextElements[i].textdata?.backgroundColor ?? "",
					backgroundOpacity:
						newTextElements[i].textdata?.backgroundOpacity ?? 0,
					textColor: newTextElements[i].textdata?.textColor ?? "",
				},
			});
		} else if (i < previousTextElements.length) {
			// extra text element from previousCover, add as is
			// mappedTextElements.push(previousTextElements[i]);
		} else if (i < newTextElements.length) {
			// retain remaining newCover text elements if any
			mappedTextElements.push({
				...newTextElements[i],
				id: GigaUserApi.Id(uuidv4()),
			});
		}
	}

	// Map regular image elements (excluding logos and frame)
	const previousRegularImages: ElementEdit[] =
		previousCover.coverEdits?.filter(isRegularImage) ?? [];
	const newRegularImages: ElementEdit[] =
		newCover.coverEdits?.filter(isRegularImage) ?? [];
	const mappedRegularImages: ElementEdit[] = [];

	const regularCount = Math.max(
		previousRegularImages.length,
		newRegularImages.length,
	);

	for (let i = 0; i < regularCount; i++) {
		if (i < previousRegularImages.length && i < newRegularImages.length) {
			// map one-to-one: merge previous data excluding geometry properties
			const { position, size, ...prevData } = previousRegularImages[i];
			newRegularImages[i] = { ...newRegularImages[i], ...prevData };
			mappedRegularImages.push({
				...newRegularImages[i],
				id: GigaUserApi.Id(uuidv4()),
			});
		} else if (i < previousRegularImages.length) {
			// add extra previous regular image element as is
			// mappedRegularImages.push(previousRegularImages[i]);
		} else if (i < newRegularImages.length) {
			// retain remaining newCover regular image element if any
			mappedRegularImages.push({
				...newRegularImages[i],
				id: GigaUserApi.Id(uuidv4()),
			});
		}
	}

	// Map frame image elements:
	const previousFrameImages =
		previousCover.coverEdits?.filter(isFrameImage) ?? [];
	const newFrameImages = newCover.coverEdits?.filter(isFrameImage) ?? [];
	const mappedFrameImages: ElementEdit[] = [];
	const maxFrameCount = Math.max(
		newFrameImages.length,
		previousFrameImages.length,
	);

	for (let i = 0; i < maxFrameCount; i++) {
		if (i < newFrameImages.length && i < previousFrameImages.length) {
			mappedFrameImages.push({
				...previousFrameImages[i],
				id: GigaUserApi.Id(uuidv4()),
				imagedata: {
					...(newFrameImages[i]?.imagedata ??
						previousFrameImages[i].imagedata),
					src: previousFrameImages[i].imagedata?.src ?? "",
					type: previousFrameImages[i].imagedata?.type as ImageType,
					naturalHeight:
						previousFrameImages[i].imagedata?.naturalHeight ?? 0,
					naturalWidth:
						previousFrameImages[i].imagedata?.naturalWidth ?? 0,
				},
				position:
					newFrameImages[i]?.position ??
					previousFrameImages[i].position,
				size: newFrameImages[i]?.size ?? previousFrameImages[i].size,
				geo: newFrameImages[i]?.geo ?? previousFrameImages[i].geo,
			});
		} else if (i < newFrameImages.length) {
			// this will add the frame from the template to the cover
			// if you want to add frame from the current video then handle that here

			mappedFrameImages.push({
				...newFrameImages[i],
				id: GigaUserApi.Id(uuidv4()),
			});
		}
	}

	// Handle logo image elements:
	// Map logos by comparing new and previous. If the previous cover has fewer logos than new cover,
	// we add the extra logos from newCover using organization logos if available.
	const newLogoTemplates: ElementEdit[] =
		newCover.coverEdits?.filter(isLogoImage) ?? [];
	const mappedLogoImages: ElementEdit[] = [];

	for (let i = 0; i < newLogoTemplates.length; i++) {
		// one-to-one mapping: use the new logo element directly
		mappedLogoImages.push({
			...newLogoTemplates[i],
			id: GigaUserApi.Id(uuidv4()),
		});
	}

	// Combine all mapped elements
	return {
		...newCover,
		coverEdits: [
			...mappedTextElements,
			...mappedRegularImages,
			...mappedFrameImages,
			...mappedLogoImages,
		],
	};
};

export const templateToCover = (
	template: OrganizationTemplate,
	aspectRatio?: AspectRatio,
	edits?: Cover,
): Cover => {
	const newCover: Cover = {
		coverEdits: template.elementEdits,
		background: {
			id: template.backgroundId as Id,
			src: template.backgroundUrl,
			backgroundType: template.backgroundType ?? "image",
			// width: cp.getCurrentAspectRatio()?.width ?? 1920,
			// height: cp.getCurrentAspectRatio()?.height ?? 1080,
			width: aspectRatio?.width || template.aspectRatio.width,
			height: aspectRatio?.height || template.aspectRatio.height,
			organizationId: GigaUserApi.Id("dummy"),
			color: template.color,
			isStarred: false,
		},
		backgroundSrc: template.backgroundUrl,

		type: template.templateMediaType === "video" ? "video" : "image",
		visible: true,
		duration: edits?.duration
			? edits.duration > 6
				? 6
				: edits.duration
			: 1.5,
	};

	return newCover;
};

export const isElementOverlayingAnotherElement = (element: ElementEdit) => {
	return element.geo === "callout" || element.geo === "spotlight";
};

export const getElementProperties = (elements: ElementEdit[]) => {
	if (!elements || elements.length === 0) return;

	const validElements: ElementEdit[] = elements.filter(
		(ele) => ele.shapedata,
	);

	if (validElements.length === 0) return;

	// properties when there are overlaying elements should be taken from the first element
	const backgroundColor =
		validElements[0].shapedata?.backgroundColor ?? "black";
	const transitionTime = validElements[0].shapedata?.transitionTime ?? 0;
	const drawTime = validElements[0].shapedata?.drawTime ?? 1;

	const mergedStartTime = validElements[0].startTime;
	let mergedEndTime = validElements[0].endTime;
	let mergedOpacity = validElements[0].shapedata?.backgroundOpacity ?? 100;

	for (let i = 1; i < validElements.length; i++) {
		const currentElement = validElements[i];
		if (currentElement.startTime > mergedEndTime) {
			break;
		} else {
			mergedEndTime = Math.max(mergedEndTime, currentElement.endTime);
			mergedOpacity = Math.max(
				mergedOpacity,
				currentElement.shapedata?.backgroundOpacity ?? 100,
			);
		}
	}

	return {
		backgroundColor,
		backgroundOpacity: mergedOpacity,
		transitionTime,
		drawTime,
		startTime: mergedStartTime,
		endTime: mergedEndTime,
	};
};
