import {
	Crop,
	VideoEdits,
} from "@giga-user-fern/api/types/api/resources/video";

import { ElementEdit } from "@giga-user-fern/api/types/api";
import { hexToRGBA } from "../../../../../utils/colorUtils";
import { DEFAULT_CROP, dummyVideoEdits } from "../../../videoEditTypes/core";
import { interpolate } from "../../../video_effects/interpolations";
import {
	getElementProperties,
	isElementOverlayingAnotherElement,
	isElementWrtVideo,
} from "../../canvasUtils";
import { RendererType } from "../../constants";
import {
	DEFAULT_SUBTITLES,
	RendererSubtitle,
} from "../../elements/canvasSubtitles";
import { drawFilledRoundedRect } from "../../elements/canvasTextbox";
import { MutableElement } from "../../mutables/elements/MutableElement";
import { RendererSubtitles } from "../CanvasRenderer";
import { CanvasAssets } from "../assets/CanvasAssets";
import cc, { CanvasCoordinates } from "../coordinates/CanvasCoordinates";

type CanvasElementsProps = {
	canvas?: HTMLCanvasElement;
	tempCanvas?: HTMLCanvasElement;
	paused?: boolean;
};

//animation
const INTERPOLATION_METHOD = "easeOutCubic";
const ANIMATION_TIME = 0.3;
const TRANSITION_TYPE = "draw";

//formatting
export const PADDING_W = 30;
export const PADDING_H = 20;

export class CanvasElements {
	videoEdits: VideoEdits;
	subtitles?: RendererSubtitles;
	props: CanvasElementsProps;
	assets: CanvasAssets;
	coordinates: CanvasCoordinates;
	rendererType: RendererType;

	constructor(
		videoEdits: VideoEdits,
		props: CanvasElementsProps,
		assets: CanvasAssets,
		coordinates: CanvasCoordinates,
		rendererType: RendererType,
		subtitles?: RendererSubtitles,
	) {
		this.videoEdits = videoEdits;
		this.props = props;
		this.assets = assets;
		this.coordinates = coordinates;
		this.rendererType = rendererType;
		this.subtitles = subtitles;
	}

	getElementEditsToDraw(currentTime: number) {
		const elementEditsToDraw: ElementEdit[] = [];
		const { videoEdits } = this;

		for (const ele of videoEdits.elements ?? []) {
			if (currentTime >= ele.startTime && currentTime <= ele.endTime) {
				elementEditsToDraw.push(ele);
			}
		}

		elementEditsToDraw.sort((a, b) => {
			if (a.geo === "blur" && b.geo !== "blur") {
				return -1;
			}
			if (a.geo !== "blur" && b.geo === "blur") {
				return 1;
			}
			if (a.geo === "text" && b.geo !== "text") {
				return 1;
			}
			if (a.geo !== "text" && b.geo === "text") {
				return -1;
			}
			return 0;
		});

		elementEditsToDraw.sort((a, b) => {
			if (a.startTime !== b.startTime) {
				return a.startTime - b.startTime;
			}
			return a.endTime - b.endTime;
		});

		return elementEditsToDraw;
	}

	renderElements(currentTime: number, transition = false) {
		let elementEditsToDraw: ElementEdit[] = [];
		const overlayingElements: ElementEdit[] = [];

		elementEditsToDraw = this.getElementEditsToDraw(currentTime);

		if (elementEditsToDraw.length) {
			for (const ele of elementEditsToDraw) {
				if (isElementOverlayingAnotherElement(ele)) {
					overlayingElements.push(ele);
					continue;
				}

				this.drawElement(ele, currentTime, transition);
			}
		}

		if (overlayingElements.length) {
			this.prepareAndDrawOverlayingElements(
				overlayingElements,
				currentTime,
			);
		}
	}

	renderSubtitles(currentTime: number) {
		if (this.videoEdits.subtitlesSettings?.showSubtitles) {
			this.drawSubtitles(currentTime);
		}
	}

	private computeElementContainer(ele: ElementEdit): {
		rectWidth: number;
		rectHeight: number;
		rectX: number;
		rectY: number;
	} {
		const { position, size, geo } = ele;
		const [x, y] = position;
		const [width, height] = size;

		const { coordinates } = this;

		const wrtVideo = isElementWrtVideo(ele);

		const rect_coords = coordinates.fractionalCoordsToCanvasCoords(
			new MutableElement(ele).getPosition(),
			wrtVideo,
		);

		const rectX = rect_coords.x;
		const rectY = rect_coords.y;

		let rectWidth = 0;
		let rectHeight = 0;

		if (wrtVideo) {
			rectWidth = width * coordinates.videoRenderWidth; ///adj_zf
			rectHeight = height * coordinates.videoRenderHeight; // / adj_zf;
		} else {
			rectWidth = width * coordinates.canvasWidth; ///adj_zf
			rectHeight = height * coordinates.canvasHeight; // / adj_zf;
		}

		if (geo === "text") {
			rectWidth += PADDING_W;
			rectHeight += PADDING_H;
		}

		return {
			rectWidth,
			rectHeight,
			rectX,
			rectY,
		};
	}

	private calculateCurrentOpacity(
		elapsedTime: number,
		totalDuration: number,
		transitionTime: number,
		backgroundOpacity: number,
		isPaused?: boolean,
		drawTime = 1,
		delayTime = 0.5,
	): number {
		if (isPaused) {
			return backgroundOpacity / 100;
		}

		let currentOpacity = 0;

		if (elapsedTime < drawTime) {
			currentOpacity = 0;
		} else if (elapsedTime < drawTime + delayTime) {
			currentOpacity = 0;
		} else if (elapsedTime < drawTime + delayTime + transitionTime) {
			const scaleProgress =
				(elapsedTime - drawTime - delayTime) / transitionTime;
			currentOpacity = scaleProgress * (backgroundOpacity / 100);
		} else if (elapsedTime > totalDuration - transitionTime) {
			const exitProgress = (totalDuration - elapsedTime) / transitionTime;
			currentOpacity = exitProgress * (backgroundOpacity / 100);
		} else {
			currentOpacity = backgroundOpacity / 100;
		}

		return Math.max(0, Math.min(currentOpacity, backgroundOpacity / 100));
	}

	private applyBackgroundOverlay(
		ctx: CanvasRenderingContext2D,
		canvas: HTMLCanvasElement,
		backgroundColor: string,
		opacity: number,
	) {
		ctx.fillStyle = backgroundColor;
		ctx.globalAlpha = opacity;
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		// Reset alpha after fill
		ctx.globalAlpha = 1;
	}

	private drawElement(
		ele: ElementEdit,
		currentTime: number,
		transition = false,
	) {
		const { canvas } = this.props;
		if (!canvas) return;

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
		ctx.save();

		switch (ele.geo) {
			case "blur":
				this.drawBlur(ele, currentTime);
				break;
			case "rectangle":
				this.drawRectangle(ele, currentTime);
				break;
			case "text":
				this.drawText(ele, currentTime, transition);
				break;
			case "arrow":
				this.drawArrow(ele, currentTime);
				break;
			case "image":
				this.drawImage(ele, currentTime);
				break;
		}

		ctx.restore();
	}

	private prepareAndDrawOverlayingElements(
		overlayingElements: ElementEdit[],
		currentTime: number,
	) {
		const { canvas } = this.props;
		if (!canvas) return;

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
		if (!ctx) return;

		// reset the zooms on the main canvas because we are using the offscreen canvas to draw the elements
		const transform = ctx.getTransform();
		ctx.resetTransform();

		const offscreenCanvas = this.assets.createCanvas(
			canvas.width,
			canvas.height,
		);
		const overlayCanvas = this.assets.createCanvas(
			canvas.width,
			canvas.height,
		);

		const offscreenCtx = offscreenCanvas.getContext(
			"2d",
		) as CanvasRenderingContext2D;

		const overlayCtx = overlayCanvas.getContext(
			"2d",
		) as CanvasRenderingContext2D;

		// apply the zooms to the offscreen canvas
		offscreenCtx.setTransform(transform);

		// get the properties that are supposed to be followed in the case of transitive elements
		// the idea is to take the first one and seamlessly apply it to all until that element ends
		const elementProperties = getElementProperties(overlayingElements);

		if (!elementProperties) return;

		const {
			transitionTime = 0,
			drawTime = 1,
			backgroundColor = "black",
		} = elementProperties;

		const isPaused = this.props?.paused;

		let maxOpacity: number | undefined = undefined;

		// we do this for opacity (we calculate the max at first and keep that throughout)
		for (const ele of overlayingElements) {
			if (!ele.shapedata) continue;

			const { shapedata, startTime, endTime, geo } = ele;

			const { backgroundOpacity = 0 } = shapedata;

			const elapsedTime = currentTime - startTime;
			const totalDuration = endTime - startTime;

			const currentOpacity = this.calculateCurrentOpacity(
				elapsedTime,
				totalDuration,
				transitionTime,
				backgroundOpacity,
				isPaused,
				geo === "spotlight" ? 0 : drawTime,
				geo === "spotlight" ? 0 : 0.5,
			);

			maxOpacity = Math.max(currentOpacity, maxOpacity || 0);
		}

		// Ensure maxOpacity has a valid value (default to 0 if no elements contribute)
		if (maxOpacity === undefined) {
			maxOpacity = 0;
		}

		// sets the background opacity over the main canvas
		this.applyBackgroundOverlay(
			overlayCtx,
			overlayCanvas,
			backgroundColor,
			maxOpacity,
		);

		overlayCtx.globalCompositeOperation = "destination-out";
		overlayCtx.globalAlpha = 1;

		// preparing the cutouts for the elements
		for (const ele of overlayingElements) {
			if (!ele.shapedata) continue;

			const { shapedata, startTime, endTime, geo } = ele;
			const {
				borderRadius = 0,
				transitionTime = 0,
				scaleFactor = 1.5,
				drawTime = 1,
			} = shapedata;

			const actualBorderRadius = Math.max(borderRadius, 0);
			const maxScale = scaleFactor;
			const scaleRange = maxScale - 1;

			const { rectX, rectY, rectWidth, rectHeight } =
				this.computeElementContainer(ele);

			const elapsedTime = currentTime - startTime;
			const totalDuration = endTime - startTime;
			const isPaused = this.props?.paused;
			const delayTime = geo === "spotlight" ? 0 : 0.5;

			let currentScale: number;

			// the overlay cutouts are also supposed to be scaled in the case of callouts
			// notice how there is no stroking logic written here.
			if (isPaused || geo === "spotlight") {
				currentScale = 1;
			} else {
				if (elapsedTime < drawTime) {
					currentScale = 1;
				} else if (elapsedTime < drawTime + delayTime) {
					currentScale = 1;
				} else if (
					elapsedTime <
					drawTime + delayTime + transitionTime
				) {
					const scaleProgress =
						(elapsedTime - drawTime - delayTime) / transitionTime;
					currentScale = 1 + scaleRange * scaleProgress;
				} else if (elapsedTime > totalDuration - transitionTime) {
					const exitProgress =
						(totalDuration - elapsedTime) / transitionTime;
					currentScale = 1 + scaleRange * exitProgress;
				} else {
					currentScale = maxScale;
				}
			}

			const scaledWidth = rectWidth * currentScale;
			const scaledHeight = rectHeight * currentScale;

			const scaledX = rectX - (scaledWidth - rectWidth) / 2;
			const scaledY = rectY - (scaledHeight - rectHeight) / 2;

			const adjustedPosition = this.clampPosition(
				scaledX,
				scaledY,
				scaledWidth,
				scaledHeight,
			);

			const { x: adjustedX, y: adjustedY } = adjustedPosition;

			const radius =
				(actualBorderRadius / 100) *
				Math.min(scaledWidth, scaledHeight);

			const perimeter = 2 * (scaledWidth + scaledHeight);

			overlayCtx.beginPath();

			// this is the actual cutout for the element
			this.drawRoundedRectPath(
				overlayCtx,
				adjustedX,
				adjustedY,
				scaledWidth,
				scaledHeight,
				radius,
				perimeter,
			);

			overlayCtx.fill();
		}

		overlayCtx.globalCompositeOperation = "source-over";

		// draw the cutouts onto the offscreen canvas
		offscreenCtx.drawImage(overlayCanvas, 0, 0);

		// draw the elements onto the offscreen canvas which now has the cutouts that scale
		for (const ele of overlayingElements) {
			// this is the actual logic for drawing elements
			this.drawCalloutAndSpotlight(
				ele,
				currentTime,
				offscreenCanvas,
				ele.geo === "spotlight",
			);
		}
	}

	private drawCalloutAndSpotlight(
		ele: ElementEdit,
		currentTime: number,
		tempCanvas: HTMLCanvasElement,
		isSpotlight: boolean,
	) {
		const { canvas } = this.props;
		if (!canvas || !tempCanvas) return;

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
		if (!ctx) return;

		const tempCtx = tempCanvas.getContext("2d") as CanvasRenderingContext2D;

		const { shapedata, startTime, endTime } = ele;
		if (!shapedata) return;

		const {
			borderRadius = 0,
			transitionTime = shapedata.transitionTime ?? 0,
			scaleFactor = 1.5,
			strokeWidth = 2,
			strokeColor = "white",
			drawTime = shapedata.drawTime ?? 1,
			backgroundOpacity = 0,
		} = shapedata;

		const actualBorderRadius = Math.max(borderRadius, 0);
		const maxScale = scaleFactor;
		const scaleRange = scaleFactor - 1;

		const { rectX, rectY, rectWidth, rectHeight } =
			this.computeElementContainer(ele);

		const elapsedTime = currentTime - startTime;
		const totalDuration = endTime - startTime;

		const delayTime = isSpotlight ? 0 : 0.5;

		let currentOpacity = 0;
		let currentScale = 1;
		let strokeProgress = 1;
		let strokeOpacity = 1;

		const isPaused = this.props?.paused;

		if (isSpotlight || (isPaused && ele.geo === "spotlight")) {
			currentOpacity = backgroundOpacity / 100;
			currentScale = 1;
			strokeProgress = 0;
			strokeOpacity = 0;
		} else if (isPaused && !isSpotlight) {
			strokeProgress = 1;
			currentScale = 1;
			currentOpacity = backgroundOpacity / 100;
			strokeOpacity = 1;
		} else {
			if (elapsedTime < drawTime) {
				strokeProgress = elapsedTime / drawTime;
				currentOpacity = 0;
				currentScale = 1;
				strokeOpacity = strokeProgress;
			} else if (elapsedTime < drawTime + delayTime) {
				strokeProgress = 1;
				currentOpacity = 0;
				currentScale = 1;
				strokeOpacity = 1;
			} else if (elapsedTime < drawTime + delayTime + transitionTime) {
				strokeProgress = 1;
				const scaleProgress =
					(elapsedTime - drawTime - delayTime) / transitionTime;
				currentOpacity = scaleProgress * (backgroundOpacity / 100);
				currentScale = 1 + scaleRange * scaleProgress;
				strokeOpacity = 1;
			} else if (elapsedTime > totalDuration - transitionTime) {
				strokeProgress = 1;
				const exitProgress =
					(totalDuration - elapsedTime) / transitionTime;
				currentOpacity = exitProgress * (backgroundOpacity / 100);
				currentScale = 1 + scaleRange * exitProgress;
				strokeOpacity = exitProgress;
			} else {
				strokeProgress = 1;
				currentOpacity = backgroundOpacity / 100;
				currentScale = maxScale;
				strokeOpacity = 1;
			}
		}

		currentOpacity = Math.max(
			0,
			Math.min(currentOpacity, backgroundOpacity / 100),
		);

		const scaledWidth = rectWidth * currentScale;
		const scaledHeight = rectHeight * currentScale;
		const scaledX = rectX - (scaledWidth - rectWidth) / 2;
		const scaledY = rectY - (scaledHeight - rectHeight) / 2;

		const adjustedPosition = this.clampPosition(
			scaledX,
			scaledY,
			scaledWidth,
			scaledHeight,
		);
		const { x: adjustedX, y: adjustedY } = adjustedPosition;

		tempCtx.strokeStyle = strokeColor;
		tempCtx.lineWidth = strokeWidth;
		tempCtx.lineCap = "round";
		tempCtx.globalAlpha = strokeOpacity;

		const radius =
			(actualBorderRadius / 100) * Math.min(scaledWidth, scaledHeight);
		const perimeter = 2 * (scaledWidth + scaledHeight);
		const progressLength = perimeter * strokeProgress;

		tempCtx.beginPath();

		// drawing the stroke
		this.drawRoundedRectPath(
			tempCtx,
			adjustedX,
			adjustedY,
			scaledWidth,
			scaledHeight,
			radius,
			progressLength,
		);
		tempCtx.stroke();

		tempCtx.globalCompositeOperation = "source-over";
		tempCtx.globalAlpha = 1;

		tempCtx.save();
		tempCtx.beginPath();

		this.drawRoundedRectPath(
			tempCtx,
			adjustedX,
			adjustedY,
			scaledWidth,
			scaledHeight,
			radius,
			perimeter,
		);

		// Clip the area before drawing
		tempCtx.clip();

		// Apply transform to the source rectangle
		// notice how this is taking the transform from the tempCanvas which has the zooms applied and not the main canvas
		// this is because the main canvas is not being used to draw the elements
		const transform = tempCtx.getTransform();

		const transformedRectX =
			transform.a * rectX + transform.c * rectY + transform.e;
		const transformedRectY =
			transform.b * rectX + transform.d * rectY + transform.f;

		tempCtx.drawImage(
			canvas,
			transformedRectX,
			transformedRectY,
			rectWidth * transform.a,
			rectHeight * transform.d,
			adjustedX,
			adjustedY,
			scaledWidth,
			scaledHeight,
		);

		tempCtx.restore();

		ctx.drawImage(tempCanvas, 0, 0);
	}

	private drawBlur(ele: ElementEdit, currentTime: number) {
		const { tempCanvas, canvas } = this.props;
		const { position, size, geo } = ele;

		const { videoEdits } = this;

		const [x, y] = position;
		const [width, height] = size;
		// These are the coordinates of the element in the video
		// With respect to rendered dimensions of the video.
		// That is, these video coordinates will also factor in the crop.

		const crop: Crop = videoEdits.crop || DEFAULT_CROP;

		// These are the coordinates of the rectangle with respect to the full canvas.

		const { rectX, rectY, rectWidth, rectHeight } =
			this.computeElementContainer(ele);

		if (!tempCanvas || !canvas) {
			console.error("canvas unavailable to render blur");
			return;
		}

		// We are going to create a temp canvas to draw the rectangle on.
		// We will then apply the blur filter to the temp canvas and draw it back onto the original canvas.
		const tempCtx = tempCanvas.getContext("2d");
		// Reset transformations on the temp canvas
		const ctx = canvas.getContext("2d");

		if (!tempCtx || !ctx) return;

		// The actual content to draw onto this temp canvas is going to be a rectangle from the base video
		// For that, it is being rendered at its natural width and height.

		// TODO: Instead of rendering at natural width and height, we can render at a reduced size
		// This size will later be renderWidth and renderHeight.
		const naturalWidth = this.assets.screenclip.naturalWidth;
		const naturalHeight = this.assets.screenclip.naturalHeight;

		// But first, we need to get the offset potentially by the crop in the video
		// Coordinates are all with respect to entire video
		// So, crop is factored externally.

		let sx = 0; // Source x - start of crop in video coordinates
		let sy = 0; // Source y - start of crop in video coordinates
		let sWidth = naturalWidth; // Source width - width of crop in video coordinates
		let sHeight = naturalHeight; // Source height - height of crop in video coordinates
		if (videoEdits.crop) {
			sx = crop.position[0] * naturalWidth; // Source x - start of crop in video coordinates
			sy = crop.position[1] * naturalHeight; // Source y - start of crop in video coordinates
			sWidth = crop.size[0] * naturalWidth; // Source width - width of crop in video coordinates
			sHeight = crop.size[1] * naturalHeight; // Source height - height of crop in video coordinates
		}

		// Now the part of the frame we're interested in drawing is determined by these widths and heights
		const frameRectWidth = width * sWidth;
		const frameRectHeight = height * sHeight;

		// Adding the crop offset to the x and y coordinates to account for the crop
		// Since the frame is being drawn from the base video, the crop offset is added to the x and y coordinates
		const frameRectX = sx + x * sWidth;
		const frameRectY = sy + y * sHeight;

		// Set the dimensions of the temp canvas to the size of the rectangle

		tempCanvas.width = rectWidth;
		tempCanvas.height = rectHeight;

		// draw some colour onto the temp canvas
		// tempCtx.fillStyle = "red";
		// tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

		// // Now, we factor in the blur radius
		// The blur radius needs to be scaled according to the size of the base video
		// This is because the visual effect of a blur with radius 20px on a video that is say 1600x900 px
		// is different from the same blur on a video that is say 800x450 px
		// To scale it, we can just assume some constant factor and multiply by the size of the video
		// THe scale should be with square root of the area of the video, and not area because then it
		// will be too much for larger videos
		//TODO: Make this a variable that can be set by the user
		const blurRadius =
			0.03 *
			Math.sqrt(naturalWidth * naturalHeight) *
			(this.rendererType === "skia" ? 2 : 1);

		// Ideally, we want to shift the top left by (blurRadius, blurRadius)
		// But, we may not be able to do that because the rectangle may be too close to the edge of the video
		// So the actual shift will be the minimum of the two values
		const leftBlurRadius = Math.min(blurRadius, frameRectX);
		const topBlurRadius = Math.min(blurRadius, frameRectY);

		const rightBlurRadius = Math.min(
			blurRadius,
			naturalWidth - (frameRectWidth + frameRectX),
		);
		const bottomBlurRadius = Math.min(
			blurRadius,
			naturalHeight - (frameRectHeight + frameRectY),
		);

		// We need to scale the blur radius to account for the fact that the rectangle is being drawn at a different size
		// This is because the blur radius is being added to the rectangle that's scaled according to video,
		// not the part in the video canvas
		// So we cannot add directly the blur radius - we need to scale it according to the
		// size of the rectangle in the video canvas

		const scaleFactor = rectWidth / frameRectWidth;

		tempCanvas.width =
			rectWidth +
			leftBlurRadius * scaleFactor +
			rightBlurRadius * scaleFactor;
		tempCanvas.height =
			rectHeight +
			topBlurRadius * scaleFactor +
			bottomBlurRadius * scaleFactor;

		tempCtx.filter = `blur(${(blurRadius / 3) * scaleFactor}px)`; // adjust the blur radius as needed
		tempCtx.drawImage(
			this.assets.screenclip.frame(currentTime), //use 'canvas' in case  of rendering full
			// this.assets.screenclip.frame(currentTime),
			frameRectX - leftBlurRadius,
			frameRectY - topBlurRadius,
			frameRectWidth + leftBlurRadius + rightBlurRadius,
			frameRectHeight + topBlurRadius + bottomBlurRadius,
			0,
			0,
			tempCanvas.width,
			tempCanvas.height,
		);

		// // Apply the blur filter
		// tempCtx.drawImage(tempCanvas, 0, 0);

		// Now to draw the blurred rectangle back onto the original canvas
		// We are drawing the blurred rectangle at the same position as the original rectangle
		// But with the blur radius added to the x and y coordinates to account for the blur effect
		ctx.drawImage(
			tempCanvas,
			leftBlurRadius * scaleFactor,
			topBlurRadius * scaleFactor,
			rectWidth,
			rectHeight,
			rectX,
			rectY,
			rectWidth,
			rectHeight,
		);
	}

	private drawRectangle(ele: ElementEdit, currentTime: number) {
		let stroke_width = 0;
		let stroke_color = "transparent";
		let fill_color = "transparent";
		let borderRadius = 0;
		let backgroundOpacity = 1;
		const strokeOpacity = 1;
		let drawTime = ANIMATION_TIME;
		let transitionTime = ANIMATION_TIME;
		let transitionType = TRANSITION_TYPE;
		let strokeProgress = 0;
		const isPaused = this.props?.paused;
		const { canvas } = this.props;
		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		const { rectWidth, rectHeight, rectX, rectY } =
			this.computeElementContainer(ele);

		if (ele.shapedata) {
			stroke_width = ele.shapedata.strokeWidth || 0;
			stroke_color = ele.shapedata.strokeColor || "transparent";
			fill_color = ele.shapedata.backgroundColor || "transparent";
			backgroundOpacity =
				ele.shapedata.backgroundOpacity === -0.01
					? 0
					: (ele.shapedata.backgroundOpacity ?? 100) / 100;
			borderRadius = Math.max(ele.shapedata.borderRadius || 0, 0);
			drawTime = ele.shapedata.drawTime || 0;
			transitionTime = ele.shapedata.transitionTime || 0;
			transitionType = ele.shapedata.transitionType || TRANSITION_TYPE;
		}

		const t = currentTime;

		// Compute the opacity only for fade transition

		// maxOpacity is the maximum opacity of the rectangle that can be set by the user
		const maxOpacity = (ele.shapedata?.backgroundOpacity ?? 100) / 100;

		if (transitionType === "fade") {
			if (t > ele.startTime && t <= ele.startTime + transitionTime) {
				const start = { y: 0, x: ele.startTime };
				const end = {
					y: maxOpacity,
					x: ele.startTime + transitionTime,
				};
				backgroundOpacity = isPaused
					? maxOpacity
					: interpolate(start, end, t, INTERPOLATION_METHOD);
			} else if (t > ele.endTime - transitionTime) {
				const start = {
					y: maxOpacity,
					x: ele.endTime - transitionTime,
				};
				const end = { y: 0, x: ele.endTime };
				backgroundOpacity = isPaused
					? maxOpacity
					: interpolate(start, end, t, INTERPOLATION_METHOD);
			}
		}

		// Compute stroke progress only for draw transition
		if (transitionType === "draw") {
			if (t > ele.startTime && t <= ele.startTime + drawTime) {
				const start = { y: 0, x: ele.startTime };
				const end = { y: 1, x: ele.startTime + drawTime };
				const bgEnd = {
					y: maxOpacity,
					x: ele.startTime + drawTime,
				};
				backgroundOpacity = isPaused
					? maxOpacity
					: interpolate(start, bgEnd, t, INTERPOLATION_METHOD);

				strokeProgress = interpolate(
					start,
					end,
					t,
					INTERPOLATION_METHOD,
				);
			} else if (t > ele.startTime + drawTime) {
				const start = {
					y: maxOpacity,
					x: ele.endTime - drawTime,
				};
				const bgEnd = { y: 0, x: ele.endTime };
				backgroundOpacity = isPaused
					? maxOpacity
					: interpolate(start, bgEnd, t, INTERPOLATION_METHOD);

				strokeProgress = 1;
			}
		}

		ctx.save();

		// Begin path for rounded rectangle
		ctx.beginPath();

		this.drawRoundedRectPath(
			ctx,
			rectX,
			rectY,
			rectWidth,
			rectHeight,
			(borderRadius * Math.min(rectWidth, rectHeight)) / 100,
			2 * (rectWidth + rectHeight),
		);

		// Fill rectangle with opacity

		const numericOpacity = Math.max(
			0,
			Math.min(1, Number(backgroundOpacity) || 0),
		);

		ctx.globalAlpha = numericOpacity;
		ctx.fillStyle = fill_color;
		ctx.fill();

		// Reset globalAlpha for stroke
		ctx.globalAlpha = 1;

		// Stroke rectangle with animation
		if (stroke_width > 0) {
			ctx.strokeStyle = stroke_color;
			ctx.lineWidth = stroke_width;
			ctx.lineCap = "round";

			if (transitionType === "draw") {
				// Calculate perimeter and border radius
				const perimeter = 2 * (rectWidth + rectHeight);
				let progressLength = 0;

				// Calculate progress length based on whether we're in entrance or exit animation
				if (t > ele.startTime && t <= ele.startTime + drawTime) {
					// Entrance animation
					const start = { y: 0, x: ele.startTime };
					const end = { y: 1, x: ele.startTime + drawTime };
					progressLength =
						perimeter *
						interpolate(start, end, t, INTERPOLATION_METHOD);
					if (isPaused) {
						progressLength = Math.max(progressLength, perimeter);
					}
				} else if (t > ele.endTime - drawTime && t <= ele.endTime) {
					// Exit animation
					const start = { y: 1, x: ele.endTime - drawTime };
					const end = { y: 0, x: ele.endTime };
					progressLength =
						perimeter *
						interpolate(start, end, t, INTERPOLATION_METHOD);
					if (isPaused) {
						progressLength = Math.max(progressLength, perimeter);
					}
				} else if (
					t > ele.startTime + drawTime &&
					t <= ele.endTime - drawTime
				) {
					// Fully drawn
					progressLength = perimeter;
				} else {
					// Not visible
					progressLength = 0;
				}

				const radius =
					(borderRadius * Math.min(rectWidth, rectHeight)) / 100;
				ctx.globalAlpha = 1;

				ctx.beginPath();
				let remainingLength = progressLength;

				// Start from top-left corner + radius
				ctx.moveTo(rectX + radius, rectY);

				// Draw top line
				if (remainingLength > 0) {
					const topLength = Math.min(
						rectWidth - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(rectX + topLength + radius, rectY);
					remainingLength -= topLength;

					// Draw top-right corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + rectWidth - radius,
							rectY + radius,
							radius,
							-Math.PI / 2,
							-Math.PI / 2 + arcProgress / radius,
							false,
						);
						remainingLength -= arcProgress;
					}
				}

				// Draw right line
				if (remainingLength > 0) {
					const rightLength = Math.min(
						rectHeight - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(rectX + rectWidth, rectY + rightLength + radius);
					remainingLength -= rightLength;

					// Draw bottom-right corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + rectWidth - radius,
							rectY + rectHeight - radius,
							radius,
							0,
							arcProgress / radius,
							false,
						);
						remainingLength -= arcProgress;
					}
				}

				// Draw bottom line
				if (remainingLength > 0) {
					const bottomLength = Math.min(
						rectWidth - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(
						rectX + rectWidth - bottomLength - radius,
						rectY + rectHeight,
					);
					remainingLength -= bottomLength;

					// Draw bottom-left corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + radius,
							rectY + rectHeight - radius,
							radius,
							Math.PI / 2,
							Math.PI / 2 + arcProgress / radius,
							false,
						);
						remainingLength -= arcProgress;
					}
				}

				// Draw left line
				if (remainingLength > 0) {
					const leftLength = Math.min(
						rectHeight - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(rectX, rectY + rectHeight - leftLength - radius);
					remainingLength -= leftLength;

					// Draw top-left corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + radius,
							rectY + radius,
							radius,
							Math.PI,
							Math.PI + arcProgress / radius,
							false,
						);
					}
				}
				ctx.stroke();
			} else if (transitionType === "fade") {
				// For fade transition, animate stroke opacity from 0 to 1
				let strokeAlpha = 1;
				if (!isPaused) {
					if (
						t > ele.startTime &&
						t <= ele.startTime + transitionTime
					) {
						strokeAlpha = interpolate(
							{ y: 0, x: ele.startTime },
							{ y: 1, x: ele.startTime + transitionTime },
							t,
							INTERPOLATION_METHOD,
						);
					} else if (
						t > ele.endTime - transitionTime &&
						t <= ele.endTime
					) {
						strokeAlpha = interpolate(
							{ y: 1, x: ele.endTime - transitionTime },
							{ y: 0, x: ele.endTime },
							t,
							INTERPOLATION_METHOD,
						);
					}
				}
				ctx.globalAlpha = strokeAlpha;
				ctx.stroke();
			} else {
				ctx.stroke();
			}
		}

		ctx.restore();
	}

	private splitTextIntoLines(
		ctx: CanvasRenderingContext2D,
		text: string,
		maxWidth: number,
	): string[] {
		// Check if the text contains CJK characters (Chinese, Japanese, Korean)
		const hasCJK =
			/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(
				text,
			);

		if (hasCJK) {
			// For CJK text, split character by character
			const lines: string[] = [];
			let currentLine = "";

			for (let i = 0; i < text.length; i++) {
				const char = text[i];
				const testLine = currentLine + char;
				const metrics = ctx.measureText(testLine);

				if (metrics.width > maxWidth && currentLine !== "") {
					lines.push(currentLine);
				} else {
					currentLine = testLine;
				}
			}
			if (currentLine) {
				lines.push(currentLine);
			}
			return lines;
		} else {
			// For non-CJK text, use word-based splitting
			const words = text.split(" ");
			const lines: string[] = [];
			let currentLine = words[0];

			for (let i = 1; i < words.length; i++) {
				const testLine = currentLine + " " + words[i];
				const metrics = ctx.measureText(testLine);

				if (metrics.width > maxWidth) {
					lines.push(currentLine);
					currentLine = words[i];
				} else {
					currentLine = testLine;
				}
			}
			lines.push(currentLine);
			return lines;
		}
	}

	private drawSubtitles(currentTime: number) {
		const { canvas } = this.props;
		const ctx = canvas?.getContext("2d");

		if (!ctx || !canvas || !this.subtitles) return;

		const parsedSubtitles = this.subtitles.subtitles;

		// Reset context transformations
		ctx.setTransform(1, 0, 0, 1, 0, 0);
		ctx.save();

		const { coordinates } = this;

		let currentSubtitle: RendererSubtitle | null = null;
		let nextSubtitle: RendererSubtitle | null = null;

		for (let i = 0; i < parsedSubtitles.length; i++) {
			if (
				currentTime >= parsedSubtitles[i].startTime &&
				currentTime <= parsedSubtitles[i].endTime
			) {
				currentSubtitle = parsedSubtitles[i];
				nextSubtitle = parsedSubtitles[i + 1] || null;
				break;
			}
		}

		if (!currentSubtitle) {
			ctx.restore();
			return;
		}

		// Animation settings
		const ANIMATION_DURATION = 0.3;
		const SLIDE_DISTANCE = -30;

		// Calculate animation progress

		let elapsedTime: number = ANIMATION_DURATION;

		if (currentTime < currentSubtitle.startTime + ANIMATION_DURATION) {
			//entry animation
			elapsedTime = currentTime - currentSubtitle.startTime;
		} else if (currentTime > currentSubtitle.endTime - ANIMATION_DURATION) {
			// Only do exit animation if there's a gap between subtitles
			if (
				nextSubtitle &&
				nextSubtitle.startTime > currentSubtitle.endTime
			) {
				elapsedTime = currentSubtitle.endTime - currentTime;
			}
		}

		const opacity = interpolate(
			{ x: 0, y: 0.7 },
			{ x: ANIMATION_DURATION, y: 1 },
			elapsedTime,
			"easeOutCubic",
		);

		//SLIDE OFFSET
		const slideOffset = Math.min(
			interpolate(
				{ x: 0, y: SLIDE_DISTANCE },
				{ x: ANIMATION_DURATION, y: 0 },
				elapsedTime,
				"easeOutCubic",
			),
			0,
		);

		// Text settings
		const fontSize =
			this.videoEdits.subtitlesSettings?.size ?? DEFAULT_SUBTITLES.size;
		const padding = fontSize * 0.35;

		ctx.font = `${fontSize}px Arial`;
		ctx.fillStyle = "white";
		ctx.textAlign = "center";
		ctx.textBaseline = "middle";

		// Calculate maximum width for text (with some margin from screen edges)
		const screenMargin = 40; // pixels from edge of screen
		const maxTextWidth = coordinates.canvasWidth - screenMargin * 2;

		// Split text into lines that fit within maxTextWidth
		const originalLines = currentSubtitle.text.split("\n");
		const lines: string[] = [];

		// Process each original line and split if necessary
		for (const originalLine of originalLines) {
			const metrics = ctx.measureText(originalLine);
			if (metrics.width > maxTextWidth) {
				// Line is too wide, split it
				const splitLines = this.splitTextIntoLines(
					ctx,
					originalLine,
					maxTextWidth,
				);
				lines.push(...splitLines);
			} else {
				lines.push(originalLine);
			}
		}

		const lineHeight = fontSize * 1.2;
		const textHeight = lineHeight * lines.length;

		// Find the widest line after splitting
		let maxWidth = 0;
		for (const line of lines) {
			const metrics = ctx.measureText(line);
			maxWidth = Math.max(maxWidth, metrics.width);
		}

		// Rectangle settings

		const rectHeight = textHeight + padding * 2;
		const baseX = coordinates.canvasWidth / 2;

		// Ensure the subtitle doesn't go below screen
		const maxY = coordinates.canvasHeight - screenMargin;
		const minY = screenMargin;
		const baseY = Math.min(
			maxY - (padding + textHeight / 2),
			Math.max(
				minY + rectHeight / 2,
				coordinates.canvasHeight - (padding + textHeight + 50),
			),
		);

		// Calculate animated Y position (slide up effect)

		const animatedY = baseY + slideOffset;

		// Draw each line with its own background rectangle
		for (let i = 0; i < lines.length; i++) {
			const line = lines[i];
			const metrics = ctx.measureText(line);
			const lineWidth = metrics.width;
			const rectWidth = lineWidth + padding * 2;
			const rectHeight = lineHeight + padding;
			const cornerRadius = Math.min(rectHeight / 2.4, 16);
			const lineSpacing = lineHeight * 0.5; // Additional spacing between lines
			const lineY =
				baseY -
				textHeight / 2 +
				i * (lineHeight + lineSpacing) + // Added lineSpacing here
				lineHeight / 2 -
				slideOffset;

			// Draw background rectangle for this line
			ctx.globalAlpha = opacity;
			ctx.fillStyle = "rgba(0, 0, 0, 0.75)";
			ctx.beginPath();
			this.drawRoundedRectPath(
				ctx,
				baseX - rectWidth / 2,
				lineY - rectHeight / 2,
				rectWidth,
				rectHeight,
				cornerRadius,
				2 * (rectWidth + rectHeight),
			);
			ctx.fill();

			// Draw text
			ctx.fillStyle = "rgba(255, 255, 255)";
			ctx.fillText(line, baseX, lineY);
		}
		ctx.restore();
	}

	private drawRoundedRectPath(
		ctx: CanvasRenderingContext2D,
		x: number,
		y: number,
		width: number,
		height: number,
		radius: number,
		progressLength: number,
	) {
		let remainingLength = progressLength;

		// make sure the radius is not negative
		const actualRadius = Math.max(radius, 0);

		// Start from top-left corner
		ctx.moveTo(x + actualRadius, y);

		// Top side
		if (remainingLength > 0) {
			const sideLength = Math.min(
				width - 2 * actualRadius,
				remainingLength,
			);
			ctx.lineTo(x + sideLength + actualRadius, y);
			remainingLength -= sideLength;

			// Top-right corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(actualRadius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + width - actualRadius,
					y + actualRadius,
					actualRadius,
					-Math.PI / 2,
					-Math.PI / 2 + cornerLength / actualRadius,
					false,
				);
				remainingLength -= cornerLength;
			}
		}

		// Right side
		if (remainingLength > 0) {
			const sideLength = Math.min(
				height - 2 * actualRadius,
				remainingLength,
			);
			ctx.lineTo(x + width, y + sideLength + actualRadius);
			remainingLength -= sideLength;

			// Bottom-right corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(actualRadius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + width - actualRadius,
					y + height - actualRadius,
					actualRadius,
					0,
					cornerLength / actualRadius,
					false,
				);
				remainingLength -= cornerLength;
			}
		}

		// Bottom side
		if (remainingLength > 0) {
			const sideLength = Math.min(
				width - 2 * actualRadius,
				remainingLength,
			);
			ctx.lineTo(x + width - sideLength - actualRadius, y + height);
			remainingLength -= sideLength;

			// Bottom-left corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(actualRadius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + actualRadius,
					y + height - actualRadius,
					actualRadius,
					Math.PI / 2,
					Math.PI / 2 + cornerLength / actualRadius,
					false,
				);
				remainingLength -= cornerLength;
			}
		}

		// Left side
		if (remainingLength > 0) {
			const sideLength = Math.min(
				height - 2 * actualRadius,
				remainingLength,
			);
			ctx.lineTo(x, y + height - sideLength - actualRadius);
			remainingLength -= sideLength;

			// Top-left corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(actualRadius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + actualRadius,
					y + actualRadius,
					actualRadius,
					Math.PI,
					Math.PI + cornerLength / actualRadius,
					false,
				);
			}
		}
	}

	private drawLine(
		context: CanvasRenderingContext2D,
		fromx: number,
		fromy: number,
		tox: number,
		toy: number,
		offsets: [number, number, number][],
		strokeColor: string,
	) {
		// draw the line with the offsets to have a natural stroke effect
		for (const offset of offsets) {
			const [offsetX, offsetY, lineWidth] = offset;

			context.beginPath();
			context.moveTo(fromx + offsetX, fromy + offsetY);
			context.lineTo(tox + offsetX, toy + offsetY);

			context.strokeStyle = strokeColor;
			context.lineWidth = lineWidth;
			context.stroke();
		}
	}

	private generateOffsets(
		points: number,
		radius: number,
	): [number, number, number][] {
		const offsets: [number, number, number][] = [];

		// generate the offsets for the line to be drawn
		for (let i = 0; i < points; i++) {
			offsets.push([
				(Math.random() * (-1) ** i * radius) / 1.2,
				(Math.random() * (-1) ** i * radius) / 1.2,
				radius,
			]);
		}
		return offsets;
	}

	private makeArrowOnCanvas = (
		context: CanvasRenderingContext2D,
		fromx: number,
		fromy: number,
		tox: number,
		toy: number,
		width = 8,
		strokeColor = "black",
	) => {
		const points = 500;
		const radius = width;

		// set context stroke style and round
		context.strokeStyle = strokeColor;
		context.lineCap = "round";
		context.lineJoin = "round";

		const offsets = this.generateOffsets(points, radius);

		// Draw arrow body
		this.drawLine(context, fromx, fromy, tox, toy, offsets, strokeColor);

		// Calculate arrow head
		const angle = Math.atan2(toy - fromy, tox - fromx);
		const headlen = width * 10;

		// Draw arrow head with brush stroke effect
		const headPoints = 450;
		const headOffsets = this.generateOffsets(headPoints, width);

		// calculate the left and right positions of the arrow head (arrow head will be drawn at the end of the line and in two directions)
		// first direction of the arrow head
		const leftX = tox - headlen * Math.cos(angle - Math.PI / 6);
		const leftY = toy - headlen * Math.sin(angle - Math.PI / 6);

		// second direction of the arrow head
		const rightX = tox - headlen * Math.cos(angle + Math.PI / 6);
		const rightY = toy - headlen * Math.sin(angle + Math.PI / 6);

		// draw the arrow head in two directions
		this.drawLine(
			context,
			tox,
			toy,
			leftX,
			leftY,
			headOffsets,
			strokeColor,
		);
		this.drawLine(
			context,
			tox,
			toy,
			rightX,
			rightY,
			headOffsets,
			strokeColor,
		);
	};

	private drawArrow(ele: ElementEdit, currentTime: number) {
		const { canvas } = this.props;
		const paused = this.props.paused;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		const { position, size, shapedata } = ele;
		if (!shapedata) return;

		const {
			strokeWidth = 8,
			strokeColor = "#d43f8c",
			drawTime = 0.5,
		} = shapedata;

		const { rectWidth, rectHeight, rectX, rectY } =
			this.computeElementContainer(ele);

		const t = currentTime;

		ctx.save();

		// Calculate animation progress
		let progress = 1;

		if (paused) {
			progress = 1;
		} else if (t > ele.startTime && t <= ele.startTime + drawTime) {
			progress = (t - ele.startTime) / drawTime;
		} else if (t > ele.endTime - drawTime && t <= ele.endTime) {
			progress = (ele.endTime - t) / drawTime;
		}

		// Draw the animated arrow with ensured non-zero progress
		if (progress > 0.2) {
			const currentX = rectX + rectWidth * progress;
			const currentY = rectY + rectHeight * progress;

			this.makeArrowOnCanvas(
				ctx,
				rectX,
				rectY,
				currentX,
				currentY,
				strokeWidth,
				strokeColor,
			);
		}

		ctx.restore();
	}

	private drawText(
		ele: ElementEdit,
		currentTime: number,
		transition = false,
	) {
		const { canvas } = this.props;
		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		let opacity = 1;
		let fillStyle: string;

		const { rectWidth, rectHeight, rectX, rectY } =
			this.computeElementContainer(ele);

		const x = rectX;
		const y = rectY;
		const w = rectWidth;
		const h = rectHeight;

		const { textdata } = ele;

		if (!textdata) return;

		//#region transition animation
		if (transition) {
			const t = currentTime;

			//compute the opacity
			if (t > ele.startTime && t <= ele.startTime + ANIMATION_TIME) {
				const start = { y: 0, x: ele.startTime };
				const end = { y: 1, x: ele.startTime + ANIMATION_TIME };

				opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
			} else if (t > ele.endTime - ANIMATION_TIME) {
				const start = { y: 1, x: ele.endTime - ANIMATION_TIME };
				const end = { y: 0, x: ele.endTime };

				opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
			}
		}
		//#endregion

		//#region draw the text

		const fontSize = textdata.fontSize * this.coordinates.canvasHeight;
		const fontFamily = textdata.font;
		const textAlign = textdata.alignment;
		const lines = textdata.lines || [""];
		const color = textdata.textColor || "#000000";
		let lineHeight = fontSize * 1.2;
		if (textdata.lineHeight) {
			lineHeight = textdata.lineHeight * fontSize;
		}

		const fontName = this.assets.getFont(fontFamily);

		ctx.font = `${fontSize}px/${fontSize}px ${fontName}`;
		// ctx.font = "normal 400 400px/400px Arizonia";
		// console.log("setting font", `${fontSize}px ${fontName}`);
		// console.log("ctx.font", ctx.font);
		ctx.fillStyle = hexToRGBA(color, opacity * 100);
		ctx.textBaseline = "top";

		const paddedY = y + PADDING_H;
		const startY = paddedY;
		ctx.textAlign = textAlign;

		// Determine horizontal position based on text alignment
		let posX: number;
		switch (textAlign) {
			case "center":
				posX = x + w / 2; // Center of the rectangle for center alignment
				break;
			case "right":
				posX = x + w - PADDING_W; // Adjust for padding for right alignment
				break;
			case "left":
				posX = x + PADDING_W; // Adjust for padding for left alignment
				break;
		}

		//#region draw the background rectangle
		if (textdata.backgroundColor) {
			ctx.save();

			let bgOpacity = textdata.backgroundOpacity || 100;
			if (textdata.backgroundOpacity === 0) bgOpacity = 0;

			fillStyle = hexToRGBA(
				textdata.backgroundColor,
				opacity * bgOpacity,
			);

			ctx.fillStyle = fillStyle;
			drawFilledRoundedRect(ctx, x, y, w, h, h * 0.0, fillStyle);
			ctx.fill();
			ctx.restore();
		}
		//#endregion

		// Draw each line of text according to the specified alignment
		lines.forEach((text, i) => {
			// ctx.fillText(line, posX, startY + i * lineHeight);
			const metrics = ctx.measureText(text);
			const x = posX;
			const y =
				startY +
				i * lineHeight -
				// 0.1 * fontSize -
				0;
			ctx.fillText(text, x, y);
			// console.log("=== Debugging Text Rendering ===");

			// Log current font and textBaseline
			// console.log("Font and Baseline:", {
			// 	font: ctx.font,
			// 	textBaseline: ctx.textBaseline,
			// 	textAlign: ctx.textAlign,
			// });

			// // Measure text metrics
			// console.log("Text Metrics:", metrics);
			// // @ts-ignore
			// console.log("text wrap", ctx.textWrap);

			// // Draw text and debugging lines
			// ctx.strokeStyle = "blue"; // Baseline
			// ctx.beginPath();
			// ctx.moveTo(0, y);
			// ctx.lineTo(canvas?.width ?? 0, y);
			// ctx.stroke();

			// ctx.strokeStyle = "red"; // Ascent
			// ctx.beginPath();
			// ctx.moveTo(0, y - metrics.actualBoundingBoxAscent);
			// ctx.lineTo(canvas?.width ?? 0, y - metrics.actualBoundingBoxAscent);
			// ctx.stroke();

			// ctx.strokeStyle = "green"; // Descent
			// ctx.beginPath();
			// ctx.moveTo(0, y + metrics.actualBoundingBoxDescent);
			// ctx.lineTo(
			// 	canvas?.width ?? 0,
			// 	y + metrics.actualBoundingBoxDescent,
			// );
			// ctx.stroke();
		});
		//#endregion
	}

	private drawImage(ele: ElementEdit, currentTime: number) {
		const { canvas } = this.props;
		const ctx = canvas?.getContext("2d");
		if (!ctx || !canvas) return;

		const mediaRenderWidth = canvas.width;
		const { rectX, rectY } = this.computeElementContainer(ele);
		const [width] = ele.size;

		if (!this.assets.screenclip.imageElementsMap) return;

		const image = this.assets.screenclip.imageElementsMap.get(ele?.id);
		if (!image) return;

		const imageData = ele.imagedata;
		if (!imageData) return;

		// Incorporate crop info from the CropImage component
		const castCrop = imageData.crop || DEFAULT_CROP;
		const cropX = castCrop.position[0];
		const cropY = castCrop.position[1];
		const cropWidthFrac = castCrop.size[0];
		const cropHeightFrac = castCrop.size[1];

		const naturalWidth = imageData.naturalWidth;
		const naturalHeight = imageData.naturalHeight;
		const sx = cropX * naturalWidth;
		const sy = cropY * naturalHeight;
		const sWidth = cropWidthFrac * naturalWidth;
		const sHeight = cropHeightFrac * naturalHeight;

		// Compute destination width based on element size and canvas width
		const rectWidth = width * mediaRenderWidth;
		// Compute destination height based on the aspect ratio of the cropped region
		const rectHeight = rectWidth * (sHeight / sWidth);

		const castCornerRadius = Number(imageData.cornerRadius) || 0;
		const castShadow = Number(imageData.shadow) || 0;

		ctx.save();
		ctx.beginPath();
		const r =
			castCornerRadius > 0
				? (castCornerRadius * Math.min(rectWidth, rectHeight)) / 100
				: 0;
		if (r > 0) {
			ctx.moveTo(rectX + r, rectY);
			ctx.lineTo(rectX + rectWidth - r, rectY);
			ctx.quadraticCurveTo(
				rectX + rectWidth,
				rectY,
				rectX + rectWidth,
				rectY + r,
			);
			ctx.lineTo(rectX + rectWidth, rectY + rectHeight - r);
			ctx.quadraticCurveTo(
				rectX + rectWidth,
				rectY + rectHeight,
				rectX + rectWidth - r,
				rectY + rectHeight,
			);
			ctx.lineTo(rectX + r, rectY + rectHeight);
			ctx.quadraticCurveTo(
				rectX,
				rectY + rectHeight,
				rectX,
				rectY + rectHeight - r,
			);
			ctx.lineTo(rectX, rectY + r);
			ctx.quadraticCurveTo(rectX, rectY, rectX + r, rectY);
		} else {
			ctx.rect(rectX, rectY, rectWidth, rectHeight);
		}
		ctx.closePath();

		if (castShadow > 0) {
			ctx.save();
			ctx.shadowColor = "black";
			ctx.shadowBlur = castShadow;
			ctx.shadowOffsetX = -castShadow / 2;
			ctx.shadowOffsetY = castShadow / 2;
			ctx.fillStyle = "rgba(0,0,0,0.6)";
			ctx.fill();
			ctx.restore();
		}

		ctx.save();
		ctx.clip();
		ctx.drawImage(
			image.src,
			sx,
			sy,
			sWidth,
			sHeight,
			rectX,
			rectY,
			rectWidth,
			rectHeight,
		);
		ctx.restore();
	}

	// if an element is scaling or moving, we need to clamp its position to the canvas to make sure it doesn't overflow
	private clampPosition(
		scaledX: number,
		scaledY: number,
		scaledWidth: number,
		scaledHeight: number,
	): { x: number; y: number } {
		const { canvas } = this.props;
		if (!canvas) return { x: 0, y: 0 };

		// Compute available padding between the video render dimensions and the canvas dimensions
		const paddingX = (canvas.width - this.coordinates.videoRenderWidth) / 2;
		const paddingY =
			(canvas.height - this.coordinates.videoRenderHeight) / 2;
		const safeMargin = Math.min(paddingX, paddingY);

		// Ensure the element does not exceed the canvas boundaries by subtracting safeMargin
		const adjustedX = Math.max(
			safeMargin,
			Math.min(scaledX, canvas.width - safeMargin - scaledWidth),
		);

		const adjustedY = Math.max(
			safeMargin,
			Math.min(scaledY, canvas.height - safeMargin - scaledHeight),
		);
		return { x: adjustedX, y: adjustedY };
	}
}

export default new CanvasElements(
	dummyVideoEdits,
	{},
	null as unknown as CanvasAssets,
	cc,
	"browser",
);
