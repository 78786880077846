import axios from "axios";
import { AnalyticsEvent, captureEvent } from "../core/analytics/analytics";
import saverconfig from "../network/_saverconfig";

export const captureEventInPanchayat = async (event: AnalyticsEvent) => {
	try {
		captureEvent(event);

		await axios.post(
			saverconfig.panchayat_url + "/api/v1/analytics/ingest",
			{
				eventName: event.eventName,
				data: event.value,
			},
		);
	} catch (err) {
		console.error("Failed to capture event", err);
	}
};
