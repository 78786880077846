import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import "./ToolbarButton.css";
import downArrowheadIcon from "../../../../../../assets/svgs/downArrowheadIcon";
import Clickout from "../../../../../../layouts/Clickout/Clickout";

type ToolbarButtonProps = {
	active: () => boolean;
	handler: () => void;
	hoverTip?: string;
	source: JSX.Element;
	backgroundColor?: string;
	disabled?: boolean;

	dropdownMenu?: ToolbarButtonProps[];
	newHoverTip?: JSX.Element;
	dropdownType?: "verticle" | "horizontal";
	customClassName?: string;
};

const ToolbarButton: React.FC<ToolbarButtonProps> = (props) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [isHovering, setIsHovering] = useState<boolean>(false);
	const onMouseEnter = (e: any) => {
		setIsHovering(true);
	};

	const onMouseLeave = () => {
		setIsHovering(false);
	};
	return (
		<Clickout
			closeFunction={() => {
				setShowDropdown(false);
			}}
		>
			<div
				onMouseEnter={(e) => {
					onMouseEnter(e);
				}}
				onMouseLeave={onMouseLeave}
				onClick={
					props.disabled
						? () => {}
						: props.dropdownMenu
							? () => {
									setShowDropdown(!showDropdown);
								}
							: props.handler
				}
				className={`
                ToolbarButton ${props.active() ? "Toolbar-activeButton" : "Toolbar-inactiveButton"}
                ${props.disabled ? "Toolbar-disabled" : "Toolbar-nondisabled"}
                ${props.dropdownMenu ? "Toolbar-drop" : "Toolbar-nondrop"}
				${props.customClassName ? props.customClassName : ""}
            `}
				style={{
					backgroundColor: props.backgroundColor
						? props.backgroundColor
						: undefined,
					borderRadius: props.backgroundColor ? 0 : undefined,
				}}
			>
				{!React.isValidElement(props.source) ||
				props.source.type === "svg" ? (
					props.newHoverTip ? (
						<Icon
							hideHoverTipArrow
							disabled={props.disabled}
							className={`gigauser-texteditor-toolbar-button ${props.active()}`}
						>
							{props.source}
						</Icon>
					) : (
						<Icon
							hoverTip={props.hoverTip}
							hideHoverTipArrow
							disabled={props.disabled}
							className={`gigauser-texteditor-toolbar-button ${props.active()}`}
						>
							{props.source}
						</Icon>
					)
				) : (
					<div className="ToolbarButton-dropdown-text-item-container">
						{" "}
						{props.source}
					</div>
				)}

				{props.dropdownMenu && (
					<>
						<div className="ToolbarButton-down-arrow">
							{props.active()
								? downArrowheadIcon("white")
								: downArrowheadIcon()}
						</div>

						<div
							className={
								props.dropdownType === "verticle"
									? "ToolbarButton-dropdown-verticle"
									: "ToolbarButton-dropdown"
							}
							style={{
								display: showDropdown ? "block" : undefined,
							}}
						>
							<div
								className={
									props.dropdownType === "verticle"
										? "ToolbarButton-dropdown-list-verticle"
										: "ToolbarButton-dropdown-list"
								}
							>
								{props.dropdownMenu.map((x, index) => (
									<ToolbarButton
										key={index}
										disabled={x.disabled}
										active={x.active}
										handler={x.handler}
										source={x.source}
										newHoverTip={x.newHoverTip}
										customClassName={
											props.dropdownType === "verticle"
												? "ToolbarButton-dropdown-item-container"
												: undefined
										}
									/>
								))}
							</div>
						</div>
					</>
				)}

				{props.newHoverTip && !showDropdown && isHovering
					? props.newHoverTip
					: null}
			</div>
		</Clickout>
	);
};
export default ToolbarButton;
