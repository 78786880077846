import React from "react";
import "./WhiteHoverTip.css";
import { commandIcon } from "../../assets/svgs/commandIcon";
import Icon from "../Icon/Icon";
import { shiftIcon } from "../../assets/svgs/shiftIcon";

type WhiteHoverTipProps = {
	text: string;
	shortcutKey?: string;
	customClassName?: string;
	hoverPosition?: "top" | "bottom";
};

const WhiteHoverTip: React.FC<WhiteHoverTipProps> = (props) => {
	let shortcut = "";

	const userOS =
		navigator.userAgent.indexOf("Mac") !== -1 ? "mac" : "windows";

	if (props.shortcutKey?.includes("or")) {
		const keys = props.shortcutKey.split("or");

		if (userOS === "mac") {
			shortcut = keys[0];
		} else {
			shortcut = keys[1];
		}
	} else {
		shortcut = props.shortcutKey || "";
	}

	const shortcutKeys = shortcut
		? shortcut.split("+").map((key) => key.trim())
		: [];

	const shortcutKeyElements = [] as JSX.Element[];

	shortcutKeys.forEach((key, index) => {
		let elementToInsert = null;
		if (
			key.toLowerCase() === "cmd" ||
			key.toLowerCase() === "ctrl" ||
			key.toLowerCase() === "command" ||
			key.toLowerCase() === "control"
		) {
			elementToInsert =
				userOS === "mac" ? (
					<div className="shortcut-icon-container">
						<Icon className="shortcut-icon">{commandIcon()}</Icon>
					</div>
				) : (
					<div className="shortcut-text-container">
						<p className="shortcut-text">Ctrl</p>
					</div>
				);
		} else if (key.toLowerCase() === "shift") {
			elementToInsert = (
				<div className="shortcut-icon-container">
					<Icon className="shortcut-icon">{shiftIcon()}</Icon>
				</div>
			);
		} else {
			elementToInsert = (
				<div className="shortcut-text-container">
					<p className="shortcut-text">{key}</p>
				</div>
			);
		}

		shortcutKeyElements.push(elementToInsert);
		if (index !== shortcutKeys.length - 1) {
			shortcutKeyElements.push(<p className="shortcut-joiner">+</p>);
		}
	});

	return (
		<div
			className={"WhiteHoverTip"}
			style={{
				bottom: props.hoverPosition === "top" ? "3rem" : "auto",
				top: props.hoverPosition === "top" ? "auto" : "100%",
			}}
		>
			<div className="WhiteHoverTip-inner">
				<div className="text-container">
					<p className="text">{props.text}</p>
				</div>
				{shortcutKeyElements.length ? (
					<div className="shortcut-container">
						{shortcutKeyElements}
					</div>
				) : null}
			</div>
		</div>
	);
};
export default WhiteHoverTip;
