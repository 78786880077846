import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";
import { useContext, useRef } from "react";
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import { HelpCenterProps } from "@gigauser/common/src/help-center/HelpCenter/HelpCenter";
import { captureEventInPanchayat } from "@gigauser/common/src/utils/panchayat";

type debouncedSearchProps = {
	searchString: string;
	setLoading: (b: boolean) => void;
	setResults: (res: GuidePreview[]) => void;
	helpCenterProps?: HelpCenterProps;
};

const useRunDebouncedSearch: () => (
	props: debouncedSearchProps,
) => Promise<void> = () => {
	const helpCenterContext = useContext(ExtHelpCenterContext);
	const debounceSearchTimer = useRef<any>(null);
	const searchEventTimer = useRef<any>(null);
	const runSearch = async (props: debouncedSearchProps) => {
		if (!props.searchString) {
			props.setLoading(false);
			props.setResults([]);
			return;
		}

		props.setLoading(true);

		const res = await helpCenterContext.search(props.searchString);

		if (res.length == 0) {
			captureEvent({
				eventName: "SearchReturnsNoResults",
				value: { searchString: props.searchString },
			});
			props.setResults([]);
		} else {
			props.setResults(res);
		}

		props.setLoading(false);
	};

	return async (props: debouncedSearchProps) => {
		if (debounceSearchTimer.current !== undefined) {
			clearTimeout(debounceSearchTimer.current);
		}
		if (searchEventTimer.current !== undefined) {
			clearTimeout(searchEventTimer.current);
		}
		if (!props.searchString) {
			//empty search string clear up here
			props.setLoading(false);
			props.setResults([]);
		} else {
			searchEventTimer.current = setTimeout(() => {
				if (props.helpCenterProps) {
					captureEventInPanchayat({
						eventName: "SearchRunInHelpCenter",
						value: {
							organization_id: props.helpCenterProps
								.getOrganization()
								?.id.toString() as string,
							search_query: props.searchString,
							session_id: props.helpCenterProps
								.helpCenterSessionId as string,
						},
					});
				}
			}, 2000);
			debounceSearchTimer.current = setTimeout(() => {
				runSearch(props);
			}, 300);
		}
	};
};

export default useRunDebouncedSearch;
