import {
	Guide,
	GuideData,
	GuidePreview,
} from "@gigauser/common/src/core/types/guide";
import { useContext } from "react";
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext";
import { useAppDispatch, useAppSelector } from "../redux";
import { setCollectionPath, setCurrentGuide } from "../redux/slices/hcSlice";
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";
import useNavigateToLink from "./useNavigateToLink";
import { Collection } from "@gigauser/common/src/core/types/collections";
import useScrollTop from "./useScrollTop";
import { Id } from "@giga-user-fern/api/types/api";
import { captureEventInPanchayat } from "@gigauser/common/src/utils/panchayat";

const useOpenGuide: () => (
	guidePreview: GuidePreview,
	parentSegments: [Collection, ...Collection[]],
) => Promise<GuideData | null> = () => {
	const helpCenterContext = useContext(ExtHelpCenterContext);
	const dispatch = useAppDispatch();
	const navigateToLink = useNavigateToLink();
	const scrollTop = useScrollTop();

	return async (
		guidePreview: GuidePreview,
		parentSegments: [Collection, ...Collection[]],
	) => {
		navigateToLink(
			("/guide/" +
				(guidePreview.guideUrlPath
					? guidePreview.guideUrlPath
					: guidePreview.id)) as string,
		);

		//This is to set guide loading.
		dispatch(
			setCurrentGuide({
				id: guidePreview.id,
				guidePreview: guidePreview,
			}),
		);

		const data = await helpCenterContext.fetchGuideData(guidePreview.id);
		// const videoEdits= await helpCenterContext.getVideoEdits(guidePreview.id)

		dispatch(
			setCurrentGuide({
				id: guidePreview.id,
				guidePreview: guidePreview,
				guideData: data!,
				// videoEdits: videoEdits || undefined
			}),
		);

		dispatch(setCollectionPath(parentSegments));

		captureEventInPanchayat({
			eventName: "GuideOpenedInHelpCenter",
			value: {
				guide_id: guidePreview.id,
				organization_id: helpCenterContext
					.getOrganization()
					?.id.toString() as string,
				source: "direct",
			},
		});

		// if(viewerComponent) viewerComponent.scrollTop = 0
		scrollTop();
		return data;
	};
};

export default useOpenGuide;
