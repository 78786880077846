import React, { MouseEventHandler, useContext, useRef } from "react";
import "./ImgShape.css";
import ScreenshotEditorContext, { Shape } from "../../ScreenshotEditorContext";
import { hexToRGBA } from "../../../../../../../utils/colorUtils";

import SizeControllerThumb, {
	SizeControllerPos,
} from "../SizeController/SizeControllerThumb";
import DragController from "../SizeController/DragController";

type ImgShapeProps = {
	shape: Shape;
	isCropping: boolean;
};

const ImgShape: React.FC<ImgShapeProps> = (props) => {
	const { shape } = props;

	const sc = useContext(ScreenshotEditorContext);
	const shapeRef = useRef<HTMLDivElement>(null);

	// Return if reference is not available
	if (!sc.imgRef || !shape) return null;
	if (!shape.position || !shape.size) return null;
	if (!sc.imgRef.current) return null;

	const isActive = shape.id === sc.activeShapeId;
	const isArrow = shape.geo === "arrow";
	const isCircle = shape.geo === "circle";

	const strokeColor = shape.stroke || "transparent";

	const crop = !props.isCropping
		? sc.crop
		: { position: [0, 0], size: [1, 1] };

	// Calculate positions based on image bounds
	const bounds = sc.imgRef.current.getBoundingClientRect();
	const x = shape.position[0] * bounds.width;
	const y = shape.position[1] * bounds.height;

	// Calculate dimensions based on image bounds
	const width = shape.size[0] * bounds.width;
	const height = shape.size[1] * bounds.height;

	const absWidth = Math.abs(width);
	const absHeight = Math.abs(height);

	let strokeWidth;

	if (!shape.version) {
		// Old stroke width: absolute value
		strokeWidth = shape.strokeWidth ?? 0;
	} else {
		strokeWidth =
			((shape.strokeWidth ?? 0) *
				Math.max(
					bounds.width / crop.size[0],
					bounds.height / crop.size[1],
				)) /
			1000;
	}

	// Set background color based on fill and opacity
	const fillColor: string =
		shape.fill && shape.fillOpacity
			? hexToRGBA(shape.fill, shape.fillOpacity)
			: "transparent";

	// Determine size controller positions based on shape type
	const edgeControls: SizeControllerPos[] = ["t", "l", "b", "r"];
	const cornerControls: SizeControllerPos[] = ["tl", "bl", "br", "tr"];
	const arrowControls: SizeControllerPos[] = ["tl", "br"];

	let sizeControls: SizeControllerPos[];

	const onMouseDown: MouseEventHandler = (e) => {
		if (e.button === 2) return;
		sc.setActiveShapeId(props.shape.id);
	};
	if (isArrow) sizeControls = arrowControls;
	else if (isCircle) sizeControls = cornerControls;
	else sizeControls = [...edgeControls, ...cornerControls];

	const theta = Math.atan2(absHeight, absWidth);

	return (
		<div
			className={`ImgShape-container ${isActive ? "active-shape" : "inactive-shape"}`}
			style={{
				top: y,
				left: x,
				height: absHeight,
				width: absWidth,
				transform: `scaleX(${Math.sign(width)}) scaleY(${Math.sign(height) || 1})`,
				transformOrigin: "top left",

				// transform: isArrow ? `rotate(${arrowAngle}rad)` : "",
				// transform: `translate(${width/2}px, ${height/2}px) rotate(${arrowAngle}deg) translate(-${width/2}px, -${height/2}px)`,

				// border:`${shape.strokeWidth}px solid ${strokeColor}`
			}}
			ref={shapeRef}
		>
			<div
				className={`ImgShape ${shape.geo}`}
				style={{
					border:
						!isArrow && strokeWidth
							? `${strokeWidth}px solid ${strokeColor}`
							: undefined,
					backgroundColor: isArrow ? undefined : fillColor,
					width: "100%",
					height: "100%",
					position: "relative",
				}}
				onMouseDown={onMouseDown}
			>
				{/* Render arrow SVG if shape type is arrow */}

				{isArrow && (
					<>
						<div
							className="ArrowLine"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								transform: `
                                translateY(${-(strokeWidth / 2) * Math.cos(theta)}px) 
                                translateX(${(strokeWidth / 2) * Math.sin(theta)}px) 
                                rotate(${theta}rad) 
                            `,
								transformOrigin: "top left",
								width: `${Math.sqrt(width * width + height * height) - strokeWidth * 2.5}px`,
								height: `${strokeWidth}px`,
								background: `${strokeColor}`,
							}}
						>
							{/* This div with a height of 10px is used to ensure the arrowhead is always selectable */}
							<div
								style={{
									height: 10,
									width: "100%",
									position: "absolute",
									top: -5,
								}}
							/>
						</div>
						<div
							className="ArrowHead"
							style={{
								transformOrigin: "bottom ",
								transform: `
                                translateX(50%) 
                                rotate(${Math.atan2(absHeight, absWidth) - Math.PI / 2}rad)
                            `,
								//translateY(1px)
								// translateX(${strokeWidth/2 - (Math.atan2(absHeight,absWidth))/(Math.PI/4)}px)

								borderLeft: `${strokeWidth * 2}px solid transparent`,
								borderRight: `${strokeWidth * 2}px solid transparent`,
								borderTop: `${strokeWidth * 4}px solid ${strokeColor}` /* Color and size of the arrowhead */,
							}}
						></div>
					</>
				)}
			</div>

			{/* Render drag controller and size controllers if shape is active */}

			{isActive && (
				<DragController
					shape={shape}
					zoomFactor={sc.zoomFactor}
					getRelativeCoords={sc.getRelativeCoords}
					getAbsoluteCoords={sc.getAbsoluteCoords}
					updateShape={sc.updateShape}
				/>
			)}

			{isActive
				? sizeControls.map((pos) => (
						<SizeControllerThumb
							key={pos}
							shape={shape}
							position={pos}
							zoomFactor={sc.zoomFactor}
							getRelativeCoords={sc.getRelativeCoords}
							getAbsoluteCoords={sc.getAbsoluteCoords}
							updateShape={sc.updateShape}
							lockRatio={isCircle}
							resizeControls={true}
						/>
					))
				: null}
		</div>
	);
};

export default ImgShape;
