import React from "react";
import "./HoverTip.css";
import { Size } from "../../types/sizes";

type HoverTipProps = {
	children?: React.ReactNode;
	hoverTipTitle?: string;
	hoverTipPara?: string | string[];
	className?: string;
	size?: Size;
};

const HoverTip: React.FC<HoverTipProps> = (props) => {
	return (
		<div className={`HoverTip-container ${props.className}`}>
			{props.children}

			<div className={`hoverTip-label ${props.size}`}>
				<div className={`hoverTip-title ${props.size}`}>
					{props.hoverTipTitle}
				</div>

				{Array.isArray(props.hoverTipPara) && (
					<ul>
						{props.hoverTipPara.map((para, index) => (
							// biome-ignore lint/suspicious/noArrayIndexKey: <only index can be trusted here>
							<li key={index} className="hoverTip-para-li">
								{para}
							</li>
						))}
					</ul>
				)}

				{!Array.isArray(props.hoverTipPara) && props.hoverTipPara && (
					<div className="hoverTip-para">{props.hoverTipPara}</div>
				)}
			</div>
		</div>
	);
};
export default HoverTip;
