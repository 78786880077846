/**
 * Mediaplayer docs: https://old.vidstack.io/docs/player/getting-started/installation
 */

import React, { useEffect, useRef, useState } from "react";
import "./Video.css";
import { GuideData, GuidePreview } from "../../../core/types/guide";
import { oembed } from "@loomhq/loom-embed";
import { OEmbedInterface } from "@loomhq/loom-embed/dist/.types/oembed";
import logger from "../../../utils/logger";
import playIcon from "../../../assets/svgs/playIcon";
import pauseIcon from "../../../assets/svgs/pauseIcon";
import logoIcon from "../../../assets/svgs/logoIcon";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import {
	MediaCommunitySkin,
	MediaOutlet,
	useMediaStore,
	MediaPlayer,
	MediaPoster,
	MediaPlayButton,
	useMediaRemote,
} from "@vidstack/react";
import type {
	MediaPlayEvent,
	MediaPlayFailEvent,
	MediaPlayRequestEvent,
	MediaPauseEvent,
	MediaPlayerElement,
} from "vidstack";
import { v4 as uuidv4 } from "uuid";

import Icon from "../../../ui/Icon/Icon";
import useWindowSize from "../../../hooks/useWindowSize";
import { Device } from "../../../types/devices";
import { captureEvent } from "../../../core/analytics/analytics";
import { calculateMediaDuration } from "../../../core/canvas/canvasUtils/videoUtils";
import { useAppSelector } from "../../../redux";
import { captureEventInPanchayat } from "../../../utils/panchayat";
import { HelpCenterProps } from "../../../help-center/HelpCenter/HelpCenter";

type VideoProps = {
	video: GuideData["video"];
	videoSrc?: string;
	guidePreview?: GuidePreview;
	overrideDevice?: Device;
	hideVideoBranding?: boolean;
	embed?: boolean;
	disableDownload?: boolean;
	helpCenterProps: HelpCenterProps;
};

const Video: React.FC<VideoProps> = (props) => {
	// const recorderType: "default" | "loom" | undefined = props.video.metadata?.recorderType;
	const vidRef = useRef<HTMLVideoElement>(null);
	const [button, setButton] = useState(true);
	const [videoHTML, setVideoHTML] = useState<string>("");

	const [firstPlay, setFirstPlay] = useState(false);
	const [flashPlay, setFlashPlay] = useState(false);
	const [flashPause, setFlashPause] = useState(false);

	const [subtitleSrc, setSubtitleSrc] = useState("");

	// ERROR ---- One or both of these two lines is causing error:
	const player = useRef<MediaPlayerElement>(null);
	const { paused, currentTime, duration } = useMediaStore(player);
	const remote = useMediaRemote(player);

	const [loaded, setLoaded] = useState(false);
	const [canPlay, setCanPlay] = useState(false);

	const [obfuscatedSource, setObfuscatedSource] = useState("");
	const [htmlVideoDuration, setHtmlVideoDuration] = useState(0);

	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const playbackPointRef = useRef(0);

	let device: Device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;

	const handlePlay = () => {
		setButton(false);
		if (vidRef.current) {
			vidRef.current.play();
		}
	};

	const handlePause = () => {
		if (vidRef.current) {
			vidRef.current.pause();
		}
	};

	const startEventLoggingInPanchayat = () => {
		stopEventLoggingInPanchayat();

		intervalRef.current = setInterval(() => {
			const playbackTime = playbackPointRef.current;
			const videoDuration = Math.floor(duration || htmlVideoDuration);

			if (props.helpCenterProps && videoDuration) {
				captureEventInPanchayat({
					eventName: "VideoPlayedInHelpCenter",
					value: {
						guide_id: props.guidePreview?.id.toString() as string,
						organization_id: props.helpCenterProps
							.getOrganization()
							?.id.toString() as string,
						playback_point_in_seconds: Math.floor(playbackTime),
						session_id: props.helpCenterProps
							.helpCenterSessionId as string,
						video_duration_in_seconds: videoDuration,
						percentage_played:
							Math.floor(
								(Math.floor(playbackTime) / videoDuration) *
									100,
							) > 100
								? 100
								: Math.floor(
										(Math.floor(playbackTime) /
											videoDuration) *
											100,
									),
					},
				});
			}
		}, 1000);
	};

	const stopEventLoggingInPanchayat = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	useEffect(() => {
		playbackPointRef.current = currentTime; // Keep the ref up-to-date
	}, [currentTime]);

	const onMediaPlay = (event: MediaPlayEvent) => {
		if (!firstPlay) {
			setFirstPlay(true);
		}

		setFlashPlay(true);

		setTimeout(() => {
			setFlashPlay(false);
		}, 200);

		startEventLoggingInPanchayat();
	};

	const onMediaPause = (event: MediaPauseEvent) => {
		setFlashPause(true);

		setTimeout(() => {
			setFlashPause(false);
		}, 200);

		stopEventLoggingInPanchayat();
	};

	// Handlers for the simple video player
	const onVideoPlay = () => {
		startEventLoggingInPanchayat();
	};

	const onVideoPause = () => {
		stopEventLoggingInPanchayat();
	};

	// Track playback progress for the simple video player
	useEffect(() => {
		if (vidRef.current) {
			const video = vidRef.current;

			const updatePlaybackPoint = () => {
				playbackPointRef.current = video.currentTime;
			};

			video.addEventListener("timeupdate", updatePlaybackPoint);
			return () => {
				video.removeEventListener("timeupdate", updatePlaybackPoint);
			};
		}
	}, []);

	useEffect(() => {
		logger.debug("vidref.current: ", vidRef.current);
		// logger.debug("src: ", props.video.metadata?.generatedVideo?.src)

		if (device === "mobile" && vidRef.current) {
			calculateMediaDuration(vidRef.current);
		}

		return () => {
			stopEventLoggingInPanchayat();
		};
	}, []);

	if (device === "laptop") {
		return (
			// <div>Hello there!</div>
			<div
				className={`gigauser-video-container 
                ${props.embed ? "gigauser-embed-video" : "gigauser-nonembed-video"}
                ${canPlay ? "gigauser-canplay-video" : "gigauser-noplay-video"}
                
            `}

				// style={{display: loaded?"block":"none"}}
				// onClick={button?handlePlay:undefined}
			>
				{/* <div
                    style={{visibility: (button)?"visible":"hidden"}} 
                    className={`gigauser-play-button`}></div> */}

				<MediaPlayer
					onPlay={onMediaPlay}
					onPause={onMediaPause}
					ref={player}
					title={props.guidePreview?.header.name}
					src={props.videoSrc || props.video.originalSrc}
					poster="https://media-files.vidstack.io/poster.png"
					className={`
                        ${!firstPlay ? "thumbnail-preview" : ""} 
                        ${device === "laptop" ? "gigauser-laptop-mediaplayer" : "gigauser-mobile-mediaplayer"}
                        ${props.embed ? "gigauser-embed-mediaplayer" : ""}
                    `}
					style={
						{
							// display: loaded?"block":"none"
						}
					}
					onCanPlay={() => {
						setCanPlay(true);
					}}
					onLoadedMetadata={() => {
						setLoaded(true);
					}}
					onContextMenu={(e: any) => {
						if (props.disableDownload) {
							e.preventDefault();
						}
					}}
					// poster="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980"
					// thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
					// aspectRatio={16 / 9}
					// crossorigin=""
				>
					<div
						className={`thumbnail-play ${paused ? "video-paused" : "video-playing"}`}
						onClick={() => {
							remote.play();
						}}
						style={{
							display: !loaded ? "none" : undefined,
						}}
					>
						<div className="thumbnail-play-icon-container">
							<div className="thumbnail-play-bg"></div>
							<div className="thumbnail-play-icon">
								{playIcon("white")}
							</div>
						</div>

						{!props.hideVideoBranding ? (
							<CanvasPlayerCluesoPreview />
						) : null}
					</div>

					<MediaOutlet>
						{/* <MediaPoster alt = {props.guidePreview?.header.name} /> */}

						<div
							className={`flash-play ${flashPlay || flashPause ? "flash-show" : ""}`}
						>
							{flashPlay ? (
								<Icon className="flash-icon-container">
									{playIcon("white")}
								</Icon>
							) : flashPause ? (
								<Icon className="flash-icon-container">
									{pauseIcon("white")}
								</Icon>
							) : null}
						</div>

						{props.video.generated?.subtitles && (
							<track
								label="English"
								kind="subtitles"
								srcLang="en"
								src={props.video.generated?.subtitles}
								// default
							/>
						)}
					</MediaOutlet>

					<MediaCommunitySkin />
				</MediaPlayer>
			</div>
		);
	} else {
		return (
			<video
				ref={vidRef}
				preload="metadata"
				controls
				crossOrigin="anonymous"
				className={"gigauser-editor-video"}
				src={props.videoSrc || props.video.originalSrc}
				// onPlay={handlePlay}
				// onPause={handlePause}s
				onContextMenu={(e: any) => {
					if (props.disableDownload) {
						e.preventDefault();
					}
				}}
				controlsList="nodownload"
				onPlay={onVideoPlay}
				onPause={onVideoPause}
				onLoadedMetadata={(e) => {
					const video = e.target as HTMLVideoElement;
					setHtmlVideoDuration(video.duration || 0); // Store duration
				}}
			>
				{props.video.generated?.subtitles && (
					<track
						label="English"
						kind="subtitles"
						srcLang="en"
						src={props.video.generated?.subtitles}
					/>
				)}
			</video>
		);
	}

	// else if (recorderType === "loom") {
	// 	return (
	// 		<div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
	// 	)
	// } else {
	//     return <></>;
	// }
};

type CanvasPlayerCluesoPreviewProps = {};

const CanvasPlayerCluesoPreview: React.FC<
	CanvasPlayerCluesoPreviewProps
> = () => {
	return (
		<a
			className="thumbnail-clueso-preview"
			href={
				"https://www.clueso.io/?utm_source=customerKB&utm_medium=videopill&utm_campaign=" +
				window.location.hostname
			}
			target="_blank"
			rel="noreferrer"
		>
			<div className="thumbnail-clueso-text">Made in</div>
			<div className="thumbnail-clueso-logo">{logoIcon()}</div>
			{/* <img className="thumbnail-clueso-logo"></img> */}
		</a>
	);
};

export default Video;
