const flag_pt = (color = "#000000") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 580 578"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M298.099 577.889C454.517 573.6 580 445.454 580 288C580 139.743 468.748 17.4699 325.172 0.111328H254.828C111.252 17.4699 0 139.743 0 288C0 445.454 125.483 573.6 281.901 577.889H298.099Z"
				fill="#006600"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M299.085 577.889C454.957 573.608 580 445.907 580 289C580 132.099 454.965 4.40073 299.1 0.111328H282.9C262.797 0.664551 243.208 3.27057 224.333 7.72729V570.273C243.213 574.731 262.807 577.337 282.915 577.889H299.085Z"
				fill="#D52B1E"
			/>
			<path
				d="M224.333 111.223C177.184 111.223 131.965 129.953 98.6256 163.293C65.2858 196.632 46.5557 241.851 46.5557 289C46.5557 336.15 65.2858 381.369 98.6256 414.708C131.965 448.048 177.184 466.778 224.333 466.778C271.483 466.778 316.702 448.048 350.041 414.708C383.381 381.369 402.111 336.15 402.111 289C402.111 241.851 383.381 196.632 350.041 163.293C316.702 129.953 271.483 111.223 224.333 111.223ZM88.489 213.312L124.089 248.912L69.4223 276.245C71.1865 254.142 77.6895 232.677 88.489 213.312ZM69.4223 301.734L167.2 350.778L107.067 390.867L107.378 391.334C85.4197 366.326 72.1094 334.905 69.4223 301.734ZM202.111 442.778C181.74 439.871 162.156 432.92 144.511 422.334H202.111V442.778ZM202.111 400.112H133.245L202.111 354.2V400.112ZM202.111 327.489L188.622 336.489L93.6223 289L140.645 265.489L202.111 326.934V327.489ZM202.111 295.512L123.378 216.778L202.111 229.889V295.512ZM202.111 206.8L103.622 190.956C128.266 160.643 163.436 140.741 202.111 135.223V206.8ZM360.178 213.312C370.98 232.684 377.483 254.156 379.245 276.267L324.578 248.934L360.178 213.312ZM246.556 135.223C285.228 140.749 320.395 160.65 345.045 190.956L246.556 206.8V135.223ZM246.556 229.889L325.289 216.756L246.556 295.512V229.889ZM246.556 326.934L308 265.489L355.045 289L260.067 336.489L246.556 327.489V326.934ZM246.556 442.778V422.334H304.156C286.511 432.92 266.927 439.871 246.556 442.778ZM246.556 400.112V354.2L315.422 400.112H246.556ZM341.311 391.334L341.622 390.867L281.489 350.778L379.267 301.734C376.559 334.9 363.252 366.315 341.311 391.334Z"
				fill="#FFCC4D"
			/>
			<path
				d="M135.444 177.889V333.444C135.444 357.019 144.809 379.628 161.479 396.298C178.149 412.968 200.758 422.333 224.333 422.333C247.908 422.333 270.517 412.968 287.187 396.298C303.857 379.628 313.222 357.019 313.222 333.444V177.889H135.444Z"
				fill="#D52B1E"
			/>
			<path
				d="M157.667 200.111V333.445C157.667 351.126 164.691 368.083 177.193 380.585C189.696 393.088 206.653 400.111 224.334 400.111C242.015 400.111 258.972 393.088 271.474 380.585C283.977 368.083 291 351.126 291 333.445V200.111H157.667Z"
				fill="white"
			/>
			<path
				d="M179.889 266.777H268.778V311.222H179.889V266.777Z"
				fill="#829ACD"
			/>
			<path
				d="M202.111 244.556H246.556V333.445H202.111V244.556Z"
				fill="#829ACD"
			/>
			<path
				d="M157.667 266.778H179.889V311.222H157.667V266.778ZM202.111 266.778H246.556V311.222H202.111V266.778ZM268.778 266.778H291V311.222H268.778V266.778ZM202.111 333.445H246.556V377.889H202.111V333.445ZM202.111 200.111H246.556V244.556H202.111V200.111Z"
				fill="#003399"
			/>
		</svg>
	);
};
export default flag_pt;
