import {
	ApplySchemaAttributes,
	command,
	CommandFunction,
	DelayedPromiseCreator,
	EditorView,
	ErrorConstant,
	extension,
	ExtensionTag,
	getTextSelection,
	invariant,
	isElementDomNode,
	isNumber,
	NodeExtension,
	NodeExtensionSpec,
	NodeSpecOverride,
	NodeViewMethod,
	omitExtraAttributes,
	PrimitiveSelection,
	ProsemirrorAttributes,
	ProsemirrorNode,
} from "@remirror/core";
import { PasteRule } from "@remirror/pm/paste-rules";
import { insertPoint } from "@remirror/pm/transform";
import { ExtensionImageTheme } from "@remirror/theme";

import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import { Shape } from "../components/EditScreenshot/ScreenshotEditorContext";
import {
	ConvertToGifFunction,
	EditScreenshotFunction,
} from "../components/EditScreenshot/useEditScreenshot";
import { ResizableZoomableImageView } from "./ResizableZoomableImageView";

export type NavigateToTimeFunction = (metadata: ImageExtensionMetadata) => void;

const IMAGE_METADATA_ATTRIBUTE = "data-custom-image";

type DelayedImage = DelayedPromiseCreator<ImageAttributes>;

export interface ImageOptions {
	createPlaceholder?: (view: EditorView, pos: number) => HTMLElement;
	updatePlaceholder?: (
		view: EditorView,
		pos: number,
		element: HTMLElement,
		progress: number,
	) => void;
	destroyPlaceholder?: (view: EditorView, element: HTMLElement) => void;

	/**
	 * The upload handler for the image extension.
	 *
	 * It receives a list of dropped or pasted files and returns a promise for the
	 * attributes which should be used to insert the image into the editor.
	 *
	 * @param files - a list of files to upload.
	 * @param setProgress - the progress handler.
	 */
	uploadHandler?: (files: FileWithProgress[]) => DelayedImage[];

	/**
	 * Enable resizing.
	 *
	 * If true, the image node will be rendered by `nodeView` instead of `toDOM`.
	 *
	 * @defaultValue false
	 */
	enableResizing?: boolean;

	/**
	 * When pasting mixed text and image content (usually from Microsoft Office products) the content on the clipboard is either:
	 *
	 * a. one large image: containing effectively a screenshot of the original content (an image with text in it).
	 * b. HTML content: containing usable text, but images with file protocol URLs (which cannot be resolved due to browser security restrictions).
	 *
	 * If true, this will extract the text from the clipboard data, and drop the images.
	 * If false, the "screenshot" image will be used and the text will be dropped.
	 *
	 * @defaultValue true
	 */
	preferPastedTextContent?: boolean;

	editable?: boolean;

	type: "extension" | "platform" | "videoTranscript" | "helpCenter";
	editScreenshot: EditScreenshotFunction;
	convertToGif: ConvertToGifFunction;
	navigateToTime?: NavigateToTimeFunction | undefined;
}

interface FileWithProgress {
	file: File;
	progress: SetProgress;
}

/**
 * Set the progress.
 *
 * @param progress - a value between `0` and `1`.
 */
type SetProgress = (progress: number) => void;

/**
 * The image extension for placing images into your editor.
 *
 * TODO ->
 * - Captions https://glitch.com/edit/#!/pet-figcaption?path=index.js%3A27%3A1 into a preset
 *
 * TODO => Split this into an image upload extension and image extension.
 * - Add a base64 image
 */
@extension<ImageOptions>({
	defaultOptions: {
		createPlaceholder,
		updatePlaceholder: () => {},
		destroyPlaceholder: () => {},
		uploadHandler,
		enableResizing: false,
		preferPastedTextContent: true,
		editable: false,
		type: "platform",
		editScreenshot: (src: string) => {},
		convertToGif: (metadata: ImageExtensionMetadata) => {},
		//@ts-expect-error
		navigateToTime: undefined,
	},
})
export class SpecialImageExtension extends NodeExtension<ImageOptions> {
	imageStore: { [x: string]: string } = {};
	get name() {
		return "image" as const;
	}

	createTags() {
		return [ExtensionTag.InlineNode, ExtensionTag.Media];
	}

	clearImageStore() {
		this.imageStore = {};
	}

	createNodeSpec(
		extra: ApplySchemaAttributes,
		override: NodeSpecOverride,
	): NodeExtensionSpec {
		const { preferPastedTextContent } = this.options;
		return {
			inline: true,
			draggable: true,
			selectable: false,
			...override,
			attrs: {
				...extra.defaults(),
				alt: { default: "" },
				crop: { default: null },
				height: { default: null },
				width: { default: "100%" },
				rotate: { default: null },
				src: { default: null },
				title: { default: "" },
				fileName: { default: null },
				metadata: {
					default: null,
				},
				resizable: { default: false },
			},
			parseDOM: [
				{
					tag: "img[src]",
					getAttrs: (element) => {
						if (isElementDomNode(element)) {
							const attrs = getImageAttributes({
								element,
								parse: extra.parse,
							});

							if (
								preferPastedTextContent &&
								attrs.src?.startsWith("file:///")
							) {
								return false;
							} // Check if the image source is a data URL for a GIF
							const metadata: ImageExtensionMetadata =
								attrs.metadata || {};
							if (attrs.src?.startsWith("data:image/gif;")) {
								// Add a format attribute to mark it as a GIF
								metadata.format = "gif";
							} else if (attrs.src?.endsWith(".gif")) {
								// Alternatively, check if the image source ends with '.gif'
								metadata.format = "gif";
							}
							attrs.metadata = metadata;

							return attrs;
						}

						return {
							metadata: JSON.stringify({
								stepId: Date.now().toString(),
							}),
						};
					},
				},
				...(override.parseDOM ?? []),
			],
			toDOM: (node) => {
				const attrs = omitExtraAttributes(node.attrs, extra);
				return [
					"img",
					{
						...extra.dom(node),
						...attrs,
						alt: "Image",
						src: this.imageStore[node.attrs.src] ?? node.attrs.src,
						[IMAGE_METADATA_ATTRIBUTE]: JSON.stringify(
							node.attrs.metadata,
						),
					},
				];
			},
		};
	}

	@command()
	resetImageStore(): CommandFunction {
		return ({ tr, dispatch }) => {
			console.log("here1");
			tr.doc.descendants((node) => {
				console.log(node, " NODE");
				if (node.type.name === "image") {
					const src = node.attrs.src;
					if (src) {
						delete this.imageStore[src];
					}
				}
			});
			return true;
		};
	}

	@command()
	insertImage(
		attributes: ImageAttributes,
		selection?: PrimitiveSelection,
	): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(
				selection ?? tr.selection,
				tr.doc,
			);
			const updatedAttributes = {
				...attributes,
				metadata: { stepId: Date.now().toString() },
			};
			const node = this.type.create(updatedAttributes);
			console.log("INSERTING IMAGE", attributes);
			dispatch?.(tr.replaceRangeWith(from, to, node));

			return true;
		};
	}

	/**
	 * Insert an image once the provide promise resolves.
	 */
	@command()
	uploadImage(
		value: DelayedPromiseCreator<ImageAttributes>,
		onElement?: (element: HTMLElement) => void,
	): CommandFunction {
		const { updatePlaceholder, destroyPlaceholder, createPlaceholder } =
			this.options;
		return (props) => {
			const { tr } = props;

			// This is update in the validate hook
			let pos = tr.selection.from;

			return this.store
				.createPlaceholderCommand({
					promise: value,
					placeholder: {
						type: "widget",
						get pos() {
							return pos;
						},
						createElement: (view, pos) => {
							const element = createPlaceholder(view, pos);
							onElement?.(element);
							return element;
						},
						onUpdate: (view, pos, element, data) => {
							updatePlaceholder(view, pos, element, data);
						},
						onDestroy: (view, element) => {
							destroyPlaceholder(view, element);
						},
					},
					onSuccess: (value, range, commandProps) => {
						return this.insertImage(value, range)(commandProps);
					},
				})
				.validate(({ tr, dispatch }) => {
					const insertPos = insertPoint(tr.doc, pos, this.type);

					if (insertPos == null) {
						return false;
					}

					pos = insertPos;

					if (!tr.selection.empty) {
						dispatch?.(tr.deleteSelection());
					}

					return true;
				}, "unshift")

				.generateCommand()(props);
		};
	}

	private fileUploadFileHandler(
		files: File[],
		event: ClipboardEvent | DragEvent,
		pos?: number,
	) {
		const { preferPastedTextContent, uploadHandler } = this.options;

		if (
			preferPastedTextContent &&
			isClipboardEvent(event) &&
			event.clipboardData?.getData("text/plain")
		) {
			return false;
		}

		const { commands, chain } = this.store;
		const filesWithProgress: FileWithProgress[] = files.map(
			(file, index) => ({
				file,
				progress: (progress) => {
					commands.updatePlaceholder(uploads[index], progress);
				},
			}),
		);

		const uploads = uploadHandler(filesWithProgress);

		if (isNumber(pos)) {
			chain.selectText(pos);
		}

		for (const upload of uploads) {
			chain.uploadImage(upload);
		}

		chain.run();

		return true;
	}

	createPasteRules(): PasteRule[] {
		return [
			{
				type: "file",
				regexp: /image/i,
				fileHandler: (props): boolean => {
					const pos = props.type === "drop" ? props.pos : undefined;
					return this.fileUploadFileHandler(
						props.files,
						props.event,
						pos,
					);
				},
			},
		];
	}

	createNodeViews(): NodeViewMethod | Record<string, NodeViewMethod> {
		// if (this.options.enableResizing) {
		//   return (node: ProsemirrorNode, view: EditorView, getPos: boolean | (() => number)) => {
		//     return new ResizableImageView(node, view, getPos as () => number);
		//   };
		// }
		return (
			node: ProsemirrorNode,
			view: EditorView,
			getPos: boolean | (() => number | undefined),
		) => {
			return new ResizableZoomableImageView(
				node,
				view,
				getPos as () => number,
				this.imageStore,
				this.options.editable,
				this.options.type,
				this.options.editScreenshot,
				this.options.convertToGif,
				this.options.navigateToTime,
			);
		};
		// return {};
	}
}

export type ImageAttributes = ProsemirrorAttributes<ImageExtensionAttributes>;

export type SpecialImageCoordinates = { x: number; y: number };

export type ImageExtensionMetadata = {
	stepId?: string;
	// The id that corresponds to the step generated via the recording.
	s3ObjectId?: string;
	// The id of the file stored in S3
	zoomCenter?: SpecialImageCoordinates;
	// The position of the red dot relative to image coordinates
	naturalDimensions?: SpecialImageCoordinates;
	// The natural size of the image
	zoomFactor?: number;
	// The scale to zoom into
	timestamp?: number;
	// The timestamp of the screenshot corresponding to the original recorded video for the guide
	shapes?: Shape[];
	//shapes that are drawn on the screenshot.
	crop?: Crop;
	// crop data of the image
} & (StaticImageExtensionMetadata | GifExtensionMetadata);

export interface StaticImageExtensionMetadata {
	format?: string;
}

export interface GifExtensionMetadata {
	format: "gif";
	editable?: boolean;
	//For the GIF:
	startTime?: number;
	endTime?: number;
	rate?: number;
}

export interface ImageExtensionAttributes {
	align?:
		| "center"
		| "end"
		| "justify"
		| "left"
		| "match-parent"
		| "right"
		| "start";
	alt?: string;
	height?: string | number;
	width?: string | number;
	rotate?: string;
	src: string;
	title?: string;
	/*
  CHANGES
  */
	metadata: ImageExtensionMetadata;
	/** The file name used to create the image. */
	fileName?: string;
}

/**
 * The set of valid image files.
 */
const IMAGE_FILE_TYPES = new Set([
	"image/jpeg",
	"image/gif",
	"image/png",
	"image/jpg",
	"image/svg",
	"image/webp",
]);

/**
 * True when the provided file is an image file.
 */
export function isImageFileType(file: File): boolean {
	return IMAGE_FILE_TYPES.has(file.type);
}

/**
 * Get the width and the height of the image.
 */
function getDimensions(element: HTMLElement) {
	let { width, height } = element.style;
	width = width || element.getAttribute("width") || "100%";
	height = height || element.getAttribute("height") || "";

	return { width, height };
}

/**
 * Retrieve attributes from the dom for the image extension.
 */
function getImageAttributes({
	element,
	parse,
}: {
	element: HTMLElement;
	parse: ApplySchemaAttributes["parse"];
}) {
	const { width, height } = getDimensions(element);

	return {
		...parse(element),
		alt: element.getAttribute("alt") ?? "",
		height: Number.parseInt(height || "0", 10) || null,
		src: element.getAttribute("src") ?? null,
		title: element.getAttribute("title") ?? "",
		width: Number.parseInt(width || "0", 10) || null,
		fileName: element.getAttribute("data-file-name") ?? null,
		metadata: JSON.parse(
			element.getAttribute(IMAGE_METADATA_ATTRIBUTE) ??
				JSON.stringify({ stepId: Date.now().toString() }),
		),
	};
}

function createPlaceholder(_: EditorView, __: number): HTMLElement {
	const element = document.createElement("div");
	element.classList.add(ExtensionImageTheme.IMAGE_LOADER);

	return element;
}

/**
 * The default handler converts the files into their `base64` representations
 * and adds the attributes before inserting them into the editor.
 */
function uploadHandler(files: FileWithProgress[]): DelayedImage[] {
	invariant(files.length > 0, {
		code: ErrorConstant.EXTENSION,
		message:
			"The upload handler was applied for the image extension without any valid files",
	});

	let completed = 0;
	const promises: Array<DelayedPromiseCreator<ImageAttributes>> = [];

	for (const { file, progress } of files) {
		promises.push(
			() =>
				new Promise<ImageAttributes>((resolve) => {
					const reader = new FileReader();

					reader.addEventListener(
						"load",
						(readerEvent) => {
							completed += 1;
							progress(completed / files.length);
							resolve({
								src: readerEvent.target?.result as string,
								fileName: file.name,
								metadata: {},
							});
						},
						{ once: true },
					);

					reader.readAsDataURL(file);
				}),
		);
	}

	return promises;
}

function isClipboardEvent(
	event: ClipboardEvent | DragEvent,
): event is ClipboardEvent {
	return (event as ClipboardEvent).clipboardData !== undefined;
}
