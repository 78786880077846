const rectangleIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="1" y="0.5" width="16" height="16" stroke={color} />
		</svg>
	);
};

export default rectangleIcon;
