import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
	CutsVideoChapters,
	GuideCreationStatus,
	SplitsState,
} from "../../core/types/collections";

// Async thunk to fetch status from API
export const fetchStatus = createAsyncThunk("status/fetchStatus", async () => {
	const response = await fetch("/api/status"); // Replace with your API
	const data = await response.json();
	return data.status;
});

export type LongVideoProcess = {
	collectionId: string;
	collectionName: string;
	status: SplitsState;
	completedGuides: number;
	totalGuides: number;
	splits?: CutsVideoChapters;
	guideCreationStatuses?: GuideCreationStatus[];
	isPolling: boolean;
};
export type LVPUpdate = {
	collectionId: string;
	collectionName?: string;
	status?: SplitsState;
	completedGuides?: number;
	totalGuides?: number;
	splits?: CutsVideoChapters;
	guideCreationStatuses?: GuideCreationStatus[];
	isPolling?: boolean;
};
export const lvsSlice = createSlice({
	name: "lvs",
	initialState: {
		allLongVideoProcesses: [] as LongVideoProcess[],
	},
	reducers: {
		addLongVideoProcess: (state, action: PayloadAction<LVPUpdate>) => {
			state.allLongVideoProcesses.push({
				collectionId: action.payload.collectionId,
				collectionName: action.payload.collectionName ?? "Untitled",
				status: action.payload.status ?? SplitsState.Splitting,
				completedGuides: action.payload.completedGuides ?? 0,
				totalGuides: action.payload.totalGuides ?? 0,
				splits: action.payload.splits,
				guideCreationStatuses: action.payload.guideCreationStatuses,
				isPolling: action.payload.isPolling ?? false,
			});
		},
		deleteLongVideoProcess: (state, action: PayloadAction<string>) => {
			state.allLongVideoProcesses.splice(
				state.allLongVideoProcesses.findIndex(
					(process) => process.collectionId === action.payload,
				),
				1,
			);
		},
		getAllLongVideoProcesses: (state) => {
			return state;
		},
		updateLongVideoProcess: (state, action: PayloadAction<LVPUpdate>) => {
			const index = state.allLongVideoProcesses.findIndex(
				(process) =>
					process.collectionId === action.payload.collectionId,
			);
			if (index !== -1) {
				state.allLongVideoProcesses[index] = {
					...state.allLongVideoProcesses[index],
					...action.payload,
				};
			}
		},
		setAllLongVideoProcesses: (
			state,
			action: PayloadAction<LongVideoProcess[]>,
		) => {
			state.allLongVideoProcesses = action.payload;
		},
	},
});
export const selectLVPStatus = (state: RootState, collectionId: string) =>
	state.lvs.allLongVideoProcesses.find(
		(process) => process.collectionId === collectionId,
	)?.status;
export const selectLVPCompletedGuides = (
	state: RootState,
	collectionId: string,
) =>
	state.lvs.allLongVideoProcesses.find(
		(process) => process.collectionId === collectionId,
	)?.completedGuides;
export const selectLVPTotalGuides = (state: RootState, collectionId: string) =>
	state.lvs.allLongVideoProcesses.find(
		(process) => process.collectionId === collectionId,
	)?.totalGuides;
export const selectLVPSplits = (state: RootState, collectionId: string) =>
	state.lvs.allLongVideoProcesses.find(
		(process) => process.collectionId === collectionId,
	)?.splits;
export const selectLVPGuideCreationStatuses = (
	state: RootState,
	collectionId: string,
) =>
	state.lvs.allLongVideoProcesses.find(
		(process) => process.collectionId === collectionId,
	)?.guideCreationStatuses;
export const selectLvs = (state: RootState) => state.lvs;
export const selectLongVideoProcess = (
	state: RootState,
	collectionId: string,
) =>
	state.lvs.allLongVideoProcesses.find(
		(process) => process.collectionId === collectionId,
	);
export const {
	addLongVideoProcess,
	deleteLongVideoProcess,
	getAllLongVideoProcesses,
	updateLongVideoProcess,
	setAllLongVideoProcesses,
} = lvsSlice.actions;
export default lvsSlice.reducer;
