import { GigaUserApi } from "@giga-user-fern/api";
import { Coordinates } from "@giga-user-fern/api/types/api";
import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import React from "react";

export type Shape = {
	id: string;
	geo:
		| "rectangle"
		| "circle"
		| "arrow"
		| "blur"
		| "text"
		| "image"
		| "logo"
		| "spotlight"
		| "callout";
	version?: string;
	position: number[]; //top-left : [x,y] fractional position.
	size: number[]; //width, height [fractional width and height].
	data?: any;
	textdata?: GigaUserApi.TextData;
	shapedata?: GigaUserApi.ShapeData;
	imagedata?: GigaUserApi.ImageData;
} & ShapeDisplayProperties;

export type ShapeDisplayProperties = {
	stroke?: string;
	strokeWidth?: number;
	fill?: string;
	fillOpacity?: number;
};

export type UpdateShapeProperties = {
	version?: string; // When migrating an old shape by transforming data after resaving
	position?: [number, number]; //top-left : [x,y] fractional position.
	size?: [number, number]; //width, height [fractional width and height].
	angle?: number; //rotation angle in degrees.
} & ShapeDisplayProperties;

export type UpdateShapeFunction = (
	id: string,
	updatedProperties: UpdateShapeProperties,
) => void;

type ScreenshotEditorContextValue = {
	//refs
	imgRef: React.RefObject<HTMLDivElement> | null;

	//state
	zoomFactor: number;
	position: { x: number; y: number };
	activeShapeId: string | null;
	crop: Crop;

	//functions
	updateShape: UpdateShapeFunction;
	getFractionalCoords: (e: React.MouseEvent) => { x: number; y: number };
	getRelativeCoords: (pos: Coordinates) => Coordinates;
	getAbsoluteCoords: (pos: Coordinates) => Coordinates;
	setActiveShapeId: (a: string | null) => void;
};

const ScreenshotEditorContext =
	React.createContext<ScreenshotEditorContextValue>({
		//refs:
		imgRef: null,

		//state
		zoomFactor: 1,
		position: { x: 0, y: 0 },
		activeShapeId: null,
		crop: { position: [0, 0], size: [1, 1] },

		//functions
		updateShape: (id, shape) => {},
		getFractionalCoords: (e) => ({ x: 0, y: 0 }),
		getRelativeCoords: (pos) => ({ x: 0, y: 0 }),
		getAbsoluteCoords: (pos) => ({ x: 0, y: 0 }),
		setActiveShapeId: (a) => {},
	});

export default ScreenshotEditorContext;
