import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { globalSaver, RegionalSaver } from "@gigauser/common/src/network/saver";
import { AnalyticsLogger } from "../../core/analytics/analytics";

interface BackendState {
	value: {
		saver: RegionalSaver;
		edits: AnalyticsLogger;
	};
}

const initialState = {
	value: {
		saver: globalSaver.selectedRegionalSaver,
		analytics: new AnalyticsLogger(),
	},
};

export const saverSlice = createSlice({
	name: "backend",
	initialState,
	reducers: {
		setSaver: (state, action: PayloadAction<RegionalSaver>) => {
			state.value.saver = action.payload;
		},
		setAnalytics: (state, action: PayloadAction<AnalyticsLogger>) => {
			state.value.analytics = action.payload;
		},
	},
});

export const { setSaver, setAnalytics } = saverSlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectSaver = (state: RootState) => state.backend.value.saver;
export const selectAnalytics = (state: RootState) =>
	state.backend.value.analytics;
export default saverSlice.reducer;
