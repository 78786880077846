const zoomoutIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.8752 8.74969C11.8752 8.91545 11.8093 9.07442 11.6921 9.19163C11.5749 9.30884 11.4159 9.37469 11.2502 9.37469H6.25018C6.08442 9.37469 5.92545 9.30884 5.80824 9.19163C5.69103 9.07442 5.62518 8.91545 5.62518 8.74969C5.62518 8.58393 5.69103 8.42496 5.80824 8.30775C5.92545 8.19054 6.08442 8.12469 6.25018 8.12469H11.2502C11.4159 8.12469 11.5749 8.19054 11.6921 8.30775C11.8093 8.42496 11.8752 8.58393 11.8752 8.74969ZM17.9424 17.9419C17.8843 18 17.8154 18.0461 17.7395 18.0775C17.6636 18.109 17.5823 18.1252 17.5002 18.1252C17.418 18.1252 17.3367 18.109 17.2608 18.0775C17.185 18.0461 17.116 18 17.058 17.9419L13.1471 14.0302C11.7859 15.1639 10.04 15.7293 8.27263 15.6086C6.50524 15.488 4.85241 14.6906 3.65797 13.3823C2.46353 12.074 1.81944 10.3557 1.85969 8.58462C1.89994 6.81357 2.62143 5.12622 3.87407 3.87358C5.12671 2.62094 6.81406 1.89945 8.5851 1.8592C10.3561 1.81895 12.0745 2.46304 13.3828 3.65748C14.691 4.85192 15.4884 6.50475 15.6091 8.27214C15.7298 10.0395 15.1644 11.7854 14.0306 13.1466L17.9424 17.0575C18.0005 17.1155 18.0466 17.1845 18.078 17.2604C18.1095 17.3362 18.1257 17.4176 18.1257 17.4997C18.1257 17.5818 18.1095 17.6632 18.078 17.739C18.0466 17.8149 18.0005 17.8838 17.9424 17.9419ZM8.75018 14.3747C9.8627 14.3747 10.9502 14.0448 11.8753 13.4267C12.8003 12.8086 13.5213 11.9301 13.947 10.9023C14.3727 9.87445 14.4841 8.74345 14.2671 7.65231C14.0501 6.56116 13.5143 5.55888 12.7277 4.77221C11.941 3.98554 10.9387 3.44981 9.84756 3.23277C8.75642 3.01573 7.62542 3.12712 6.59758 3.55287C5.56975 3.97861 4.69124 4.69958 4.07316 5.62461C3.45508 6.54963 3.12518 7.63717 3.12518 8.74969C3.12683 10.241 3.72 11.6708 4.77453 12.7253C5.82906 13.7799 7.25884 14.373 8.75018 14.3747Z"
				fill={color}
			/>
		</svg>
	);
};
export default zoomoutIcon;
