const circleIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 15C0 6.705 6.705 0 15 0C23.295 0 30 6.705 30 15C30 23.295 23.295 30 15 30C6.705 30 0 23.295 0 15ZM15 26.667C21.443 26.667 26.667 21.443 26.667 15C26.667 8.557 21.443 3.333 15 3.333C8.557 3.333 3.333 8.557 3.333 15C3.333 21.443 8.557 26.667 15 26.667Z"
				fill={color}
			/>
		</svg>
	);
};

export default circleIcon;
