import { GigaUserApi } from "@giga-user-fern/api";
import { OriginalEdits } from "@giga-user-fern/api/types/api/resources/guides";
import {
	Crop,
	VideoEdits,
} from "@giga-user-fern/api/types/api/resources/video";

// export type FracPosition = {
//     x: number, y: number //fraction (<1)
// }

// export interface ZoomEdit {

//     startTime: number, //finishes the zoomin transition at the start time
//     endTime: number, //starts zooming back out at the end time
//     zoomCenter: FracPosition,
//     zoomFactor: number //make zoomFactor
// }

// export type VideoEdits  = {

//     "version": "10-05-2023",
//     "intro"?: Cover,
//     "outro"?: Cover,
//     "background"?:Background,
//     "zooms"?: ZoomEdit[],

// }

export const dummyVideoEdits: VideoEdits = {
	version: "may012024",
};

export const DEFAULT_CROP: Crop = {
	position: [0, 0],
	size: [1, 1],
};
