import { GigaUserApi } from "@giga-user-fern/api";
import { Background, Cover, Id } from "@giga-user-fern/api/types/api";
import { OrganizationLogo } from "@giga-user-fern/api/types/api/resources/organizations";
import { v4 as uuidv4 } from "uuid";

export const DEFAULT_BACKGROUND_SRC =
	"https://public-assets.clueso.io/web_assets/premade_backgrounds/white.png";

export const defaultIntroCover: Cover = {
	visible: false,
	duration: 1.5,
	intersectionDuration: 0,
	backgroundSrc: DEFAULT_BACKGROUND_SRC,
	background: {
		color: "#ffffff",
		src: DEFAULT_BACKGROUND_SRC,
		isStarred: false,
		id: GigaUserApi.Id("dummy"),
	},
};

export const emptyIntroCover: Cover = {
	...defaultIntroCover,
	visible: false,
};

export const defaultOutroCover: Cover = {
	visible: false,
	duration: 1.5,
	intersectionDuration: 0,
	backgroundSrc: DEFAULT_BACKGROUND_SRC,
	background: {
		color: "#ffffff",
		src: DEFAULT_BACKGROUND_SRC,
		isStarred: false,
		id: GigaUserApi.Id("dummy"),
	},
};

export const emptyOutroCover: Cover = {
	...defaultOutroCover,
	visible: false,
};

export const defaultBackground: Background = {
	visible: true,
	padding: 8,
	borderRadius: 0,
	shadow: 30,
	src: DEFAULT_BACKGROUND_SRC,
};

export const emptyBackground: Background = {
	...defaultBackground,
	visible: false,
};

export const makeFromScratchIntro = (
	intro: Cover,
	logo: OrganizationLogo,
	title = "My project Title",
): Cover => {
	return {
		...intro,
		coverEdits: [
			{
				id: GigaUserApi.Id(uuidv4()) as Id,
				startTime: 50.0,
				endTime: 70.0,
				geo: "image",
				position: [0.3782749069474988, 0.14735674608059313],
				size: [0.24345018610500235, 0.01],
				imagedata: {
					src:
						logo?.logoUrl ??
						"https://public-assets.clueso.io/default/video_assets/YourCompanyLight.svg",
					naturalHeight: logo?.height ?? 100.0,
					naturalWidth: logo?.width ?? 455.0,
					logoImmutableName: logo?.immutableName,
					shadow: 0,
					cornerRadius: 5.0,
					type: "logo",
					id: logo?.id ?? (GigaUserApi.Id(uuidv4()) as Id),
				},
			},
			{
				id: GigaUserApi.Id(uuidv4()) as Id,
				startTime: 0.0,
				endTime: 0.5,
				geo: "text",
				position: [0.5, 0.5],
				size: [0.8380605021510565, 0.09100019606073775],
				textdata: {
					text: title,
					lines: [
						`${title.split(" ").slice(0, 4).join(" ")}`,
						`${title.split(" ").slice(4).join(" ")}`,
					],
					fontSize: 0.08242386672969144,
					alignment: "center",
					backgroundColor: "#000000",
					backgroundOpacity: 0.0,
					textColor: "#000000",
					font: "Figtree",
				},
			},
		],
		background: {
			...intro.background,
			backgroundType: "image",
			src: DEFAULT_BACKGROUND_SRC,
			color: "#ffffff",
			id: GigaUserApi.Id(uuidv4()) as Id,
			isStarred: false,
			isDeleted: false,
		},
		visible: true,
		backgroundSrc: DEFAULT_BACKGROUND_SRC,
		type: "image",
		duration: 1.5,
	};
};

export const makeFromScratchOutro = (
	outro: Cover,
	logo: OrganizationLogo,
): Cover => {
	return {
		...outro,
		coverEdits: [
			{
				id: GigaUserApi.Id(uuidv4()) as Id,
				startTime: 50.0,
				endTime: 70.0,
				geo: "image",
				position: [0.3782749069474988, 0.14735674608059313],
				size: [0.24345018610500235, 0.01],
				imagedata: {
					src:
						logo?.logoUrl ??
						"https://public-assets.clueso.io/premade_backgrounds/yourcompany.png",
					naturalHeight: logo?.height ?? 100.0,
					naturalWidth: logo?.width ?? 455.0,
					logoImmutableName: logo?.immutableName ?? "light_mode",
					shadow: 0,
					cornerRadius: 5.0,
					type: "logo",
					id: logo?.id ?? (GigaUserApi.Id(uuidv4()) as Id),
				},
			},
			{
				id: GigaUserApi.Id(uuidv4()) as Id,
				startTime: 0.0,
				endTime: 0.5,
				geo: "text",
				position: [0.5, 0.5],
				size: [0.8380605021510565, 0.09100019606073775],
				textdata: {
					text: "Thanks for watching!",
					lines: ["Thanks for watching!"],
					fontSize: 0.08242386672969144,
					alignment: "center",
					backgroundColor: "#000000",
					backgroundOpacity: 0.0,
					textColor: "#000000",
					font: "Figtree",
				},
			},
		],
		background: {
			...outro.background,
			backgroundType: "image",
			src: DEFAULT_BACKGROUND_SRC,
			color: "#ffffff",
			id: GigaUserApi.Id(uuidv4()) as Id,
			isStarred: false,
			isDeleted: false,
		},
		visible: true,
		backgroundSrc: DEFAULT_BACKGROUND_SRC,
		type: "image",
		duration: 1.5,
	};
};
