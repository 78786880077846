const arrowIcon = (color = "currentColor") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.1253 5.52734V13.6523C16.1253 13.8181 16.0595 13.9771 15.9423 14.0943C15.8251 14.2115 15.6661 14.2773 15.5003 14.2773C15.3346 14.2773 15.1756 14.2115 15.0584 14.0943C14.9412 13.9771 14.8753 13.8181 14.8753 13.6523V7.03594L5.94254 15.9695C5.82526 16.0868 5.6662 16.1527 5.50035 16.1527C5.3345 16.1527 5.17544 16.0868 5.05816 15.9695C4.94088 15.8523 4.875 15.6932 4.875 15.5273C4.875 15.3615 4.94088 15.2024 5.05816 15.0852L13.9918 6.15234H7.37535C7.20959 6.15234 7.05062 6.0865 6.93341 5.96929C6.8162 5.85208 6.75035 5.6931 6.75035 5.52734C6.75035 5.36158 6.8162 5.20261 6.93341 5.0854C7.05062 4.96819 7.20959 4.90234 7.37535 4.90234H15.5003C15.6661 4.90234 15.8251 4.96819 15.9423 5.0854C16.0595 5.20261 16.1253 5.36158 16.1253 5.52734Z"
				fill={color}
			/>
		</svg>
	);
};

export default arrowIcon;
