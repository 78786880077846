import { GigaUserApi } from "@giga-user-fern/api";
import { Cover } from "@giga-user-fern/api/types/api";
import { printText } from "./canvasText";
import {
	fractionalCoordsToCanvasCoordsUtil,
	getScaleFactor,
} from "./canvasUtils";
import { drawCoverElements } from "./elements/canvasElement";
import { CanvasImageAsset } from "./immutables/assets/CanvasAssets";

type IntroOptions = {
	width: number;
	height: number;
	imageDataMap: Map<GigaUserApi.Id, CanvasImageAsset> | null;
	background: HTMLImageElement | null;
	getFont: (name: string | undefined) => string;
	logo?: HTMLImageElement | null;
};

export const drawIntro = async (
	canvas: HTMLCanvasElement,
	intro: Cover,
	options: IntroOptions,
): Promise<void> => {
	const { width, height, background, imageDataMap, getFont } = options;
	if (!canvas) return;

	const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

	if (intro.background && background) {
		ctx.drawImage(background, 0, 0, width, height);
	}

	const xPos = 120;

	const { f, wf, hf } = getScaleFactor(width, height);

	const fontColor = intro.fontColor || "#4f46e5";
	const textAlign = intro.alignment || "left";

	if (intro.type === "video") {
		// render a non-migrated intro.

		const printIntroText = async () => {
			if (!intro.text1) return;
			const font = getFont(intro.font);
			if (!font || typeof font === "boolean")
				throw new Error("Font not found");

			let weight = 0;
			if (font.split("-").slice(-1)[0] === "regular") {
				weight = 500;
			} else {
				weight = Number.parseInt(font.split("-").slice(-1)[0]);
			}
			if (Number.isNaN(weight)) {
				weight = 600;
			}

			printText(
				ctx,
				intro.text1,
				{ width, height },
				{
					color: fontColor,
					x: textAlign === "center" ? "center" : xPos * wf,
					y: "center",
					maxWidth: 1400 * wf,
					fontSize: 64 * f,
					fontWeight: weight,
					font: font,
				},
			);
		};

		await printIntroText();
	}

	if (intro.coverEdits && canvas) {
		//render a migrated intro
		drawCoverElements({
			allEleEdits: intro.coverEdits,
			canvas,
			fractionalCoordsToCanvasCoords: fractionalCoordsToCanvasCoordsUtil,
			imageDataMap: imageDataMap ?? new Map(),
			showLogo: intro.showLogo ?? false,
			getFont,
		});
	}
};

export const printIntroClipTitle = (
	//This funcition ONLY runs for intro VIDEOs that have text.
	ctx: CanvasRenderingContext2D,
	currTime: number,
	intro: Cover,
	options: IntroOptions,
): void => {
	const textElement = intro.coverEdits?.find(
		(edit) => edit.geo === "text" && edit.textdata?.hideText === false,
	);

	if (!textElement) return;

	const { width, height, getFont } = options;

	const customColor = textElement.textdata?.textColor;

	const { f, wf } = getScaleFactor(width, height);

	const startTime = textElement.textdata?.textStartTime;
	const endTime = undefined;

	let fillStyle = "#4f46e5";
	if (customColor) fillStyle = customColor;

	if (startTime && currTime < startTime) return;
	if (endTime && currTime > endTime) return;

	const printTitle = async () => {
		if (!textElement) return;

		const fontName = getFont(
			textElement.textdata?.font || "League Spartan",
		);
		if (!fontName || typeof fontName === "boolean")
			throw new Error("Font not found");

		printText(
			ctx,
			textElement.textdata?.text ?? "",
			{ width, height },
			{
				color: fillStyle,
				x: "center",
				y: "center",
				maxWidth: 1400 * wf,
				fontSize: 64 * f,
				fontWeight: 600,
				font: fontName,
			},
		);
	};

	printTitle();
};
