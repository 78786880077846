import { useEffect } from "react";

const useScrollTracker = (
	containerRef: React.RefObject<HTMLDivElement>,
	onScrollUpdate: (percentage: number, guideId: string) => void,
	guideId: string,
) => {
	useEffect(() => {
		const container = containerRef.current;
		if (!container) return;

		const handleScroll = () => {
			const scrollTop = container.scrollTop;
			const scrollHeight =
				container.scrollHeight - container.clientHeight;

			if (scrollHeight === 0) return;

			const scrolledPercentage = Math.round(
				(scrollTop / scrollHeight) * 100,
			);
			onScrollUpdate(scrolledPercentage, guideId); // Send event on every scroll
		};

		container.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, [containerRef, onScrollUpdate]);
};

export default useScrollTracker;
