import { Coordinates, Cover } from "@giga-user-fern/api/types/api";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { dummyVideoEdits } from "../../../videoEditTypes/core";
import { CanvasAssets } from "../assets/CanvasAssets";
import feCanvasAssets from "../assets/FeCanvasAssets";

// IMPORTANT NOTE: THE CALCULATIONS FOR CANVAS DIMS IS REPLCIATED ON THE BE REPO (dimensionsCalculation.py)
// IF YOU MAKE CHANGES HERE, YOU MUST MAKE CHANGES THERE
export class CanvasCoordinates {
	/**
	 *
	 * COORDINATE SYSTEM CONVERTERS
	 *
	 * fractionalCoords: {x, y} in the range [0,1] representing a fraction of the
	 * total width and height of only the screenclip in the canvas
	 * canvasCoords: {x, y} in the canvas coordinate system
	 * pixels: {x,y} pixels at the DOM level.
	 * pixels and canvas coords are off by just a scale factor
	 *
	 */

	videoEdits: VideoEdits;

	videoRenderWidth: number;
	videoRenderHeight: number;

	assets: CanvasAssets;

	canvasHeight: number;
	canvasWidth: number;

	constructor(videoEdits: VideoEdits, assets: CanvasAssets) {
		this.videoEdits = videoEdits;
		this.assets = assets;

		//canvas dims
		const canvasDims = this.initCanvasDims();
		this.canvasWidth = canvasDims.x;
		this.canvasHeight = canvasDims.y;

		//screenclip dimensions in the canvas
		const videoRenderDims = this.initVideoRenderDims();
		this.videoRenderWidth = videoRenderDims.x;
		this.videoRenderHeight = videoRenderDims.y;
	}

	getPaddingFactor: () => number = () => {
		let paddingFactor = 0;

		if (!this.videoEdits.background?.visible) {
			paddingFactor = 0;
		} else {
			if (this.videoEdits.background.padding) {
				paddingFactor = this.videoEdits.background.padding / 100;
			} else {
				paddingFactor = 0;
			}
		}

		return paddingFactor;
	};

	//#region CALCULATE DIMESIONS

	initCanvasDims: (covers?: { intro?: Cover; outro?: Cover }) => Coordinates =
		// IMPORTANT NOTE: THE CALCULATIONS FOR CANVAS DIMS IS REPLCIATED ON THE BE REPO (dimensionsCalculation.py)
		// IF YOU MAKE CHANGES HERE, YOU MUST MAKE CHANGES THERE
		(covers) => {
			let _canvasWidth = this.assets.screenclip.naturalWidth;
			let _canvasHeight = this.assets.screenclip.naturalHeight;

			const { videoEdits } = this;

			let _videoWidth = this.assets.screenclip.naturalWidth;
			let _videoHeight = this.assets.screenclip.naturalHeight;

			if (videoEdits.crop) {
				_videoWidth = _videoWidth * videoEdits.crop.size[0];
				_videoHeight = _videoHeight * videoEdits.crop.size[1];
			}

			const paddingFactor = this.getPaddingFactor();

			_videoWidth = _videoWidth * (1 + paddingFactor);
			_videoHeight = _videoHeight * (1 + paddingFactor);

			const canvas_width =
				Math.floor(_canvasWidth) + (Math.floor(_canvasWidth) % 2);
			const canvas_height =
				Math.floor(_canvasHeight) + (Math.floor(_canvasHeight) % 2);

			let desiredAspectRatio = canvas_width / canvas_height;

			if (
				this.videoEdits.aspectRatio?.width &&
				this.videoEdits.aspectRatio?.height
			) {
				desiredAspectRatio =
					this.videoEdits.aspectRatio.width /
					this.videoEdits.aspectRatio.height;
			}

			if (_videoWidth / _videoHeight > desiredAspectRatio) {
				// Video is wider than the desired aspect ratio, adjust height
				_canvasWidth = _videoWidth;
				_canvasHeight = Math.max(
					_videoWidth / desiredAspectRatio,
					_videoHeight,
				);
			} else {
				// Video is taller than the desired aspect ratio, adjust width
				_canvasHeight = _videoHeight;
				_canvasWidth = Math.max(
					_videoHeight * desiredAspectRatio,
					_videoWidth,
				);
			}

			return {
				x: Math.floor(_canvasWidth) + (Math.floor(_canvasWidth) % 2),
				y: Math.floor(_canvasHeight) + (Math.floor(_canvasHeight) % 2),
			};
		};

	initVideoRenderDims: () => Coordinates = () => {
		let _w = 0;
		let _h = 0;

		const paddingFactor = this.getPaddingFactor();

		let { naturalWidth, naturalHeight } = this.assets.screenclip;

		const crop = this.videoEdits.crop;

		if (crop) {
			naturalWidth *= crop.size[0];
			naturalHeight *= crop.size[1];
		}

		const originalAspectRatio = naturalWidth / naturalHeight;
		const canvasAspectRatio = this.canvasWidth / this.canvasHeight;

		if (originalAspectRatio > canvasAspectRatio) {
			_w = this.canvasWidth * (1 - paddingFactor);
			_h = _w / originalAspectRatio;
		} else {
			_h = this.canvasHeight * (1 - paddingFactor);
			_w = _h * originalAspectRatio;
		}

		_h = Math.floor(_h) + (Math.floor(_h) % 2);
		_w = Math.floor(_w) + (Math.floor(_w) % 2);

		return {
			x: _w,
			y: _h,
		};
	};

	//#endregion

	//#region COORDINATE SYSTEM CONVERTERS
	fractionalCoordsToCanvasCoords: (
		pos: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos, wrtVideo) => {
		/**
		 * @param wrtVideo: if true, the fractional value is with respect to the video and not the full canvas
		 * @returns {x, y} with respect to the full canvas
		 */

		let w = this.canvasWidth;
		let h = this.canvasHeight;

		let pw = 0;
		let ph = 0;

		if (wrtVideo) {
			w = this.videoRenderWidth;
			h = this.videoRenderHeight;

			pw = (this.canvasWidth - this.videoRenderWidth) / 2;
			ph = (this.canvasHeight - this.videoRenderHeight) / 2;
		}

		const canvas_x = pw + w * pos.x;
		const canvas_y = ph + h * pos.y;

		return { x: canvas_x, y: canvas_y };
	};

	canvasCoordsToPixels: (
		pos_c: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos_c, wrtVideo = false) => {
		/**
		 * @param pos_c: {x, y} with respect to the full canvas
		 * @param wrtVideo: if true, the returned fractional value is with respect to the video and not the full canvas
		 */

		const w = this.canvasWidth;
		const h = this.canvasHeight;

		let pw = 0;
		let ph = 0;

		if (!this.videoEdits.background?.visible) {
			pw = 0;
			ph = 0;
		}

		const pixels_x = pw + w * pos_c.x;
		const pixels_y = ph + h * pos_c.y;

		return { x: pixels_x, y: pixels_y };
	};

	canvasCoordsToFractionalCoords: (
		pos_c: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos_c, wrtVideo = false) => {
		/**
		 * @param pos_c: {x, y} with respect to the full canvas
		 * @param wrtVideo: if true, the returned fractional value is with respect to the video and not the full canvas
		 */

		let w = this.canvasWidth;
		let h = this.canvasHeight;

		let pw = 0;
		let ph = 0;

		if (wrtVideo) {
			// if ignore offset is true, we are working with pure video file
			// which is taking the complete canvas
			w = this.videoRenderWidth;
			h = this.videoRenderHeight;

			pw = (this.canvasWidth - this.videoRenderWidth) / 2;
			ph = (this.canvasHeight - this.videoRenderHeight) / 2;
		} else {
			pw = 0;
			ph = 0;
		}

		const fractional_x = (pos_c.x - pw) / w;
		const fractional_y = (pos_c.y - ph) / h;

		return { x: fractional_x, y: fractional_y };
	};

	//#endregion
}
let canvasCoordinates: CanvasCoordinates = null as unknown as CanvasCoordinates;
if (typeof globalThis !== "undefined" && globalThis.window) {
	canvasCoordinates = new CanvasCoordinates(dummyVideoEdits, feCanvasAssets);
}

export default canvasCoordinates;
