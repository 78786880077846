import { SubtitlesSettings } from "@giga-user-fern/api/types/api/resources/video";

export interface RendererSubtitle {
	startTime: number;
	endTime: number;
	text: string;
}

export const DEFAULT_SUBTITLES: SubtitlesSettings = {
	showSubtitles: false,
	size: 62, //ideal size for 1920 x 1080 videos
};

export const parseTimeToSeconds = (timeStr: string): number => {
	const [hours, minutes, seconds] = timeStr.split(":");
	const [secs, ms] = seconds.split(",");
	return (
		Number.parseInt(hours) * 3600 +
		Number.parseInt(minutes) * 60 +
		Number.parseInt(secs) +
		Number.parseInt(ms) / 1000
	);
};

export const parseSubtitles = (srtString: string): RendererSubtitle[] => {
	/**
	 * @input srtString: a stringified SRT file.
	 * @output subtitles: an array of subtitles that can be used in the renderer.
	 */

	const subtitles: RendererSubtitle[] = [];
	const lines = srtString.split(/\r?\n/); // Properly split by lines
	let i = 0;

	while (i < lines.length) {
		// Step 1: Parse index (skip empty lines)
		while (i < lines.length && !lines[i].trim()) {
			i++; // Skip blank lines
		}
		if (i >= lines.length) break;

		const index = Number.parseInt(lines[i], 10);
		if (Number.isNaN(index)) {
			i++; // If it's not a valid number, move on
			continue;
		}

		i++; // Move to timestamp line
		if (i >= lines.length) break;

		// Step 2: Parse time range
		const timeRange = lines[i].split(" --> ");
		if (timeRange.length !== 2) {
			i++;
			continue;
		}
		const startTime = parseTimeToSeconds(timeRange[0]);
		const endTime = parseTimeToSeconds(timeRange[1]);

		i++; // Move to subtitle text
		if (i >= lines.length) break;

		// Step 3: Collect text until we hit a new index or run out of lines
		let text = "";
		while (
			i < lines.length &&
			!Number.isInteger(Number.parseInt(lines[i], 10)) && // Stop at a new index
			!lines[i].includes("-->") // Stop at a timestamp
		) {
			text += lines[i] + " ";
			i++;
		}

		subtitles.push({
			startTime,
			endTime,
			text: text.trim(),
		});
	}

	return subtitles;
};
