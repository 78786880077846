import { ElementEdit } from "@giga-user-fern/api/types/api";
import { DEFAULT_CROP } from "../../videoEditTypes/core";
import { FractionalCoordsToCanvasCoordsFunction } from "../canvasUtils";
import { CanvasImageAsset } from "../immutables/assets/CanvasAssets";
import { canvasPrintText } from "./canvasTextbox";

type DrawElementsProps = {
	allEleEdits: ElementEdit[];
	canvas: HTMLCanvasElement;
	fractionalCoordsToCanvasCoords: FractionalCoordsToCanvasCoordsFunction;
	showLogo: boolean;
	getFont: (name: string | undefined) => string;
	cp?: any;
	imageDataMap: Map<string, CanvasImageAsset>;
};

export const drawCoverElements = (props: DrawElementsProps) => {
	//this is used only in covers.

	const { allEleEdits, canvas, fractionalCoordsToCanvasCoords } = props;

	let eleList: ElementEdit[] = [];

	eleList = allEleEdits;
	const elementEdits = eleList;

	if (!canvas) return;

	const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

	const mediaRenderHeight = canvas.height;
	const mediaRenderWidth = canvas.width;

	if (elementEdits.length) {
		for (const ele of elementEdits) {
			const { position, size, geo } = ele;
			const [x, y] = position;
			const [width, height] = size;

			const rect_coords = fractionalCoordsToCanvasCoords(
				{ x, y },
				canvas,
			);

			const rectX = rect_coords.x;
			const rectY = rect_coords.y;
			const rectWidth = width * mediaRenderWidth; ///adj_zf
			const rectHeight = height * mediaRenderHeight; // / adj_zf;

			ctx.save();

			if (geo === "text") {
				if (!canvas) return;
				const attributeWidth = canvas.width;

				let displayedWidth: number;
				try {
					displayedWidth = canvas.getBoundingClientRect().width;
				} catch (_) {
					displayedWidth = attributeWidth;
				}

				const scalingFactor =
					Number(attributeWidth) / Number(displayedWidth ?? 1);

				if (ele.textdata) {
					canvasPrintText(
						canvas,
						{
							x: rectX,
							y: rectY,
							w: rectWidth,
							h: rectHeight,
							scalingFactor,
						},
						ele,
						ele.textdata.font ?? "Inter",
						props.getFont,
					);
				}
			} else if (geo === "image") {
				if (ele.imagedata?.src) {
					const image = props.imageDataMap.get(ele.id);

					if (image) {
						const { naturalHeight, naturalWidth } = ele.imagedata;

						const imageData = ele.imagedata;
						if (!imageData) return;

						// Incorporate crop info from the CropImage component
						const castCrop = imageData.crop || DEFAULT_CROP;
						const cropX = castCrop.position[0];
						const cropY = castCrop.position[1];
						const cropWidthFrac = castCrop.size[0];
						const cropHeightFrac = castCrop.size[1];

						const sx = cropX * naturalWidth;
						const sy = cropY * naturalHeight;
						const sWidth = cropWidthFrac * naturalWidth;
						const sHeight = cropHeightFrac * naturalHeight;

						// Compute destination width based on element size and canvas width
						const rectWidth = width * mediaRenderWidth;
						// Compute destination height based on the aspect ratio of the cropped region
						const rectHeight = rectWidth * (sHeight / sWidth);

						const castCornerRadius =
							Number(imageData.cornerRadius) || 0;
						const castShadow = Number(imageData.shadow) || 0;

						ctx.save();
						ctx.beginPath();

						const r =
							castCornerRadius > 0
								? (castCornerRadius *
										Math.min(rectWidth, rectHeight)) /
									100
								: 0;
						if (r > 0) {
							ctx.moveTo(rectX + r, rectY);
							ctx.lineTo(rectX + rectWidth - r, rectY);
							ctx.quadraticCurveTo(
								rectX + rectWidth,
								rectY,
								rectX + rectWidth,
								rectY + r,
							);
							ctx.lineTo(
								rectX + rectWidth,
								rectY + rectHeight - r,
							);
							ctx.quadraticCurveTo(
								rectX + rectWidth,
								rectY + rectHeight,
								rectX + rectWidth - r,
								rectY + rectHeight,
							);
							ctx.lineTo(rectX + r, rectY + rectHeight);
							ctx.quadraticCurveTo(
								rectX,
								rectY + rectHeight,
								rectX,
								rectY + rectHeight - r,
							);
							ctx.lineTo(rectX, rectY + r);
							ctx.quadraticCurveTo(
								rectX,
								rectY,
								rectX + r,
								rectY,
							);
						} else {
							ctx.rect(rectX, rectY, rectWidth, rectHeight);
						}
						ctx.closePath();

						if (castShadow > 0) {
							ctx.save();
							ctx.shadowColor = "black";
							ctx.shadowBlur = castShadow;
							ctx.shadowOffsetX = 0;
							ctx.shadowOffsetY = 0;

							// Calculate opacity based on shadow intensity
							const shadowOpacity = Math.min(
								0.8,
								castShadow / 100,
							);
							ctx.fillStyle = `rgba(0,0,0,${shadowOpacity})`;
							ctx.fill();
							ctx.restore();
						}

						ctx.save();
						ctx.clip();
						ctx.drawImage(
							image.src,
							sx,
							sy,
							sWidth,
							sHeight,
							rectX,
							rectY,
							rectWidth,
							rectHeight,
						);
						ctx.restore();
					}
				}
			}

			ctx.restore();
		}
	}

	return elementEdits;
};
