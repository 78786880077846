import React, { useState } from "react";

import "./Icon.css";
import WhiteHoverTip from "../WhiteHoverTip/WhiteHoverTip";

type IconProps = {
	children: React.ReactNode;
	className?: string;
	hoverTip?: string;
	hideHoverTipArrow?: boolean;
	hoverPosition?: "bottom" | "top";
	disabled?: boolean;
	disabledHoverTipText?: string;
	ref?: React.RefObject<HTMLDivElement>;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const Icon: React.FC<IconProps> = ({
	className,
	hoverTip,
	hoverPosition = "bottom",
	disabledHoverTipText,
	ref,
	...props
}) => {
	const [isHovering, setIsHovering] = useState<boolean>(false);

	const onMouseEnter = (e: any) => {
		setIsHovering(true);
	};
	const onMouseLeave = () => {
		setIsHovering(false);
	};

	const hoverTipComponent =
		isHovering &&
		hoverTip &&
		(!props.disabled || (props.disabled && disabledHoverTipText)) ? (
			<div className="WhiteHoverTip">
				<WhiteHoverTip
					text={
						props.disabled && disabledHoverTipText
							? disabledHoverTipText
							: hoverTip
					}
					hoverPosition={hoverPosition}
				/>
			</div>
		) : null;

	return (
		<div
			ref={ref ?? null}
			className={`gigauser-Icon-parent ${props.disabled ? "icon-disabled" : "icon-active"}`}
		>
			<div
				className={`gigauser-Icon ${className} `}
				{...props}
				onMouseEnter={(e) => {
					onMouseEnter(e);
				}}
				onMouseLeave={onMouseLeave}
				onClick={
					props.disabled
						? () => {}
						: props.onClick
							? props.onClick
							: () => {}
				}
			>
				{props.children}
			</div>
			{hoverTipComponent}
		</div>
	);
};
export default Icon;
