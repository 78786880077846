const strokeWidthIcon = (color = "#ffffff") => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 33 33"
			fill="none"
		>
			<g clipPath="url(#clip0_1290_204)">
				<path
					d="M0 0H33V8.25H0V0ZM0 11.55H33V18.15H0V11.55ZM0 21.45H33V26.4H0V21.45ZM0 29.7H33V33H0V29.7Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1290_204">
					<rect width="33" height="33" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default strokeWidthIcon;
