import {
	useActive,
	useChainedCommands,
	useCommands,
	useEditorView,
	useRemirrorContext,
} from "@remirror/react";
import React, { useEffect, useRef, useState, MutableRefObject } from "react";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import bold from "../../../../../../assets/svgs/text-editor-toolbar/editor-bold";
import italic from "../../../../../../assets/svgs/text-editor-toolbar/editor-italic";
import underline from "../../../../../../assets/svgs/text-editor-toolbar/editor-underline";
import strikethrough from "../../../../../../assets/svgs/text-editor-toolbar/editor-strikethrough";

import ToolbarButton from "../ToolbarButton/ToolbarButton";
import ListOptions from "../ListOptions/ListOptions";
import BlockquoteButton from "../BlockquoteButton/BlockquoteButton";
import HRButton from "../HRButton/HRButton";
import { useAppDispatch } from "../../../../../../redux";
import { openOverlay } from "../../../../../../layouts/Overlay";
import LinkPopup from "../LinkPopup/LinkPopup";
import link from "../../../../../../assets/svgs/text-editor-toolbar/link";
import ScreenshotDropdown from "../ScreenshotDropdown/ScreenshotDropdown";
import AlignmentDropdown from "../AlignmentDropdown/AlignmentDropdown";
import WhiteHoverTip from "../../../../../../ui/WhiteHoverTip/WhiteHoverTip";

type TextStyleOptionsProps = {
	disabled?: boolean;
};

const TextStyleOptions: React.FC<TextStyleOptionsProps> = (props) => {
	const dispatch = useAppDispatch();
	const active = useActive();
	const commands = useChainedCommands();
	const view = useEditorView();
	const items = [
		{
			label: "bold",
			active: () => active.bold(),
			handler: () => commands.toggleBold().focus().run(),
			source: bold("white"),
			newHoverTip: <WhiteHoverTip text="Bold" shortcutKey="Command+B" />,
		},
		{
			label: "italic",
			active: () => active.italic(),
			handler: () => commands.toggleItalic().focus().run(),
			source: italic("white"),
			newHoverTip: (
				<WhiteHoverTip text="Italic" shortcutKey="Command+I" />
			),
		},
		{
			label: "underline",
			active: () => active.underline(),
			handler: () => commands.toggleUnderline().focus().run(),
			source: underline("white"),
			newHoverTip: (
				<WhiteHoverTip text="Underline" shortcutKey="Command+U" />
			),
		},
		{
			label: "strikethrough",
			active: () => active.strike(),
			handler: () => commands.toggleStrike().focus().run(),
			source: strikethrough("white"),
			newHoverTip: (
				<WhiteHoverTip text="Strikethrough" shortcutKey="Command+D" />
			),
		},
	];

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			{items.map((x) => (
				<ToolbarButton
					active={x.active}
					handler={x.handler}
					source={x.source}
					disabled={props.disabled}
					newHoverTip={x.newHoverTip}
					key={x.label}
				/>
			))}
			<ToolbarButton
				disabled={props.disabled}
				active={() => active.link()}
				handler={() => {
					const text = view.state.doc.textBetween(
						view.state.selection.from,
						view.state.selection.to,
					);
					dispatch(
						openOverlay({
							heading: {
								icon: null,
								title: "Link Details",
								data: "",
							},
							component: (
								<LinkPopup
									text={text}
									currentLink={""}
									save={(newText, newLink) => {
										if (newLink) {
											if (newLink.includes("https"))
												commands
													.replaceText({
														content: newText,
													})
													.updateLink({
														href: newLink,
													})
													.run();
											else
												commands
													.replaceText({
														content: newText,
													})
													.updateLink({
														href: "#0",
														guideId: newLink,
													})
													.run();
										}
									}}
									delete={() => commands.removeLink().run()}
								></LinkPopup>
							),
						}),
					);
				}}
				source={link("white")}
				newHoverTip={<WhiteHoverTip text="Link" />}
			></ToolbarButton>
			<AlignmentDropdown disabled={props.disabled}></AlignmentDropdown>
			<div className="toolbar-special-buttons-divider"></div>
			<ListOptions disabled={props.disabled}></ListOptions>
			<div className="toolbar-special-buttons-divider"></div>
			<BlockquoteButton disabled={props.disabled}></BlockquoteButton>
			<HRButton disabled={props.disabled}></HRButton>
			<ScreenshotDropdown disabled={props.disabled}></ScreenshotDropdown>
		</div>
	);
};
export default TextStyleOptions;
