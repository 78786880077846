import { GigaUserApi } from "@giga-user-fern/api";
import { GuidePreview } from "./guide";
import { Id } from "./baseTypes";
export type Collection = GigaUserApi.collections.Collection;
export type CollectionInput = GigaUserApi.collections.CollectionInput;

export type CollectionChildren = {
	collections: Collection[];
	guidePreviews: GuidePreview[];
};

export type SplitsState = GigaUserApi.collections.SplitsState;
export const SplitsState = GigaUserApi.collections.SplitsState;

export type SplitsVideoClip = {
	idx: number;
	id: Id;
	parentId: Id | null;
	title: string;
	topic: string;
	startTime: number;
	endTime: number;
	thumbnail: string;
};

export type GuideCreationStatus = GigaUserApi.collections.GuideCreationStatus;
export type CutsVideoChapters = GigaUserApi.collections.CutsVideoChapters;
